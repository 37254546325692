import React, {Component, ReactElement} from "react";
import {Dropdown, FormField} from "semantic-ui-react";
import DataStore, {StoreProps} from "../../models/DataStore";
import {SelectorProps} from "./FormProps";

interface CountrySelectorProps extends SelectorProps {
    value: string
}

class CountrySelector extends Component<CountrySelectorProps & StoreProps> {
    countries = Array
        .from(this.props.store.get("countries"), ([key, value]) => value)
        .map(
            (value) => {
                return {
                    "key": value.code,
                    "value": value.code,
                    "text": value.de
                }
            }
        );

    render(): ReactElement {
        return <FormField>
            <label>Land</label>
            <Dropdown
                placeholder='auswählen'
                fluid
                search
                selection
                options={this.countries}
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChange}
            />
        </FormField>
    }
}

export default DataStore.withStore(CountrySelector)