import React, {Component, ReactElement} from "react";
import EntityForm from "../../../base/forms/EntityForm";
import {defaultCompany} from "../../general/basedata/Companies";
import Helpers, {APIResponse, STANDARD_ID} from "../../../base/Helpers";
import {api} from "../../../models/InitialData";
import {Button, Card, CardContent, Label, Popup} from "semantic-ui-react";
import ProjectAssociation, {defaultProjectAssociation} from "./ProjectAssociation";
import DateAssociation, {defaultDateAssociation} from "./DateAssociation";
import {SemanticCOLORS} from "semantic-ui-react/dist/commonjs/generic";
import DataStore, {StoreProps} from "../../../models/DataStore";


export const defaultTimeEntry = Object.assign({
    id: -1,
}, {dates: defaultDateAssociation}, defaultProjectAssociation);

const colorMap: { [key: number]: SemanticCOLORS } = {
    1: "blue",
    2: "red",
    3: "teal",
    4: "pink"
};


class TimeEntry extends Component<{
    value: typeof defaultTimeEntry, cancelCreate?: () => void,
    create?: boolean, onChange: () => void,
    viewOnly?: boolean,
    userId?: string
} & StoreProps> {

    metaState = {
        "entityForm_editing": this.props.create,
    };

    render() {
        return <EntityForm
            defaultState={defaultTimeEntry}
            metaState={this.metaState}
            value={this.props.value.id}

            name={"time_entry_id"}
            preset={this.props.value}
            set={this.moveStateUpstream}
            delete={value => api("/time_entry/" + value, "DELETE")}

            changeHandler={() => this.props.onChange()}
            {...STANDARD_ID}

            render={this.editing}
        />;
    }

    moveStateUpstream = (value: number, state: typeof defaultTimeEntry): Promise<APIResponse<typeof defaultTimeEntry>> => {
        if (value > defaultCompany.id) {
            return api("/time_entry/", "PATCH", TimeEntry.transformForInsertion(state, 0))
        }

        let promises: Promise<APIResponse<any>>[] = [];
        for (let i = 0; i < state.dates.length; i++) {
            promises.push(api(this.props.userId ? "/employees_time_entry/?id=" + this.props.userId : "/time_entry/",
                "PUT", TimeEntry.transformForInsertion(state, i)));
        }

        return new Promise<APIResponse<typeof defaultTimeEntry>>(
            r => Promise.all(promises).then(
                ps => {
                    let toRemove: number[] = [];
                    ps.forEach((p, idx) => p.ok && toRemove.push(idx));
                    const newDates = TimeEntry.removeArrIndex(state.dates, ...toRemove);

                    r({
                        "ok": newDates.length === 0,
                        "status": newDates.length === 0 ? 200 : 400,
                        "explain": newDates.length === 0 ? "" : "Einige Einträge wurden gespeichert, die verbliebenen sind fehlerhaft.",
                        "data": Object.assign(state, {dates: newDates})
                    });
                }
            )
        );
    };

    static removeArrIndex<T>(input: T[], ...indexes: number[]): T[] {
        let copy = Helpers.immCopy(input);

        for (let index of indexes) {
            if (index < copy.length) {
                copy.splice(index)
            }
        }

        return copy
    }

    static transformForInsertion(state: typeof defaultTimeEntry, index: number) {
        let deep = Helpers.immCopy(state);
        deep = Object.assign(deep, deep.dates[index]);
        delete deep.dates;

        return deep;
    }

    editing = (form: EntityForm<typeof defaultTimeEntry, number, {}>): ReactElement => {
        if (form.state.entityForm_editing) {
            if (!this.props.store.get("ready")) {
                return <></>
            }

            const p = form.state.product_id;
            const disableWorkingTime = p === 1 || p === 2;
            const disableOverTime = p === 1 || p === 2 || p === 3;
            const workingTimeNumber = p === 3;

            return <Card fluid>
                <CardContent>
                    <div className={"ui form"}>
                        <h2>{this.props.create && <>neuer </>}Eintrag{!this.props.create && <> bearbeiten</>}</h2>
                        <ProjectAssociation onChange={form.handleChange} {...form.state}/>
                        <DateAssociation
                            value={form.state.dates}
                            onChange={dates => form.handleChange(null, {
                                name: "dates",
                                value: dates
                            })}
                            extendable={this.props.create}
                            workingTimeNumber={workingTimeNumber}
                            disableWorkingTime={disableWorkingTime}
                            disableOverTime={disableOverTime}
                        />
                    </div>
                </CardContent>
                <CardContent extra>
                    {form.getTemporaryError()}
                    {!this.props.create && <Button type='submit' negative floated="left"
                                                   onClick={form.doDeleteEntity}>Löschen</Button>}
                    <Button type='submit' primary floated="right"
                            onClick={form.doFinishEditing}>Speichern</Button>
                    <Button type='reset' floated="right"
                            onClick={() => this.doCancelEditing(form)}
                    >Abbrechen</Button>
                </CardContent>
            </Card>
        }

        const color = colorMap.hasOwnProperty(form.state.product_id)
            ? {"color": colorMap[form.state.product_id]} : {};

        const label = <Label as={this.props.viewOnly ? undefined : "a"}
                             onClick={this.props.viewOnly ? undefined : form.doStartEditing} {...color}>
            {!form.state.dates[0].working_time.isZero() && <>{form.state.dates[0].working_time.toString()} Stunden</>}
            {form.state.project_id === -1 && form.state.dates[0].working_time.isZero() && <>{form.state.product_short_name}</>}
            {!form.state.dates[0].over_time.isZero() &&
            <Label.Detail className={"highlight"}>+{form.state.dates[0].over_time.toString()}</Label.Detail>}
            {form.state.project_id !== -1 && !form.state.dates[0].working_time.isZero() &&
            <Label.Detail color={"grey"}>{form.state.product_short_name}</Label.Detail>}
            {form.state.project_id !== -1 &&
            <Label.Detail>{form.state.project_number} {form.state.project_name}</Label.Detail>}
            {form.state.project_id === -1 && !form.state.dates[0].working_time.isZero() &&
            <Label.Detail content={form.state.product_short_name}/>}
        </Label>;

        if (form.state.notes !== "") {
            return <Popup content={form.state.notes} trigger={label} hideOnScroll/>
        }

        return label;
    };

    doCancelEditing = (form: EntityForm<typeof defaultTimeEntry, number, {}>) => {
        form.doCancelEditing();
        if (this.props.cancelCreate) {
            this.props.cancelCreate();
        }
    }
}

export default DataStore.withStore(TimeEntry);