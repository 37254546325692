import React, {Component, ReactElement} from "react";
import {RouteComponentProps} from "react-router";
import ContentHeader, {MenuAction} from "../../base/ContentHeader";
import RouterContent from "../../base/RouterContent";
import ProjectView, {defaultProject, statusColoring} from "./views/ProjectView";
import {api} from "../../models/InitialData";
import Helpers from "../../base/Helpers";
import {
    Button,
    Card,
    CardContent,
    CardGroup,
    FormField,
    Header,
    Label,
    Popup,
    Select,
    SemanticCOLORS,
    Table
} from "semantic-ui-react";
import DataView from "../../base/forms/DataView";
import DataStore, {getOrEmpty, StoreProps} from "../../models/DataStore";
import CurrencyForm from "../../base/forms/CurrencyForm";
import ExternalInputForm, {defaultExternalInput} from "./views/ExternalInputForm";
import JSXHelpers from "../../base/JSXHelpers";
import Time from "../../models/Time";
import Groups, {hasAnyGroup} from "../../base/Groups";

const defaultAggregatedExternalInput = Object.assign({
    company_id: -1,
    supplier_contact_name: "",
    company_name: "",
}, defaultExternalInput);

export const defaultProjectCost = {
    "project_id": -1,
    "project_number": "",
    "project_name": "",
    "companies": [] as number[],
    "employees": [] as number[],
    "employee_names": [] as string[],
    "company_names": [] as string[],
    "products": [] as number[],
    "product_names": [] as string[],
    "working_minutes": 0,
    "over_minutes": 0,
    "weighted_hourly_earnings": 0,
    "weighted_hourly_fixcost": 0,
    "total_employee_cost": 0,
    "total_external_input": 0,
    "actual_returns": 0,
    "date": null
};

export type projectCostTableColsKey =
    "project_name"
    | "employee_names"
    | "company_names"
    | "product_names"
    | "working_minutes"
    | "over_minutes"
    | "date"
    | "total_external_input"
    | "actual_returns"
    | "weighted_hourly_earnings"
    | "weighted_hourly_fixcost"
    | "total_employee_cost"
    | "total_equity_gained"
    | "total_cost"
    | "total_time";

const hiddenCols = ["total_external_input", "actual_returns", "weighted_hourly_earnings", "weighted_hourly_fixcost",
    "total_employee_cost", "total_equity_gained", "total_cost", "total_time"] as projectCostTableColsKey[];

interface TableCol {
    name: string
    getValue: (input: typeof defaultProjectCost) => ReactElement
}

const projectCostTableCols: Map<projectCostTableColsKey, TableCol> = new Map<projectCostTableColsKey, TableCol>([
    ["project_name", {
        name: "Projekt",
        getValue: input => <Label content={input.project_number + " " + input.project_name}/>
    }],
    ["employee_names", {
        name: "Mitarbeiter",
        getValue: input => <>{input.employee_names.map((v, idx) => <Label content={v} key={idx}/>)}</>
    }],
    ["company_names", {
        name: "Unternehmen",
        getValue: input => <>{input.company_names.map((v, idx) => <Label content={v} key={idx}/>)}</>
    }],
    ["product_names", {
        name: "Produkte",
        getValue: input => <>{input.product_names.map(
            (v, idx) => <Label content={v + (input.products[idx] === 74 ? " (intern)" : "")} key={idx}/>)}</>
    }],
    ["working_minutes", {
        name: "Std",
        getValue: input => <>{Time.parse(input.working_minutes).toString()}</>
    }],
    ["over_minutes", {
        name: "ÜStd",
        getValue: input => <>{Time.parse(input.over_minutes).toString()}</>
    }],
    ["total_time", {
        name: "Zeit",
        getValue: input => <Header size={"small"}
                                   content={Time.parse(input.working_minutes + input.over_minutes).toString()}/>
    }],
    ["weighted_hourly_earnings", {
        name: "Lohn/Std",
        getValue: input => <>{CurrencyForm.currencyString(input.weighted_hourly_earnings)}</>
    }],
    ["weighted_hourly_fixcost", {
        name: "K-Fix/Std",
        getValue: input => <>{CurrencyForm.currencyString(input.weighted_hourly_fixcost)}</>
    }],
    ["total_external_input", {
        name: "Fremdleistungen", getValue: input => <Header size={"small"}
                                                            content={CurrencyForm.currencyString(input.total_external_input)}/>
    }],
    ["actual_returns", {
        name: "Umsatz", getValue: input => <Header size={"small"}
                                                   color={input.actual_returns > 0 ? "green" : undefined}
                                                   content={CurrencyForm.currencyString(input.actual_returns)}/>
    }],
    ["total_equity_gained", {
        name: "Gewinn", getValue: input => {
            const eq = input.actual_returns - input.total_employee_cost
                - input.total_external_input;

            let equityColor: SemanticCOLORS | undefined = undefined;
            if (eq !== 0) {
                equityColor = eq > 0 ? "green" : "red";
            }

            return <Header size={"small"}
                           color={equityColor}
                           content={CurrencyForm.currencyString(eq)}/>
        }
    }],
    ["total_employee_cost", {
        name: "Arbeitsaufwand", getValue: input => <Header size={"small"}
                                                           content={CurrencyForm.currencyString(input.total_employee_cost)}/>
    }],
    ["total_cost", {
        name: "Kosten", getValue: input => {
            const cost = input.total_external_input + input.total_employee_cost;

            return <Header size={"small"}
                           color={cost > 0 ? "red" : undefined}
                           content={CurrencyForm.currencyString(cost)}/>
        }
    }],
    ["date", {name: "Datum", getValue: input => <>{Helpers.dateString(input.date)}</>}],
]);

const projectGroupByCols: { [key: string]: projectCostTableColsKey[] } = {
    "employee": ["employee_names", "product_names", "working_minutes", "over_minutes", "total_employee_cost"],
    "date": ["date", "employee_names", "product_names", "total_time", "total_employee_cost"],
    "product": ["product_names", "employee_names", "working_minutes", "over_minutes", "total_employee_cost"],
    "none": ["date", "employee_names", "product_names", "total_time", "weighted_hourly_earnings", "weighted_hourly_fixcost", "total_employee_cost"],
};

const defaultProjectView = Object.assign({
    external_inputs: [] as typeof defaultAggregatedExternalInput[],
    project_costs: [] as typeof defaultProjectCost[],
}, defaultProject);

const costOrderBy = [
    {key: 'employee', value: 'employee', text: 'Mitarbeiter'},
    {key: 'date', value: 'date', text: 'Datum'},
    {key: 'product', value: 'product', text: 'Produkt'},
    {key: 'none', value: 'none', text: 'nichts'},
];


class SingleProject extends Component<RouteComponentProps<{ id: string }> & StoreProps> {

    state = Object.assign({
        isReady: false,
        editProject: false,
        costOrderByColumn: "employee",
        editIdx: -1,
        createObj: false,
        totalMinutes: 0,
        externalInputTotal: 0,
        employeeInputTotal: 0,
    }, Helpers.immCopy(defaultProjectView));

    componentDidMount(): void {
        if (this.props.match.params.id === "-1") {
            this.props.history.goBack();
        }

        api<typeof defaultProjectView>("/reports/project_view/" + this.props.match.params.id + "?groupBy=" + this.state.costOrderByColumn, "GET")
            .then(resp => !resp.ok || resp.data === null
                ? this.props.history.goBack()
                : this.setState(Object.assign({
                    isReady: true,
                    editIdx: -1,
                    editProject: false,
                    externalInputTotal: resp.data.external_inputs.length === 0 ? 0
                        : resp.data.external_inputs
                            .map(e => e.invoice_total)
                            .reduce((p, e) => p + e),
                    employeeInputTotal: resp.data.project_costs.length === 0 ? 0
                        : resp.data.project_costs
                            .map(e => e.total_employee_cost)
                            .reduce((p, e) => p + e),
                    totalMinutes: resp.data.project_costs.length === 0 ? 0
                        : resp.data.project_costs
                            .map(e => e.working_minutes + e.over_minutes)
                            .reduce((p, e) => p + e),
                }, resp.data)));
    }

    componentDidUpdate(prevProps: any, prevState: Readonly<{ costOrderByColumn: string }>): void {
        if (prevState.costOrderByColumn !== this.state.costOrderByColumn) {
            api<typeof defaultProjectCost[]>("/reports/project_cost?id=" + this.props.match.params.id + "&groupBy=" + this.state.costOrderByColumn, "GET")
                .then(resp => !resp.ok || resp.data === null
                    ? this.props.history.goBack()
                    : this.setState({
                        project_costs: resp.data,
                        employeeInputTotal: resp.data.length === 0 ? 0
                            : resp.data.map(e => e.total_employee_cost)
                                .reduce((p, e) => p + e),
                        totalMinutes: resp.data.length === 0 ? 0
                            : resp.data
                                .map(e => e.working_minutes + e.over_minutes)
                                .reduce((p, e) => p + e),
                    }));
        }
    }

    render(): ReactElement {
        const project = this.state;
        const status = getOrEmpty(this.props.store.get("status").get(project.status_id), "name");
        const products = this.props.store.get("products");
        let statusColor: SemanticCOLORS | undefined;

        if (statusColoring.hasOwnProperty(project.status_id)) {
            statusColor = statusColoring[project.status_id]
        }

        let menuAction: MenuAction | null = null;
        if (!this.state.editProject && hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT, Groups.ACQUISITION, Groups.PROJECTLEAD)) {

            menuAction = new MenuAction(() => this.setState({editProject: true}), "Projekt bearbeiten");
        }

        let costCols: projectCostTableColsKey[] = [];
        if (projectGroupByCols.hasOwnProperty(this.state.costOrderByColumn)) {
            costCols = projectGroupByCols[this.state.costOrderByColumn];
        }


        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT, Groups.PROJECTLEAD)) {
            const newCostCols: projectCostTableColsKey[] = [];

            costCols.forEach((col, idx) =>
                !hiddenCols.includes(col) && newCostCols.push(col));

            costCols = newCostCols;
        }


        const earnings = project.actual_return - project.employeeInputTotal
            - project.externalInputTotal;

        return (
            <>
                <ContentHeader
                    title={project.name === "" ? "Projekt: Detailansicht" : project.project_number + " " + project.name}
                    isReady={true} action={menuAction} goBackButton>
                    <div className={"item"}>
                        {project.customer_display_name === "" ? "Daten werden geladen..." : "für " + project.customer_display_name
                            .replace("Herr", "Herrn") + " von " + project.company_name}
                    </div>
                </ContentHeader>
                <RouterContent>
                    {
                        this.state.editProject
                            ? <ProjectView value={this.state.id} preset={this.state} name={"project_id"}
                                           onChange={() => this.componentDidMount()} entity create/>
                            : <CardGroup>


                                <Card fluid>
                                    <CardContent>
                                        <h2>Allgemein</h2>
                                        <div className={"vcenter-text"}>
                                            <DataView name={"Projektnummer"}>{project.project_number}</DataView>
                                            <DataView name={"Name"}>{project.name}</DataView>
                                            <DataView name={"Status"}><Label content={status}
                                                                             color={statusColor}/></DataView>
                                        </div>
                                        <div className={"vcenter-text space-around"}>
                                            <DataView
                                                name={"Startdatum"}>{Helpers.dateString(project.start_date)}</DataView>
                                            <DataView
                                                name={"Enddatum"}>{Helpers.dateString(project.expected_end_date)}</DataView>
                                        </div>
                                        <div className={"vcenter-text space-around"}>
                                            <DataView
                                                name={"Stunden"}>{Time.parse(this.state.totalMinutes).toString()} / {project.expected_hours}</DataView>
                                        </div>
                                        <br/>
                                        <div className={"ui form"}>
                                            <FormField>
                                                <label>Notizen</label>
                                                <Card fluid style={{marginTop: 0}}>
                                                    <CardContent>{this.state.notes === "" ? "keine Notizen" :
                                                        project.notes.split("\n").map((s, idx) => <span
                                                            key={idx}>{s}<br/></span>)}</CardContent>
                                                </Card>
                                            </FormField>
                                        </div>
                                        <br/>
                                        <div className={"ui form"}>
                                            <FormField>
                                                <label>Kunde</label>
                                                <Card fluid style={{marginTop: 0}}>
                                                    <CardContent>
                                                        <strong>{this.state.customer_display_name}</strong>
                                                        {this.state.company_id !== -1 && <>
                                                            <br/>von {this.state.company_name}</>}
                                                        <br/><br/>
                                                        {JSXHelpers.previewContactData(this.state)}
                                                    </CardContent>
                                                </Card>
                                            </FormField>
                                        </div>
                                    </CardContent>
                                </Card>

                                {hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT, Groups.PROJECTLEAD) &&
                                <Card fluid>
                                    <CardContent>
                                        <h2>Betriebswirtschaft</h2>
                                        <div className={"vcenter-text space-around"}>
                                            <DataView
                                                name={"Angebotssumme"}>{CurrencyForm.currencyString(project.expected_return)}</DataView>
                                            <DataView
                                                name={"Umsatz"}>{CurrencyForm.currencyString(project.actual_return)}</DataView>
                                        </div>
                                        <Table basic='very' celled textAlign={"center"} className={"sumup"} structured>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>Umsatz</Table.Cell>
                                                    <Table.Cell
                                                        style={{color: project.actual_return > 0 ? "green" : ""}}>
                                                        + {CurrencyForm.currencyString(project.actual_return)}
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Arbeitsaufwand</Table.Cell>
                                                    <Table.Cell
                                                        style={{color: project.employeeInputTotal > 0 ? "red" : ""}}>
                                                        – {CurrencyForm.currencyString(project.employeeInputTotal)}
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Fremdleistungen</Table.Cell>
                                                    <Table.Cell
                                                        style={{color: project.externalInputTotal > 0 ? "red" : ""}}>
                                                        – {CurrencyForm.currencyString(project.externalInputTotal)}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                            <Table.Footer>
                                                <Table.Row>
                                                    <Table.Cell textAlign={"right"}>
                                                        <Header size={"small"} content={"Gewinn"}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Header size={"medium"}
                                                                style={{color: earnings >= 0 ? "green" : "red"}}
                                                                content={CurrencyForm.currencyString(earnings)}/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Footer>
                                        </Table>
                                    </CardContent>
                                </Card>
                                }


                                <Card fluid>
                                    <CardContent>
                                        <h2 style={{display: "inline-block"}}>Arbeitsaufwand</h2>
                                        <span style={{float: "right"}}>
                                            <Header size={"small"}
                                                    style={{"margin": "0 15px 0 0", "display": "inline-block"}}>
                                                gruppieren nach
                                            </Header>
                                            <Select placeholder='Auswählen'
                                                    value={this.state.costOrderByColumn}
                                                    onChange={(a, v) =>
                                                        this.setState({costOrderByColumn: v.value})}
                                                    options={costOrderBy}/>
                                        </span>
                                        <Table basic='very' celled textAlign={"center"} className={"sumup"} structured>
                                            {this.state.project_costs.length > 0 &&
                                            assembleTable(costCols, this.state.project_costs)}
                                            {this.state.project_costs.length === 0 &&
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell colSpan={costCols.length}>
                                                        Bisher keine Zeiteintragungen für dieses Projekt.
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>}
                                            <Table.Footer>
                                                <Table.Row>
                                                    <Table.Cell colSpan={costCols.length - 1}
                                                                textAlign={"right"}>
                                                        <Header size={"small"} content={"Gesamtsumme"}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Header size={"medium"}
                                                                content={!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT, Groups.PROJECTLEAD)
                                                                    ? Time.parse(this.state.totalMinutes).toString()
                                                                    : CurrencyForm.currencyString(this.state.employeeInputTotal)}/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Footer>
                                        </Table>
                                    </CardContent>
                                </Card>


                                {hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT, Groups.PROJECTLEAD) &&
                                <Card fluid>
                                    <CardContent>
                                        <h2>
                                            Fremdleistungen
                                            <Button floated={"right"} content={"neue Fremdleistung"}
                                                    onClick={() => this.setState({createObj: true})}
                                                    icon='plus' labelPosition='left'/>
                                        </h2>
                                        <Table basic='very' celled textAlign={"center"} className={"sumup"} singleLine
                                               structured>
                                            {
                                                this.state.external_inputs.length > 0 &&
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>Datum</Table.HeaderCell>
                                                        <Table.HeaderCell>Lieferant</Table.HeaderCell>
                                                        <Table.HeaderCell>Produkt</Table.HeaderCell>
                                                        <Table.HeaderCell>Rechnungs-Nr.</Table.HeaderCell>
                                                        <Table.HeaderCell>Summe</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                            }
                                            <Table.Body>
                                                {
                                                    this.state.createObj &&
                                                    <Table.Row>
                                                        <Table.Cell colSpan={5} textAlign={"left"}>
                                                            <ExternalInputForm name={"ext_input_id"} value={-1} create
                                                                               onChange={() => {
                                                                                   Helpers.cancelAction(this)();
                                                                                   this.componentDidMount();
                                                                               }} onCancel={Helpers.cancelAction(this)}
                                                                               defaultProject={this.state}/>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                }
                                                {
                                                    !this.state.createObj && this.state.external_inputs.length === 0 &&
                                                    <Table.Row>
                                                        <Table.Cell colSpan={5}>
                                                            Bisher keine Fremdleistungen vermerkt.
                                                        </Table.Cell>
                                                    </Table.Row>
                                                }
                                                {
                                                    this.state.external_inputs.map(
                                                        e => {
                                                            if (this.state.editIdx === e.id) {
                                                                return <Table.Row key={e.id + "edit"}>
                                                                    <Table.Cell colSpan={5} textAlign={"left"}>
                                                                        <ExternalInputForm value={e.id} preset={e}
                                                                                           defaultProject={this.state}
                                                                                           name={"ext_input_id"} create
                                                                                           onChange={() => this.componentDidMount()}
                                                                                           onCancel={() => this.setState({editIdx: -1})}
                                                                        />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            }

                                                            const row = <Table.Row key={e.id} className={"selectable"}
                                                                                   onClick={() => this.setState({editIdx: e.id})}>
                                                                <Table.Cell>{Helpers.dateString(e.delivery_date)}</Table.Cell>
                                                                <Table.Cell>{e.company_id === -1 ? e.supplier_contact_name : e.company_name}</Table.Cell>
                                                                <Table.Cell>{getOrEmpty(products.get(e.product_id), "short_name")}</Table.Cell>
                                                                <Table.Cell>{e.invoice_id}</Table.Cell>
                                                                <Table.Cell>{CurrencyForm.currencyString(e.invoice_total)}</Table.Cell>
                                                            </Table.Row>;


                                                            return e.notes !== "" ?
                                                                <Popup key={e.id} trigger={row} content={e.notes}/> : row;
                                                        }
                                                    )
                                                }
                                            </Table.Body>
                                            <Table.Footer>
                                                <Table.Row>
                                                    <Table.Cell colSpan={4}
                                                                textAlign={"right"}>
                                                        <Header size={"small"} content={"Gesamtsumme"}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Header size={"medium"}
                                                                content={CurrencyForm.currencyString(this.state.externalInputTotal)}/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Footer>
                                        </Table>
                                    </CardContent>
                                </Card>
                                }
                            </CardGroup>
                    }
                </RouterContent>
            </>
        )
    }


}

export function assembleTable(input: projectCostTableColsKey[], costs: typeof defaultProjectCost[],
                              onClickRow?: (input: typeof defaultProjectCost) => void): ReactElement {

    const cols = input.map(v => projectCostTableCols.get(v));

    const header = <Table.Header>
        <Table.Row>
            {cols.map((v, idx) =>
                <Table.HeaderCell key={input[idx]}>
                    {getOrEmpty(v, "name")}
                </Table.HeaderCell>
            )}
        </Table.Row>
    </Table.Header>;

    const body = <Table.Body>
        {costs.map((c, rIdx) =>
            <Table.Row key={rIdx} onClick={() => onClickRow && onClickRow(c)}
                       className={onClickRow ? "selectable" : ""}>
                {cols.map((v, cIdx) => v !== undefined &&
                    <Table.Cell key={cIdx} className={"ui labels"}>{v.getValue(c)}</Table.Cell>)}
            </Table.Row>
        )}
    </Table.Body>;

    return <>
        {header}
        {body}
    </>
}

export default DataStore.withStore(SingleProject)