import React, {Component, ReactElement} from "react";
import OptionalEntityForm, {OptionalEntityFormState} from "../../../base/forms/OptionalEntityForm";
import {api} from "../../../models/InitialData";
import {PrefixedForm, PresetReceiver, ValueSelectorProps} from "../../../base/forms/FormProps";
import DataStore, {getOrEmpty, StoreProps} from "../../../models/DataStore";
import {Form} from "semantic-ui-react";
import CountrySelector from "../../../base/forms/CountrySelector";
import {Store} from "undux";
import {DisplayResult} from "../../../base/forms/SearchSelection";
import Helpers, {STANDARD_ID} from "../../../base/Helpers";
import EntityForm from "../../../base/forms/EntityForm";

const defaultState = {
    "id": -1,
    "street": "",
    "street_number": "",
    "postcode": "",
    "city": "",
    "country": "DE"
};

class OptionalAddressForm extends Component<ValueSelectorProps<number> & PrefixedForm & StoreProps & PresetReceiver<typeof defaultState>> {
    render(): ReactElement {
        return <OptionalEntityForm
            defaultState={defaultState}
            value={this.props.value}
            name={this.props.name}
            notPresentText={"noch keine Addresse verknüpft"}

            changeHandler={this.changeHandler}
            get={(value) => api("/address/" + value, "GET")}
            set={(value, state) => api("/address/", value === defaultState.id ? "PUT" : "PATCH", state)}
            delete={(value) => api("/address/" + value, "DELETE")}
            preset={this.props.preset}
            deleteEntity

            {...STANDARD_ID}

            preview={this.preview}
            editing={this.editing}
        />
    }

    changeHandler = (name: string, value: number) => {
        this.props.onChange(null, {name: name, value: value});
    };

    editing = (form: EntityForm<typeof defaultState, number, OptionalEntityFormState>): ReactElement => {
        return <div className={"ui form"}>
            <h2>Adresse</h2>
            <Form.Group>
                <Form.Input fluid label='Straße' name='street' placeholder="Musterstraße"
                            onChange={form.handleChange}
                            value={form.state.street} type='text' width={11}/>
                <Form.Input fluid label='Straßennummer' name='street_number' placeholder="123"
                            onChange={form.handleChange}
                            value={form.state.street_number} type='text' width={7}/>
            </Form.Group>
            <Form.Group>
                <Form.Input fluid label='PLZ' name='postcode' value={form.state.postcode}
                            onChange={form.handleChange} placeholder='12345' width={6}/>
                <Form.Input fluid label='Stadt' name='city' value={form.state.city}
                            onChange={form.handleChange} placeholder='Musterstadt' width={10}/>
            </Form.Group>
            <Form.Group widths={2}>
                <CountrySelector name='country' value={form.state.country}
                                 onChange={form.handleChange}/>
            </Form.Group>
        </div>
    };

    preview = (state: typeof defaultState): ReactElement => {
        return addressPreview(state, this.props.store, this.props.prefix);
    };

    getSearch = (query: string): Promise<typeof defaultState[]> =>
        Helpers.getSearch("/reports/search_address", query);


    fetchSearchResults = (models: typeof defaultState[]): DisplayResult<number>[] => {
        return models.map(
            state => {
                return {
                    title: state.street + " " + state.street_number,
                    description: "in " + state.postcode + " " + state.city + ", "
                        + getOrEmpty(this.props.store.get("countries").get(state.country), "de"),
                    value: state.id
                }
            }
        )
    }
}

export default DataStore.withStore(OptionalAddressForm)

export function addressPreview(state: typeof defaultState, store: Store<any>, prefix?: string, unBold?: boolean, isoCountry?: boolean): ReactElement {

    const country = isoCountry ? state.country : getOrEmpty(store.get("countries").get(state.country), "de");
    const pre = prefix ? <>{prefix}&nbsp;</> : <></>;
    const view = <>{state.street} {state.street_number}, {state.postcode}&nbsp;{state.city}, {country}</>;

    if (unBold) {
        return <>
            {pre}{view}
        </>
    }

    return <>{pre}<strong>{view}</strong></>;
}