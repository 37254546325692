import React, {Component, ReactElement} from "react";
import {Button, Icon, Table, TableCell, TableRow} from "semantic-ui-react";
import Helpers, {STANDARD_ID} from "../../../../base/Helpers";
import {api} from "../../../../models/InitialData";
import EntityForm from "../../../../base/forms/EntityForm";
import {defaultFixcost} from "../FixCosts";
import DateForm from "../../../../base/forms/DateForm";
import CurrencyForm from "../../../../base/forms/CurrencyForm";
import ConfirmPortal from "../../../../base/forms/ConfirmPortal";


class FixcostView extends Component<{ fix: typeof defaultFixcost, cancelCreate?: () => void, create?: boolean }> {
    metaState = {
        "entityForm_editing": this.props.create,
        "optionalEntityForm_confirmDelete": false
    };

    render(): ReactElement {
        return <EntityForm
            defaultState={defaultFixcost}
            metaState={this.metaState}
            value={this.props.fix.id}

            name={"valid_from"}
            preset={this.props.fix}
            set={(value, state) => api("/fixcost/", value === defaultFixcost.id ? "PUT" : "PATCH", state)}
            delete={(value) => api("/fixcost/" + encodeURIComponent(value), "DELETE")}

            changeHandler={() => {
                if (this.props.cancelCreate) {
                    this.props.cancelCreate();
                }
            }}
            {...STANDARD_ID}

            render={this.editing}
        />
    }

    editing = (form: EntityForm<typeof defaultFixcost, number, { optionalEntityForm_confirmDelete: boolean }>): ReactElement => {
        if (form.state.entityForm_editing) {
            return <>
                <TableRow>
                    <TableCell>
                        <DateForm name='valid_from' value={form.state.valid_from}
                                  onChange={form.handleChange} label={""}/>
                    </TableCell>
                    <TableCell className={"vcenter-text"}>
                        <CurrencyForm value={form.state.amount} label={""} name={"amount"}
                                      onChange={form.handleChange}/>
                        <Button.Group style={{"margin": "0 0 0 5px"}}>
                            { form.state.id !== defaultFixcost.id &&
                                <Button negative icon onClick={() => this.confirmDelete(form, true)}>
                                    <Icon name='trash'/>
                                </Button>
                            }
                            <Button icon onClick={() => this.doCancelEditing(form)}>
                                <Icon name='x'/>
                            </Button>
                            <Button icon primary onClick={form.doFinishEditing}>
                                <Icon name='check'/>
                            </Button>
                            {
                                form.state.optionalEntityForm_confirmDelete &&
                                <ConfirmPortal open ok={() => form.doDeleteEntity()}
                                               cancel={() => this.confirmDelete(form, false)}
                                               text={"Fixkosten-Eintrag wirklich löschen?"}
                                />
                            }
                        </Button.Group>
                    </TableCell>
                </TableRow>
                {form.state.entityForm_temporaryError !== "" &&
                <Table.Row>
                    <Table.Cell colSpan='2'>
                        {form.getTemporaryError()}
                    </Table.Cell>
                </Table.Row>
                }
            </>
        }

        return <TableRow onClick={form.doStartEditing} className={"selectable"}>
            <TableCell>{Helpers.dateString(form.state.valid_from)}</TableCell>
            <TableCell>{CurrencyForm.currencyString(form.state.amount)}</TableCell>
        </TableRow>
    };

    doCancelEditing = (form: EntityForm<typeof defaultFixcost, number, any>) => {
        form.doCancelEditing();
        if (this.props.cancelCreate) {
            this.props.cancelCreate();
        }
    };


    confirmDelete = (form: EntityForm<any, any, { optionalEntityForm_confirmDelete: boolean }>, value: boolean) => {
        form.setState({"optionalEntityForm_confirmDelete": value})
    };
}

export default FixcostView