import {SelectorProps} from "./FormProps";
import React, {Component, ReactElement} from "react";
import {get, StoreProps} from "../../models/DataStore";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import {Dropdown} from "semantic-ui-react";

interface ProductSelectorProps extends SelectorProps {
    value: number[]
}

class MultipleProductSelector extends Component<StoreProps & ProductSelectorProps> {
    products: DropdownItemProps[] = [];

    render(): ReactElement {
        if (!this.props.store.get("ready")) {
            return <></>
        }

        if (this.products.length === 0) {
            const categories = this.props.store.get("categories");
            this.products = Array
                .from(this.props.store.get("products"), ([key, value]) => value)
                .filter(p => p.category_id !== 1 && p.category_id !== 2)
                .map(
                    (value) => {
                        return {
                            "key": value.id,
                            "value": value.id,
                            "text": value.short_name + " (" + get(categories.get(value.category_id), "name") + ")",
                        }
                    }
                );
        }

        return <Dropdown
            placeholder='Leistungen auswählen'
            fluid
            multiple
            search
            selection
            name={this.props.name}
            value={this.props.value}
            options={this.products}
            noResultsMessage={"keine Ergebnisse gefunden"}
            onChange={this.props.onChange}
        />;
    }
}

export default MultipleProductSelector