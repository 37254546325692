import React, {Component, ReactElement} from "react";

interface DataViewProps {
    name:string
}

export default class DataView extends Component<DataViewProps> {
    render(): ReactElement {

        return <div><strong>{this.props.name}:</strong> {this.props.children}</div>
    }
}