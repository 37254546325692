import React, {Component, ReactElement} from "react";
import {RouteComponentProps} from "react-router-dom";
import ContentHeader from "../../base/ContentHeader";
import RouterContent from "../../base/RouterContent";
import {defaultProjectCost} from "../projects/SingleProject";
import {api} from "../../models/InitialData";
import {Card, Header, Input, Label, Table} from "semantic-ui-react";
import CurrencyForm from "../../base/forms/CurrencyForm";
import DataStore, {StoreProps} from "../../models/DataStore";
import Groups, {hasAnyGroup} from "../../base/Groups";
import {goHome} from "../../base/JSXHelpers";


export const defaultSupplerOverview = {
    "supplier_id": -1,
    "supplier_display_name": "",
    "project_amount": 0,
    "order_amount": 0,
    "invoice_total": 0
};

class SupplierOverviewReport extends Component<RouteComponentProps & StoreProps> {

    thisYear = new Date().getUTCFullYear() + "";

    state = {
        year: this.thisYear,
        editYear: this.thisYear,
        totalExpenses: 0,
        suppliers: [] as typeof defaultSupplerOverview[],
    };

    componentDidMount(): void {
        api<typeof defaultSupplerOverview[]>("/reports/supplier_overview?year=" + this.state.year, "GET")
            .then(resp => !resp.ok || resp.data === null
                ? this.props.history.goBack()
                : this.setState({
                    suppliers: resp.data,
                    totalExpenses: resp.data.length === 0 ? 0 :
                        resp.data
                            .map(m => m.invoice_total)
                            .reduce((l, p) => l + p)
                }));
    }

    componentDidUpdate(prevProps: any, prevState: Readonly<{ year: string, project_work: typeof defaultProjectCost[] }>): void {
        if (prevState.year !== this.state.year) {
            this.componentDidMount();
        }
    }


    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT)) {
            return goHome()
        }

        const suppliers = this.state.suppliers;


        return this.header(<>
            <Card fluid>
                <Card.Content>
                    <h2 style={{display: "inline-block"}}>Kosten</h2>
                    <span style={{float: "right"}}>
                        <Header size={"small"} style={{"margin": "0 15px 0 0", "display": "inline-block"}}>
                            Jahr
                        </Header>
                        <Input value={this.state.editYear}
                               action={{
                                   disabled: this.state.year === this.state.editYear,
                                   icon: "check", onClick: () => this.setState({year: this.state.editYear})
                               }}
                               onChange={(a, v) => this.setState({editYear: v.value})}
                               type='number' placeholder='XXXX' size={"small"}/>
                    </span>
                    <Table basic='very' celled textAlign={"center"} className={"sumup"} structured>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Projekte</Table.HeaderCell>
                                <Table.HeaderCell>Bestellungen</Table.HeaderCell>
                                <Table.HeaderCell>Kosten</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {suppliers.length === 0 &&
                            <Table.Row>
                                <Table.Cell colSpan={4}>
                                    Es existieren keine Zulieferer.
                                </Table.Cell>
                            </Table.Row>
                            }
                            {
                                suppliers.map(
                                    e => <Table.Row key={e.supplier_id} className={"selectable"}
                                                    onClick={() => this.props.history.push("./suppliers/" + e.supplier_id)}>
                                        <Table.Cell><Label content={e.supplier_display_name}/></Table.Cell>
                                        <Table.Cell><Header size={"medium"}
                                                            content={e.project_amount}/></Table.Cell>
                                        <Table.Cell><Header size={"medium"}
                                                            content={e.order_amount}/></Table.Cell>
                                        <Table.Cell><Header size={"medium"}
                                                            color={e.invoice_total > 0 ? "red" : undefined}
                                                            content={CurrencyForm.currencyString(e.invoice_total)}/></Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.Cell colSpan={3}
                                            textAlign={"right"}>
                                    <Header size={"small"} content={"Gesamtsumme"}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header size={"medium"}
                                            color={this.state.totalExpenses > 0 ? "red" : undefined}
                                            content={CurrencyForm.currencyString(this.state.totalExpenses)}/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Card.Content>
            </Card>
        </>);
    }

    header(content: ReactElement) {
        return <>
            <ContentHeader
                title={"Zulieferer: Übersicht"}
                isReady action={null} goBackButton>
                <div className={"item"}>
                    Für detaillierte Analyse Zulieferer auswählen
                </div>
            </ContentHeader>
            <RouterContent children={content}/>
        </>
    }

}


export default DataStore.withStore(SupplierOverviewReport)