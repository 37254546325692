import React, {Component, ReactElement} from "react";
import EntityForm from "../../../../base/forms/EntityForm";
import {api} from "../../../../models/InitialData";
import Helpers, {STANDARD_ID} from "../../../../base/Helpers";
import {MinimalFormData, ValueSelectorProps} from "../../../../base/forms/FormProps";
import CurrencyForm from "../../../../base/forms/CurrencyForm";
import {Button, Icon, Table} from "semantic-ui-react";
import DateForm from "../../../../base/forms/DateForm";
import ConfirmPortal from "../../../../base/forms/ConfirmPortal";
import _ from "lodash";

const defaultEmployeeCost = {
    id: -1,
    valid_from: null,
    hourly_earnings: 0,
};

const defaultState = {
    id: -1,
    costs: [] as typeof defaultEmployeeCost[],
};

const defaultMetaState = {
    createEarnings: 0,
    createValidFrom: new Date().toISOString(),
    create: false,
    confirmDelete: false,
    editIdx: -1,
};

class EmployeeCost extends Component<ValueSelectorProps<number>> {
    form: EntityForm<typeof defaultState, number, typeof defaultMetaState> | null = null;

    render(): ReactElement {
        return <EntityForm
            defaultState={defaultState}
            value={this.props.value}
            name={"employee_cost_id"}
            changeHandler={(n, v) => this.props.onChange(null, {name: n, value: v})}

            get={(value) => api("/reports/employee_cost/" + value, "GET")}
            delete={value => api("/employee_cost/" + value, "DELETE")}
            set={(value, state) => api("/report/employee_cost/" + value, "GET")}

            {...STANDARD_ID}

            render={this.externalRender}
            metaState={defaultMetaState}
        />
    }

    externalRender = (form: EntityForm<typeof defaultState, number, typeof defaultMetaState>): ReactElement => {
        if (this.form === null) {
            this.form = form;
        }

        return <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>gültig ab</Table.HeaderCell>
                    <Table.HeaderCell>Betrag</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {form.state.create
                    ? <Table.Row>
                        <Table.Cell>
                            <DateForm value={form.state.createValidFrom} label={""} name={"createValidFrom"}
                                      onChange={form.handleChange}/>
                        </Table.Cell>
                        <Table.Cell className={"vcenter-text"}>
                            <CurrencyForm value={form.state.createEarnings} label={""} name={"createEarnings"}
                                          onChange={form.handleChange}/>
                            <Button.Group style={{"margin": "0 0 0 5px"}}>
                                <Button icon onClick={() => form.setState({create: false})}>
                                    <Icon name='x'/>
                                </Button>
                                <Button icon primary onClick={() => this.createCostEntry(form)}>
                                    <Icon name='check'/>
                                </Button>
                            </Button.Group>
                        </Table.Cell>
                    </Table.Row>
                    : <Table.Row textAlign={"center"}>
                        <Table.Cell colSpan={2} content={<Button onClick={() => form.setState({create: true})}>neuer
                            Stundenlohn</Button>}/>
                    </Table.Row>}
                {form.state.costs.map((v: typeof defaultEmployeeCost, idx: number) => idx === form.state.editIdx
                    ? <Table.Row key={idx + "edit"}>
                        <Table.Cell>
                            {idx === form.state.costs.length - 1
                                ? <>seit Einstellung</>
                                : <DateForm value={v.valid_from} label={""} name={"valid_from"}
                                            onChange={(a, b) => this.onTableChange(a, b, idx, form)}/>}
                        </Table.Cell>
                        <Table.Cell className={"vcenter-text"}>
                            <CurrencyForm value={v.hourly_earnings} label={""} name={"hourly_earnings"}
                                          onChange={(a, b) => this.onTableChange(a, b, idx, form)}/>
                            <Button.Group style={{"margin": "0 0 0 5px"}}>
                                {idx !== form.state.costs.length - 1 &&
                                <Button negative icon onClick={() => form.setState({confirmDelete: true})}>
                                    <Icon name='trash'/>
                                </Button>
                                }
                                <Button icon primary onClick={() => this.updateCostEntry(idx, form)}>
                                    <Icon name='check'/>
                                </Button>
                                {
                                    form.state.confirmDelete &&
                                    <ConfirmPortal open ok={() => this.deleteCostEntry(v.id, form)}
                                                   cancel={() => form.setState({confirmDelete: false})}
                                                   text={"Lohn-Eintrag wirklich löschen?"}
                                    />
                                }
                            </Button.Group>
                        </Table.Cell>
                    </Table.Row>
                    : <Table.Row key={idx} onClick={() => form.setState({editIdx: idx})} className={"selectable"}>
                        <Table.Cell>{idx === form.state.costs.length - 1
                            ? <>seit Einstellung</>
                            : Helpers.dateString(v.valid_from)}</Table.Cell>
                        <Table.Cell>{CurrencyForm.currencyString(v.hourly_earnings)}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    };

    onTableChange = (a: any, v: MinimalFormData, idx: number, form: EntityForm<typeof defaultState, number, typeof defaultMetaState>) => {
        let costs = form.state.costs;
        let name = v.name ? v.name : "";

        costs[idx] = Object.assign(costs[idx], {[name]: v.value});
        form.setState({costs: costs});
    };

    updateCostEntry = (idx: number, form: EntityForm<typeof defaultState, number, typeof defaultMetaState>) => {
        if (!_.isEqual(form.state.costs, form.uneditedState.costs)) {

            api("/employee_cost/", "PATCH", {
                "id": form.state.costs[idx].id,
                "valid_from": form.state.costs[idx].valid_from,
                "hourly_earnings": form.state.costs[idx].hourly_earnings,
            }).then(() => form.doFetchEntity(this.props.value, true));
        }

        form.setState({editIdx: -1});
    };

    createCostEntry = (form: EntityForm<typeof defaultState, number, typeof defaultMetaState>) => {
        api("/employee_cost/", "PUT", {
            "employee_id": this.props.value,
            "valid_from": form.state.createValidFrom,
            "hourly_earnings": form.state.createEarnings,
        }).then(() => form.doFetchEntity(this.props.value, true))
            .then(() => form.setState({create: false, createEarnings: 0}));
    };

    deleteCostEntry = (idx: number, form: EntityForm<typeof defaultState, number, typeof defaultMetaState>) => {
        form.props.delete && form.props
            .delete(idx)
            .then(() => form.doFetchEntity(this.props.value, true));

        form.setState({editIdx: -1, confirmDelete: false});
    };
}

export default EmployeeCost
