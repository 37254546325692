import React, {Component, ReactElement} from "react";
import {DropdownItemProps, FormSelect} from "semantic-ui-react";
import DataStore, {StoreProps} from "../../models/DataStore";
import {DataFormProps} from "./FormProps";


class GroupSelector extends Component<StoreProps & DataFormProps<number>> {
    render(): ReactElement {
        if (!this.props.store.get("ready")) {
            return <></>
        }

        const groups: DropdownItemProps[] = Array
            .from(this.props.store.get("groups"), ([key, value]) => value)
            .map(
                (value) => {
                    return {
                        "key": value.id,
                        "value": value.id,
                        "text": value.name
                    }
                }
            );


        return <FormSelect
            label={this.props.label}
            options={groups}
            name={this.props.name}
            placeholder='unbekannt'
            value={this.props.value}
            onChange={this.props.onChange}
        />
    }
}

export default DataStore.withStore(GroupSelector);