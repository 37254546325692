import React, {Component, ReactElement} from "react";
import Store, {StoreProps} from "../../models/DataStore";
import {User} from "../../models/UserReport";
import {Button, Header, HeaderContent, Statistic} from "semantic-ui-react";
import {NavLink, RouteComponentProps} from "react-router-dom";
import {defaultEmployeeReport} from "../reports/EmployeeOverviewReport";
import Time from "../../models/Time";
import {api} from "../../models/InitialData";
import Groups, {hasAnyGroup} from "../../base/Groups";


class Overview extends Component<StoreProps & RouteComponentProps> {
    state = defaultEmployeeReport;

    componentDidMount(): void {
        api<typeof defaultEmployeeReport>("/reports/my_work_report", "GET")
            .then(resp => !resp.ok || resp.data === null
                ? this.props.history.goBack()
                : this.setState(resp.data));
    }

    render(): ReactElement {
        const user: User = this.props.store.get("user");
        const employee = this.state;

        return <>
            <Header size={"huge"}>
                <HeaderContent>
                    Hallo {user.person_id === -1 ? user.username : user.first_name}!
                </HeaderContent>
                <Header.Subheader>
                    Herzlich Willkommen im neuen Zeiterfassungs-System.
                </Header.Subheader>
            </Header>
            <br/>
            <br/>

            <Statistic.Group size={"small"} widths={4}>
                <Statistic label={"Urlaubstage"}
                           value={employee.used_vacation + " / " + employee.yearly_vacation}/>
                <Statistic label={"Überstunden"} color={employee.over_time_saldo < 0 ? "red" : undefined}
                           value={Time.parse(employee.over_time_saldo).toString()}/>
                <Statistic label={"Std. diesen Monat"}
                           value={Time.parse(employee.working_minutes_month).toString()}/>
                <Statistic label={"Krank"} value={employee.illness_days + " Tage"}/>
            </Statistic.Group>
            <br/>


            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            {
                hasAnyGroup(this.props, Groups.DEVELOPER, Groups.ACQUISITION, Groups.MANAGEMENT, Groups.PROJECTLEAD)
                &&
                <div>
                    <h2>Verwaltung</h2>
                    {
                        hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT) &&
                        <NavLink to={"/entities"}>
                            <Button size={"huge"} content={"Mitarbeiter"}/>
                        </NavLink>
                    }
                    <NavLink to={"/entities/customers"}>
                        <Button size={"huge"} content={"Kunden"}/>
                    </NavLink>
                    <NavLink to={"/entities/suppliers"}>
                        <Button size={"huge"} content={"Zulieferer"}/>
                    </NavLink>
                    {
                        hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT) &&
                        <NavLink to={"/basedata/fixcosts"}>
                            <Button size={"huge"} content={"Fixkosten"}/>
                        </NavLink>
                    }
                    <br/>
                    <br/>
                </div>
            }
            <div>
                <h2>Direktlinks</h2>
                <NavLink to={"/projects/list/all"}>
                    <Button size={"huge"} content={"Projekte"}/>
                </NavLink>
                <NavLink to={"/time_management?killOvertime=true"}>
                    <Button size={"huge"} content={"Überstunden abbummeln"}/>
                </NavLink>
            </div>
        </>
    }
}

export default Store.withStore(Overview);