import React, {Component, ReactElement} from "react";
import SearchList from "../../../base/forms/SearchList";
import {api} from "../../../models/InitialData";
import {Card} from "semantic-ui-react";
import {MenuActionProps} from "../../../base/MainMenu";
import CustomerView from "./views/CustomerView";
import Groups, {hasAnyGroup} from "../../../base/Groups";
import {goHome} from "../../../base/JSXHelpers";
import DataStore, {StoreProps} from "../../../models/DataStore";

export const defaultCustomer = {
    "id": -1,
    "person_id": -1,
    "first_name": "",
    "last_name": "",
    "date_of_birth": null,
    "gender_id": -1,
    "telephone_fixed": "",
    "telephone_mobile": "",
    "telephone_fax": "",
    "email": "",
    "address_id": -1,
    "notes": "",
    "created_at": null,
    "updated_at": null,
    "is_deleted": false,
    "company_id": -1,
    "company_name": "",
};


class CustomerManagement extends Component<MenuActionProps & { create: boolean } & StoreProps> {
    componentDidMount(): void {
        this.props.menu("Kunden erstellen");
    }

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.ACQUISITION, Groups.MANAGEMENT, Groups.PROJECTLEAD)) {
            return goHome()
        }

        return <SearchList
            match={(p: typeof defaultCustomer) => p.first_name + " " + p.last_name + " " + p.company_name}

            get={() => api("/reports/customers", "GET")}

            searchLabel={"nach Kunden suchen"}
            placeholder={"Max Mustermann"}

            pageSize={14}

            render={(m, list) => <>
                {this.props.create &&
                <CustomerView name={"customer_id"} value={-1} create entity onChange={() => {
                    this.props.cancelMenu();
                    list.componentDidMount();
                }}/>}


                <Card.Group itemsPerRow={2}>
                    {
                        m.map(
                            (c: typeof defaultCustomer) => {
                                return <CustomerView value={c.id} key={c.id} preset={c} name={"customer_id"}
                                                     onChange={() => list.componentDidMount()} entity/>
                            }
                        )
                    }
                    {m.length === 0 && <Card fluid><Card.Content>Keine Ergebnisse gefunden</Card.Content></Card>}
                </Card.Group>
            </>
            }
        />
    }
}

export default DataStore.withStore(CustomerManagement)