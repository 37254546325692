import React, {Component, ReactElement} from "react";
import ContentHeader from "../../base/ContentHeader";
import MenuLink from "../../base/MenuLink";
import RouterContent from "../../base/RouterContent";
import {Route, RouteComponentProps, Switch} from "react-router";
import Helpers from "../../base/Helpers";
import {Redirect} from "react-router-dom";
import SingleProject from "./SingleProject";
import ProjectsList from "./ProjectsList";


class Projects extends Component<RouteComponentProps> {

    state = {
        menuAction: null,
        createObj: false
    };


    render(): ReactElement {
        const path = this.props.match.path;

        return <>
            <Switch>
                <Route path={path + "/list/archive"}>
                    {this.projectsHeader(path)}
                    <RouterContent>
                        <ProjectsList create={this.state.createObj} menu={Helpers.setMenuAction(this)}
                                      cancelMenu={Helpers.cancelAction(this)}
                                      {...this.props} subGroup={"archive"}/>
                    </RouterContent>
                </Route>
                <Route path={path + "/list/all"}>
                    {this.projectsHeader(path)}
                    <RouterContent>
                        <ProjectsList create={this.state.createObj} menu={Helpers.setMenuAction(this)}
                                      cancelMenu={Helpers.cancelAction(this)}
                                      {...this.props} subGroup={"all"}/>
                    </RouterContent>
                </Route>
                <Route path={path + "/list/atwork"}>
                    {this.projectsHeader(path)}
                    <RouterContent>
                        <ProjectsList create={this.state.createObj} menu={Helpers.setMenuAction(this)}
                                      cancelMenu={Helpers.cancelAction(this)}
                                      {...this.props} subGroup={"atwork"}/>
                    </RouterContent>
                </Route>

                <Route path={path + "/view/:id"} component={SingleProject}/>
                <Redirect to={path + "/list/atwork"}/>
            </Switch>
        </>
    }

    projectsHeader = (path: string) => {
        return <ContentHeader title={"Projekte"} isReady={true}
                              action={this.state.menuAction}>
            <MenuLink to={path + "/list/atwork"}>in Arbeit</MenuLink>
            <MenuLink to={path + "/list/all"}>alle</MenuLink>
            <MenuLink to={path + "/list/archive"}>Archiv</MenuLink>
        </ContentHeader>;
    }
}


export default Projects