import React, {Component, ReactElement} from "react";
import {Button, Card, Container, Header, Select} from "semantic-ui-react";
import {MenuActionProps} from "../../base/MainMenu";
import SearchList from "../../base/forms/SearchList";
import {api} from "../../models/InitialData";
import {RouteComponentProps} from "react-router";
import ProjectView, {defaultProject} from "./views/ProjectView";
import GetList from "../../base/forms/GetList";
import _ from "lodash";
import Groups, {hasAnyGroup} from "../../base/Groups";
import DataStore, {StoreProps} from "../../models/DataStore";

const projectOrderBy = [
    {key: 'pj.start_date', value: 'pj.start_date', text: 'Startdatum'},
    {key: 'pj.expected_end_date', value: 'pj.expected_end_date', text: 'Enddatum'},
    {key: 'pj.project_number', value: 'pj.project_number', text: 'Projektnummer'},
    {key: 'pj.name', value: 'pj.name', text: 'Projektname'},
];

class ProjectsList extends Component<{ create: boolean, subGroup?: string } & MenuActionProps & RouteComponentProps & StoreProps> {
    listPointer: GetList<any, any> | null = null;

    state = {
        orderByColumn: "pj.start_date",
        orderByAscending: false,
    };

    componentDidUpdate(prevProps: { subGroup?: string }, prevState: Readonly<{}>): void {
        if ((!_.isEqual(prevState, this.state) || prevProps.subGroup !== this.props.subGroup)
            && this.listPointer !== null) {

            this.listPointer.componentDidMount();
        }
    }

    componentDidMount(): void {
        if (hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT, Groups.ACQUISITION, Groups.PROJECTLEAD)) {
            this.props.menu("Projekt erstellen");
        }
    }

    render(): ReactElement {
        return <>
            <Container style={{marginBottom: "20px"}} textAlign={"right"}>
                <Header size={"small"} style={{"margin": "0 15px 0 0", "display": "inline-block"}}>Sortieren
                    nach</Header>
                <Select placeholder='Auswählen'
                        value={this.state.orderByColumn}
                        onChange={(a, v) =>
                            this.setState({orderByColumn: v.value})}
                        options={projectOrderBy}/>
                <Button.Group size={"large"} style={{marginLeft: "10px"}}>
                    <Button icon={'arrow up'} active={this.state.orderByAscending}
                            onClick={() => this.setState({orderByAscending: true})}/>
                    <Button icon={'arrow down'} active={!this.state.orderByAscending}
                            onClick={() => this.setState({orderByAscending: false})}/>
                </Button.Group>
            </Container>
            <SearchList
                match={(m: typeof defaultProject) => m.project_number + " " + m.name
                    + " " + m.customer_display_name + " " + m.company_name}

                get={() => api("/reports/projects?orderBy="
                    + encodeURIComponent(this.state.orderByColumn
                        + (this.state.orderByAscending ? " ASC" : " DESC"))
                    + (this.props.subGroup ? "&subGroup=" + this.props.subGroup : ""), "GET")}

                searchLabel={"nach Namen oder Datum suchen"}
                placeholder={"z. B. 20190301 Neue Website"}

                pageSize={7}

                render={(m, list) => {
                    if (this.listPointer === null) {
                        this.listPointer = list;
                    }

                    return <>
                        {this.props.create &&
                        <ProjectView name={"customer_id"} value={-1} create entity onChange={() => {
                            this.props.cancelMenu();
                            list.componentDidMount();
                        }}/>}
                        <Card.Group>
                            {
                                m.map(
                                    (e) => {
                                        return <ProjectView value={e.id} key={e.id} preset={e} name={"project_id"}
                                                            onChange={() => list.componentDidMount()} entity
                                                            onClick={() => this.props.history
                                                                .push(this.props.match.path + "/view/" + e.id)}/>
                                    }
                                )
                            }
                            {m.length === 0 &&
                            <Card fluid><Card.Content>Keine Ergebnisse gefunden</Card.Content></Card>}
                        </Card.Group>
                    </>;
                }}
            />
        </>
    }
}


export default DataStore.withStore(ProjectsList);