import React, {Component, ReactElement} from "react";
import EntityForm from "../../../base/forms/EntityForm";
import {Button, Card, Checkbox, Form, FormField} from "semantic-ui-react";
import CurrencyForm from "../../../base/forms/CurrencyForm";
import GroupSelector from "../../../base/forms/GroupSelector";
import NewOptionalPersonForm from "./OptionalPersonForm";
import Helpers, {STANDARD_ID} from "../../../base/Helpers";
import {api} from "../../../models/InitialData";
import NumberSelector from "../../../base/forms/NumberSelector";

const defaultState = {
    id: -1,
    address_id: -1,
    username: "",
    person_id: -1,
    current_hourly_earnings: 0,
    weekly_hours: 0,
    yearly_vacation: 20,
    group_id: 2,
    is_hidden: false,
    new_password: ""
};

class CreateEmployeeForm extends Component<{ cancelCreate: () => void }> {
    editing = (form: EntityForm<typeof defaultState, number, {}>): ReactElement => {
        return <Card
            style={{width: "100%"}}>
            <Card.Content>
                <Form>
                    <Form.Group widths={2}>
                        <Form.Input fluid label='Nutzername' name='username'
                                    value={form.state.username}
                                    onChange={form.handleChange} type='text' placeholder='nutzer_name123'/>
                        <Form.Input fluid label='neues Passwort' name='new_password'
                                    onChange={form.handleChange}
                                    value={form.state.new_password} type='password'
                                    placeholder='supergeheim'/>
                    </Form.Group>
                    <Form.Group widths={3}>
                        <CurrencyForm label='Stundenlohn'
                                      name='current_hourly_earnings'
                                      value={form.state.current_hourly_earnings}
                                      onChange={form.handleChange}/>
                        <NumberSelector label='wöchentliche Stundenzahl' name='weekly_hours'
                                        onChange={form.handleChange} value={form.state.weekly_hours}
                                        placeholder='XX'/>
                        <NumberSelector label='jährliche Urlaubstage' name='yearly_vacation'
                                        onChange={form.handleChange} value={form.state.yearly_vacation}
                                        placeholder='XX'/>
                    </Form.Group>
                    <Form.Group widths={2}>
                        <GroupSelector name={"group_id"}
                                       value={form.state.group_id} onChange={form.handleChange}
                                       label={"Nutzer-Gruppe"}/>

                        <FormField>
                            <label>Profil in Berichten anzeigen</label>
                            <Checkbox label={form.state.is_hidden ? "wird versteckt" : "wird angezeigt"}
                                      className={"vcenter-text"} name={'is_hidden'} toggle
                                      onChange={(a, data) => form.handleChange(a, {
                                          name: data.name,
                                          value: !data.checked
                                      })}
                                      checked={!form.state.is_hidden}/>
                        </FormField>
                    </Form.Group>
                </Form>
            </Card.Content>
            <Card.Content>
                <NewOptionalPersonForm name={"person_id"} value={form.state.person_id} onChange={form.handleChange}/>
            </Card.Content>
            <Card.Content>
                {form.getTemporaryError()}
                <Button primary floated="right" onClick={() => form.doFinishEditing()}>Speichern</Button>
                <Button floated="right" onClick={() => this.props.cancelCreate()}>Abbrechen</Button>
            </Card.Content>
        </Card>
    };

    render(): ReactElement {
        return <EntityForm
            defaultState={defaultState}
            value={-1}
            name={"employee_id"}

            get={() => Helpers.getStatic(defaultState)}
            set={(value, state) => api("/employee/", "PUT", Helpers.stripPassword(state))}

            {...STANDARD_ID}

            metaState={{"entityForm_editing": true}}
            changeHandler={() => {
                this.props.cancelCreate()
            }}
            render={this.editing}
        />
    }
}

export default CreateEmployeeForm