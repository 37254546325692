import React, {Component, ReactElement} from "react";
import {Divider, Form, Icon, Pagination} from "semantic-ui-react";
import GetList, {GetListProps} from "./GetList";

interface SearchListProps<Model, Index> extends GetListProps<Model, Index> {
    match: (model: Model) => string

    searchLabel: string
    placeholder: string
    pageSize?: number
}

const defaultState = {
    "query": "",
    "currentPage": 1
};

class SearchList<Model, Index> extends Component<SearchListProps<Model, Index>> {
    state = defaultState;

    render(): ReactElement {
        return <GetList get={this.props.get} render={this.embed} updateCallback={this.props.updateCallback}/>
    }

    embed = (models: Model[], list: GetList<Model, Index>): ReactElement => {
        let ms = list.state.definiteError === "" ? list.state.values.filter(m => this.props.match(m)
            .toLowerCase().indexOf(this.state.query.toLowerCase()) > -1) : [];

        let totalPages = 1;
        if (this.props.pageSize) {
            totalPages = Math.ceil(ms.length / this.props.pageSize);
            ms = ms.slice((this.state.currentPage - 1) * this.props.pageSize, this.state.currentPage * this.props.pageSize);
        }

        return <>
            <Form>
                <Form.Input
                    fluid
                    label={this.props.searchLabel}
                    placeholder={this.props.placeholder}
                    action={{
                        disabled: this.state.query === "",
                        icon: "cancel",
                        onClick: () => {
                            this.setState({"query": "", "currentPage": 1})
                        }
                    }}
                    value={this.state.query}
                    onChange={(e, v) => this.setState({"query": v.value, "currentPage": 1})}
                />
            </Form>
            <Divider/>
            {this.props.render(ms, list)}
            {totalPages > 1 &&
            <>
                <Divider/>
                <div className={"vcenter-text"}>
                    <h3 style={{"margin": "0"}}>Seitenauswahl</h3>
                    <Pagination
                        activePage={this.state.currentPage}
                        ellipsisItem={{content: <Icon name='ellipsis horizontal'/>, icon: true}}
                        firstItem={{content: <Icon name='angle double left'/>, icon: true}}
                        lastItem={{content: <Icon name='angle double right'/>, icon: true}}
                        prevItem={{content: <Icon name='angle left'/>, icon: true}}
                        nextItem={{content: <Icon name='angle right'/>, icon: true}}
                        totalPages={totalPages}

                        onPageChange={(a, data) => this.setState({"currentPage": data.activePage})}
                    />
                </div>
            </>
            }
        </>
    }
}

export default SearchList