import React, {Component, ReactElement} from "react";
import {assembleTable, defaultProjectCost, projectCostTableColsKey} from "../projects/SingleProject";
import {api} from "../../models/InitialData";
import {RouteComponentProps} from "react-router-dom";
import ContentHeader from "../../base/ContentHeader";
import RouterContent from "../../base/RouterContent";
import {Card, Header, Input, SemanticCOLORS, Table} from "semantic-ui-react";
import CurrencyForm from "../../base/forms/CurrencyForm";
import DataStore, {StoreProps} from "../../models/DataStore";
import Groups, {hasAnyGroup} from "../../base/Groups";
import {goHome} from "../../base/JSXHelpers";


const cols = ["project_name", "total_time", "actual_returns", "total_cost", "total_equity_gained"] as projectCostTableColsKey[];

class CompanySuccessReport extends Component<RouteComponentProps & StoreProps> {
    thisYear = new Date().getUTCFullYear() + "";

    state = {
        year: this.thisYear,
        editYear: this.thisYear,
        totalEquityGained: 0,
        projects: [] as typeof defaultProjectCost[],
    };


    componentDidMount(): void {
        api<typeof defaultProjectCost[]>("/reports/company_success?year=" + this.state.year, "GET")
            .then(resp => !resp.ok || resp.data === null
                ? this.props.history.goBack()
                : this.setState({
                    projects: resp.data,
                    totalEquityGained: resp.data.length === 0 ? 0 :
                        resp.data
                            .map(m => m.actual_returns - m.total_employee_cost - m.total_external_input)
                            .reduce((l, p) => l + p)
                }));
    }

    componentDidUpdate(prevProps: any, prevState: Readonly<{ year: string, project_work: typeof defaultProjectCost[] }>): void {
        if (prevState.year !== this.state.year) {
            this.componentDidMount();
        }
    }

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT)) {
            return goHome()
        }


        const projects = this.state.projects;

        let equityColor: SemanticCOLORS | undefined = undefined;
        if (this.state.totalEquityGained !== 0) {
            equityColor = this.state.totalEquityGained > 0 ? "green" : "red";
        }

        return this.header(<>
            <Card fluid>
                <Card.Content>
                    <h2 style={{display: "inline-block"}}>Ertrag</h2>
                    <span style={{float: "right"}}>
                        <Header size={"small"} style={{"margin": "0 15px 0 0", "display": "inline-block"}}>
                            Jahr
                        </Header>
                        <Input value={this.state.editYear}
                               action={{
                                   disabled: this.state.year === this.state.editYear,
                                   icon: "check", onClick: () => this.setState({year: this.state.editYear})
                               }}
                               onChange={(a, v) => this.setState({editYear: v.value})}
                               type='number' placeholder='XXXX' size={"small"}/>
                    </span>
                    <Table basic='very' celled textAlign={"center"} className={"sumup"} structured>
                        {projects.length > 0 &&
                        assembleTable(cols, projects, (state) => this.props.history.push("/projects/view/" + state.project_id))}
                        {projects.length === 0 &&
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell colSpan={cols.length}>
                                    Keine Projekte in diesem Zeitraum
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>}
                        <Table.Footer>
                            <Table.Row>
                                <Table.Cell colSpan={cols.length - 1}
                                            textAlign={"right"}>
                                    <Header size={"small"} content={"Gesamtsumme"}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header size={"medium"}
                                            color={equityColor}
                                            content={CurrencyForm.currencyString(this.state.totalEquityGained)}/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Card.Content>
            </Card>
        </>);
    }


    header(content: ReactElement) {
        return <>
            <ContentHeader
                title={"Unternehmenserfolg"}
                isReady action={null} goBackButton>
                <div className={"item"}>
                    Analyse aller Projekte aus {this.state.year}
                </div>
            </ContentHeader>
            <RouterContent children={content}/>
        </>
    }
}

export default DataStore.withStore(CompanySuccessReport)