import React, {Component, ReactElement} from "react";
import {Button, FormField, Input, Table} from "semantic-ui-react";
import DateForm from "../../../base/forms/DateForm";
import {FormData} from "../../../base/forms/FormProps";
import Time from "../../../models/Time";
import Helpers from "../../../base/Helpers";


export const defaultDateAssociation = [{
    date: new Date().toISOString(),
    working_time: Time.ZERO,
    over_time: Time.ZERO
}];

interface DateProps {
    value: typeof defaultDateAssociation
    onChange: (dates: typeof defaultDateAssociation) => void
    extendable?: boolean,
    workingTimeNumber?: boolean
    disableWorkingTime?: boolean,
    disableOverTime?: boolean
}

const dateAction = {
    icon: "close"
};

class DateAssociation extends Component<DateProps> {


    componentDidUpdate(prevProps: Readonly<DateProps>): void {
        let newValues = this.props.value;
        let executed = false;

        if (prevProps.workingTimeNumber !== this.props.workingTimeNumber
            && this.props.workingTimeNumber) {

            executed = true;
            for (let v of newValues) {
                v.working_time = Time.parse(v.working_time.getHours() * 60)
            }
        }


        if (prevProps.disableWorkingTime !== this.props.disableWorkingTime
            && this.props.disableWorkingTime) {

            executed = true;
            for (let v of newValues) {
                v.working_time = Time.ZERO
            }
        }

        if (prevProps.disableOverTime !== this.props.disableOverTime
            && this.props.disableOverTime) {

            executed = true;
            for (let v of newValues) {
                v.over_time = Time.ZERO
            }
        }

        if (executed) {
            this.props.onChange(newValues)
        }
    }

    render(): ReactElement {
        const hasAction = this.props.value.length > 1;

        return <FormField>
            <Table basic='very' className={"associatedays-form"}>
                <Table.Body>
                    {this.props.value.map((val, idx) => {
                        let actualAction = {};
                        let actualLabel = ["Datum", "Arbeitszeit", "neue Überstunden", "Überstunden"];

                        if (hasAction) {
                            actualAction = {
                                action: Object.assign({}, {
                                    ...dateAction,
                                    onClick: () => this.removeDay(idx)
                                })
                            };
                        }

                        if (idx >= 1) {
                            actualLabel = ["", "", "", ""];
                        }

                        return <Table.Row key={idx}>
                            <Table.Cell>
                                <DateForm notFluid={true} label={actualLabel[0]} name='date'
                                          onChange={this.handleChange(idx)} {...actualAction}
                                          value={val.date}/>
                            </Table.Cell>
                            <Table.Cell>
                                {this.props.workingTimeNumber ?
                                    <FormField
                                        control={Input}
                                        label={actualLabel[3]}
                                        type={"number"}
                                        placeholder={"HH"}
                                        name={"working_time"}
                                        min={0}
                                        value={val.working_time.getHours()}
                                        onChange={(a: any, v: FormData) =>
                                            this.handleChange(idx)(a, {name: v.name, value: v.value + ":00"})}
                                        disabled={this.props.disableWorkingTime}
                                    />
                                    : <FormField
                                        control={Input}
                                        label={actualLabel[1]}
                                        type={"time"}
                                        placeholder={"HH:MM"}
                                        name={"working_time"}
                                        value={val.working_time.export()}
                                        onChange={this.handleChange(idx)}
                                        disabled={this.props.disableWorkingTime}
                                    />}
                            </Table.Cell>
                            <Table.Cell>
                                <FormField
                                    control={Input}
                                    label={actualLabel[2]}
                                    type={"time"}
                                    placeholder={"HH:MM"}
                                    name={"over_time"}
                                    value={val.over_time.export()}
                                    onChange={this.handleChange(idx)}
                                    disabled={this.props.disableOverTime}
                                    error={true}
                                />
                            </Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>
            {this.props.extendable &&
            <Button content='ein weiterer Tag' icon='plus' labelPosition='left' onClick={() => this.addDay()}/>}
        </FormField>
    }

    handleChange(idx: number) {
        return (a: any, value: FormData): void => {
            let newDates = this.props.value;
            let newValue = value.value;
            const newName = value.name ? value.name : "";

            if (newName === "date") {
                newValue = value.value !== null ? new Date(value.value).toISOString() : value.value;
            } else {
                newValue = Time.import(value.value);
            }

            newDates[idx] = Object.assign(newDates[idx], {[newName]: newValue});
            this.props.onChange(newDates)
        }
    }

    addDay() {
        let result: Date;
        let newDates = this.props.value;

        if (newDates.length > 0) {
            result = new Date(Helpers.isoDate(newDates[newDates.length - 1].date));
            result.setDate(result.getDate() + 1)
        } else {
            result = new Date()
        }

        newDates.push({date: result.toISOString(), working_time: Time.ZERO, over_time: Time.ZERO});
        this.props.onChange(newDates)
    }

    removeDay(idx: number) {
        let newDates = this.props.value;
        newDates.splice(idx, 1);


        this.props.onChange(newDates)
    }
}

export default DateAssociation