import React, {Component, ReactElement} from "react";
import {FormField, FormGroup, Input} from "semantic-ui-react";
import {FormData} from "../../../base/forms/FormProps";
import Helpers, {STANDARD_ID} from "../../../base/Helpers";
import SearchSelection, {DisplayResult} from "../../../base/forms/SearchSelection";
import ProductSelector from "../../../base/forms/ProductSelector";
import DataStore, {getOrEmpty, StoreProps} from "../../../models/DataStore";
import {Product} from "../../../models/Product";

export const defaultProjectState = {
    id: -2,
    project_number: "",
    name: "",
    company_name: "",
};

const defaultProductState = {
    id: -1,
    short_name: "",
    category_name: "",
    notes: ""
};

export const defaultProjectAssociation = {
    project_id: defaultProjectState.id,
    project_name: defaultProjectState.name,
    project_number: "",
    company_name: defaultProjectState.company_name,
    product_id: defaultProductState.id,
    product_short_name: defaultProductState.short_name,
    category_name: defaultProductState.category_name,
    notes: defaultProductState.notes
};

class ProjectAssociation extends Component<typeof defaultProjectAssociation & { onChange: (i: any, data: FormData) => void } & StoreProps> {


    render(): ReactElement {
        if (!this.props.store.get("ready")) {
            return <></>
        }

        const products = this.props.store.get("products");
        let preset = "";

        if (this.props.project_id === -1) {
            preset = "kein Projekt"
        } else if (this.props.project_id !== defaultProjectState.id
            && this.props.project_name.length > 0) {
            preset = this.props.project_number + " " + this.props.project_name
                + " für " + this.props.company_name;
        }

        const projectDisabled = getOrEmpty(products.get(this.props.product_id), "category_id") === "1";

        return <>
            <FormGroup widths={2}>
                <FormField>
                    <label>Beschäftigung bzw. Produkt</label>
                    <ProductSelector value={this.props.product_id} name={"product_id"}
                                     onChange={(i, v) => this.onChangeFilter(i, v, products)}/>
                </FormField>
                <FormField>
                    <label>Projekt</label>
                    <SearchSelection get={getSearchProject}
                                     resultFetcher={fetchProjectSearchResults}
                                     name={"project_id"}
                                     onChange={this.props.onChange}
                                     placeholder={"nach Projekt suchen"}
                                     defaultState={defaultProjectState}
                                     preset={preset}
                                     disabled={projectDisabled}

                                     {...STANDARD_ID}
                    />
                </FormField>
            </FormGroup>
            <FormField>
                <label>Bemerkungen</label>
                <Input type={"text"} fluid
                       placeholder={this.props.product_id === 3 ? "Ausgezahlt oder frei genommen?"
                           : "diese Einheit hat mir sehr viel Spaß gemacht..."}
                       name={"notes"}
                       value={this.props.notes}
                       onChange={this.props.onChange}/>
            </FormField>
        </>
    }

    onChangeFilter = (i: any, val: FormData, products: Map<number, Product>) => {
        if (val.name === "product_id") {
            const categoryId = getOrEmpty(products.get(val.value), "category_id");

            if (categoryId === "1") {
                this.props.onChange(i, {
                    "name": "project_id",
                    "value": -1,
                });
            }
        }

        this.props.onChange(i, val);
    };



    getSearchProduct = (query: string): Promise<typeof defaultProductState[]> =>
        Helpers.getSearch("/reports/search_product", query);


    fetchProductSearchResults = (models: typeof defaultProductState[]): DisplayResult<number>[] => {
        return models.map(
            search => {
                return {
                    title: search.short_name,
                    description: "Kategorie " + search.category_name,
                    value: search.id
                }
            }
        )
    };
}

export function getSearchProject(query: string): Promise<typeof defaultProjectState[]> {
    return Helpers.getSearch("/reports/search_project", query);
}


export function fetchProjectSearchResults(models: typeof defaultProjectState[]): DisplayResult<number>[] {
    return models.map(
        search => {
            return {
                title: search.project_number + " " + search.name,
                description: "für " + search.company_name,
                value: search.id
            }
        }
    )
};

export default DataStore.withStore(ProjectAssociation)