import * as React from "react";
import {Component, ReactElement} from "react";
import {Button, CardContent} from "semantic-ui-react";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import ConfirmPortal from "./ConfirmPortal";
import EntityForm, {EntityFormProps} from "./EntityForm";

interface OptionalEntityFormProps<State, Index> extends EntityFormProps<State, Index> {
    notPresentText: string,
    deleteEntity?: boolean
    unDeletable?: boolean
    fullSizeEdit?: boolean
    onClick?: "edit" | "none" | (() => void)
    create?: boolean
    onCancel?: () => void
    forbidEditing?: boolean

    preview: (state: State) => ReactElement
    editing: (form: EntityForm<State, Index, OptionalEntityFormState>) => ReactElement
}

export interface OptionalEntityFormState {
    optionalEntityForm_confirmDeleteLink: boolean
}

class OptionalEntityForm<State, Index> extends Component<OptionalEntityFormProps<State, Index>> {
    defaultEditing = this.props.create ? {"entityForm_editing": true} : {};

    initialFormState: OptionalEntityFormState = Object.assign({
        optionalEntityForm_confirmDeleteLink: false
    }, this.defaultEditing);


    externalRender = (form: EntityForm<State, Index, OptionalEntityFormState>): ReactElement => {
        let content: ReactElement;
        let bottomButtons: ReactElement | null = null;
        let additionalProperties = {};

        if (form.state.entityForm_editing) {
            // edit / entity-creation mode

            if (this.props.fullSizeEdit) {
                Object.assign(additionalProperties, {"style": {"width": "100%"}})
            }

            content = this.props.editing(form);
            bottomButtons = <CardContent extra>
                {
                    !this.props.unDeletable &&
                    this.props.getIdFromState(form.state) !== this.props.getIdFromState(this.props.defaultState) &&
                    <>
                        {this.props.deleteEntity
                            ? <Button floated="left" negative
                                      onClick={() => this.confirmDeleteLinking(form, true)}>Löschen</Button>
                            : <a style={{"float": "left"}} onClick={() =>
                                this.confirmDeleteLinking(form, true)}>Verknüpfung aufheben</a>}
                        {
                            form.state.optionalEntityForm_confirmDeleteLink &&
                            <ConfirmPortal open
                                           ok={() => this.props.deleteEntity ? form.doDeleteEntity() : form.doDeleteLinking()}
                                           cancel={() => this.confirmDeleteLinking(form, false)}
                                           text={this.props.deleteEntity ? "Entität wirklich löschen?" : "Verknüpfung wirklich aufheben?"}
                            />
                        }
                    </>
                }
                <Button type='submit' primary floated="right" onClick={form.doFinishEditing}>Speichern</Button>
                <Button type='reset' floated="right" onClick={() => {
                    form.doCancelEditing();
                    if (this.props.onCancel) {
                        this.props.onCancel();
                    }
                }}>Abbrechen</Button>
            </CardContent>
        } else if (this.props.getIdFromState(form.state) === this.props.getIdFromState(this.props.defaultState)) {
            // no entity yet
            content = <>
                {
                    !this.props.forbidEditing &&
                    <Button icon='plus' floated="right" size="mini"
                            onClick={form.doStartEditing}
                            circular/>
                }
                {this.props.notPresentText}
            </>
        } else {
            // normal preview

            if (this.props.onClick === "edit" && !this.props.forbidEditing) {
                Object.assign(additionalProperties, {"onClick": form.doStartEditing})
            } else if (typeof this.props.onClick === "function") {
                Object.assign(additionalProperties, {"onClick": this.props.onClick})
            }

            content = <>
                {this.props.onClick !== "edit" && !this.props.forbidEditing &&
                <Button icon='pencil alternate' floated="right" size="mini"
                        onClick={(e) => {
                            e.stopPropagation();
                            form.doStartEditing();
                        }}
                        circular/>}
                {this.props.preview(form.state)}
            </>
        }


        return <>
            <Card fluid {...additionalProperties}>
                <CardContent>
                    {content}
                    {form.getTemporaryError()}
                </CardContent>
                {bottomButtons === null ? <></> : bottomButtons}
            </Card>
        </>
    };

    confirmDeleteLinking = (form: EntityForm<State, Index, OptionalEntityFormState>, value: boolean) => {
        form.setState({"optionalEntityForm_confirmDeleteLink": value})
    };


    render(): ReactElement {
        return <EntityForm
            defaultState={this.props.defaultState}
            value={this.props.value}
            name={this.props.name}

            changeHandler={this.props.changeHandler}
            get={this.props.get}
            set={this.props.set}
            delete={this.props.delete}
            getIdFromState={this.props.getIdFromState}
            setIdOnState={this.props.setIdOnState}
            preset={this.props.preset}

            render={this.externalRender}
            metaState={this.initialFormState}
        />
    }
}

export default OptionalEntityForm