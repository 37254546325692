import React, {Component, ReactElement, ReactNode} from "react";
import {Container} from "semantic-ui-react";


class RouterContent extends Component<{ children?: ReactNode }> {
    render(): ReactElement {
        return (
            <Container text id='router-content'>
                {this.props.children}
            </Container>
        )
    }
}

export default RouterContent;