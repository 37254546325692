import React, {Component, ReactElement} from "react";
import {CardDescription, CardHeader, CardMeta, Divider, Form, FormField, Label, TextArea} from "semantic-ui-react";
import Helpers, {STANDARD_ID} from "../../../../base/Helpers";
import {api} from "../../../../models/InitialData";
import EntityForm from "../../../../base/forms/EntityForm";
import {defaultCompany} from "../Companies";
import OptionalAddressForm, {addressPreview} from "../../forms/OptionalAddressForm";
import DataStore, {StoreProps} from "../../../../models/DataStore";
import OptionalEntityForm, {OptionalEntityFormState} from "../../../../base/forms/OptionalEntityForm";
import {FormData, PresetReceiver, ValueSelectorProps} from "../../../../base/forms/FormProps";
import SearchSelection, {DisplayResult} from "../../../../base/forms/SearchSelection";

class OptionalCompanyForm extends Component<{ create?: boolean, entity?: boolean } & StoreProps
    & ValueSelectorProps<number> & PresetReceiver<typeof defaultCompany>> {

    render(): ReactElement {
        return <OptionalEntityForm
            defaultState={defaultCompany}
            value={this.props.value}
            name={"company_id"}
            notPresentText={"noch kein Unternehmen ausgewählt"}

            preset={this.props.preset}
            get={(value) => api("/reports/aggregated_company/" + value, "GET")}
            set={(value, state) => api("/company/", value === defaultCompany.id ? "PUT" : "PATCH", state)}
            unDeletable={this.props.entity}
            fullSizeEdit={this.props.entity}
            onClick={this.props.entity ? "edit" : undefined}
            create={this.props.create}
            onCancel={this.doCancelEditing}

            changeHandler={this.changeHandler}
            {...STANDARD_ID}

            editing={this.editing}
            preview={this.preview}
        />
    }

    changeHandler = (name: string, id: number) => {
        this.props.onChange(null, {name: name, value: id});
    };

    editing = (form: EntityForm<typeof defaultCompany, number, OptionalEntityFormState>): ReactElement => {
        const addressPreset = Helpers.immCopy(form.state);
        addressPreset.id = addressPreset.address_id;

        return <>
            <h2>Unternehmen</h2>
            <div className={"ui form"}>
                {form.state.id === -1 && !this.props.create &&
                <>
                    <SearchSelection get={this.getSearch}
                                     resultFetcher={this.fetchSearchResults}
                                     name={"id"}
                                     onChange={(a: any, b: FormData) => form.setValue(b.value)}
                                     placeholder={"nach bestehendem Unternehmen suchen"}
                                     defaultState={defaultCompany}

                                     {...STANDARD_ID}
                    />
                    <Divider/>
                </>
                }
                <Form.Input fluid label='Name' name='name' value={form.state.name}
                            onChange={form.handleChange} placeholder='Beispiel Gesellschaft mbH'/>
                <FormField>
                    <label>Notizen</label>
                    <TextArea name='notes'
                              onChange={form.handleChange}
                              value={form.state.notes} type='text' placeholder='Raum für Notizen'/>
                </FormField>
                <OptionalAddressForm
                    name={"address_id"}
                    prefix={"sitzt an"}
                    value={form.state.address_id}
                    preset={addressPreset}

                    onChange={(a, model) =>
                        form.doUpdateEntity({"address_id": model.value})
                            .then(() => form.doFetchEntity(form.state.id))}
                />
            </div>
        </>
    };

    preview = (state: typeof defaultCompany): ReactElement => {
        const address = state.address_id > -1 ? addressPreview(state, this.props.store, "", this.props.entity)
            : "keine Adresse verknüpft";

        return this.props.entity ? <>
            <CardHeader>{state.name}</CardHeader>
            <CardMeta>{address}</CardMeta>
            <CardDescription>
                {state.notes}<br/>
                {state.is_supplier && <Label content={"Zulieferer"}/>}
                {state.is_customer && <Label content={"Kunde"}/>}
            </CardDescription>
        </> : <><strong>{state.name}</strong><br/>{address}</>
    };

    doCancelEditing = () => {
        if (this.props.create) {
            this.changeHandler(this.props.name, -1)
        }
    };

    getSearch = (query: string): Promise<typeof defaultCompany[]> =>
        Helpers.getSearch("/reports/search_company", query);


    fetchSearchResults = (models: typeof defaultCompany[]): DisplayResult<number>[] => {
        return models.map(
            state => {
                return {
                    title: state.name,
                    description: (state.address_id === defaultCompany.address_id) ? "keine Adresse verknüpft"
                        : "in " + state.postcode + " " + state.city + ", " + state.country,
                    value: state.id
                }
            }
        )
    }
}

export default DataStore.withStore(OptionalCompanyForm)