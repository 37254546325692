import {APIResponse} from "../Helpers";
import React, {Component, ReactElement} from "react";
import {Message} from "semantic-ui-react";


export interface GetListProps<Model, Index> {
    get: () => Promise<APIResponse<Model[]>>
    render: (models: Model[], list: GetList<Model, Index>) => ReactElement

    updateCallback?: () => void
}

const defaultGetListState = {
    "values": [],
    "definiteError": "",
    "initialized": false
};

class GetList<Model, Index> extends Component<GetListProps<Model, Index>> {
    state = defaultGetListState;

    componentDidMount = (): void => {
        this.props
            .get()
            .then(resp => {
                let s;

                if (!resp.ok || resp.data === null) {
                    s = {"definiteError": resp.explain};
                } else {
                    s = {"values": resp.data, "initialized": true}
                }

                let cb = this.props.updateCallback;

                if (!this.state.initialized) {
                    cb = undefined;
                }

                this.setState(s, cb)
            });
    };

    render(): ReactElement {
        return <>
            {
                this.state.definiteError === ""
                    ? this.props.render(this.state.values, this)
                    : <Message negative>
                        <Message.Header>Konnte Liste nicht laden</Message.Header>
                        <p>{this.state.definiteError}</p>
                    </Message>
            }
        </>
    }
}

export default GetList