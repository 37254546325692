import {SelectorProps} from "./FormProps";
import React, {Component, ReactElement} from "react";
import DataStore, {get, StoreProps} from "../../models/DataStore";
import {Dropdown} from "semantic-ui-react";
import Helpers from "../Helpers";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";

interface ProductSelectorProps extends SelectorProps {
    value: number
}

class ProductSelector extends Component<StoreProps & ProductSelectorProps & { onlyExternal?: boolean }> {
    products: DropdownItemProps[] = [];

    render(): ReactElement {
        if (!this.props.store.get("ready")) {
            return <></>
        }

        if (this.products.length === 0) {
            const categories = this.props.store.get("categories");
            this.products = Array
                .from(this.props.store.get("products"), ([key, value]) => value)
                .filter(p => !this.props.onlyExternal || (p.category_id !== 1 && p.category_id !== 2))
                .map(
                    (value) => {
                        return {
                            "key": value.id,
                            "value": value.id,
                            "text": value.short_name + " (" + get(categories.get(value.category_id), "name") + ")",
                        }
                    }
                );
        }


        return <Dropdown
            placeholder='Produkt wählen'
            fluid
            search
            selection
            options={this.products}
            name={this.props.name}
            value={Helpers.toNumber(this.props.value)}
            onChange={this.props.onChange}
            className={"category-selector"}
            noResultsMessage={"keine Ergebnisse gefunden"}
        />
    }
}

export default DataStore.withStore(ProductSelector);