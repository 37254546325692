import React, {Component, ReactElement} from 'react';
import {Button, Divider, Icon, Image, Menu, Segment, Sidebar} from "semantic-ui-react";

import './MainMenu.css';
import Store, {StoreProps} from "../models/DataStore";
import MenuLink from "./MenuLink";
import Groups, {hasAnyGroup} from "./Groups";

class MainMenu extends Component<StoreProps> {
    render(): ReactElement {
        const user = this.props.store.get("user");

        return (
            <Sidebar as={Segment} id={"main-menu"} animation='push' visible={true} basic>
                <Segment basic>
                    <Image fluid wrapped src="/auth/assets/img/logo.png"/>
                </Segment>
                <Menu secondary fluid vertical id='action-menu'>
                    <MenuLink to="/dashboard">
                        <Icon name='rss'/>
                        Dashboard
                    </MenuLink>
                    <MenuLink to="/time_management">
                        <Icon name='history'/>
                        Zeitverwaltung
                    </MenuLink>
                    <MenuLink to="/projects">
                        <Icon name='film'/>
                        Projekte
                    </MenuLink>
                    {
                        hasAnyGroup(this.props, Groups.MANAGEMENT, Groups.DEVELOPER) &&
                        <MenuLink to="/reports">
                            <Icon name='folder open'/>
                            Berichte
                        </MenuLink>
                    }
                    <MenuLink to="/contacts">
                        <Icon name='user'/>
                        Kontakte
                    </MenuLink>
                </Menu>
                <Divider/>
                <Menu text vertical className='sub-menu'>
                    <Menu.Item header>Verwaltung</Menu.Item>
                    {
                        hasAnyGroup(this.props, Groups.DEVELOPER, Groups.ACQUISITION, Groups.MANAGEMENT, Groups.PROJECTLEAD) &&
                        <>
                            <MenuLink to="/entities">
                                Entitäten pflegen
                            </MenuLink>
                            <MenuLink to="/basedata">
                                Stammdaten pflegen
                            </MenuLink>
                        </>
                    }
                    <MenuLink to="/myaccount">
                        Mein Account
                    </MenuLink>
                </Menu>
                <div className='bottom-attached'>
                    <Divider horizontal>Eingeloggt als</Divider>
                    <div className='user-display vcenter-text'>
                        {user.person_id === -1 ? user.username : user.full_name}
                        <form action={"/auth/logout"} method="POST">
                            <Button icon='log out'/>
                        </form>
                    </div>
                </div>
            </Sidebar>
        );
    }
}

export default Store.withStore(MainMenu);

export interface MenuActionProps {
    menu: (action: string | null) => void
    cancelMenu: () => void
}
