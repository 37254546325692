import React, {Component, SyntheticEvent} from "react";
import {Card} from "semantic-ui-react";
import DataStore, {StoreProps} from "../../../models/DataStore";
import {FormData} from "../../../base/forms/FormProps";
import EmployeeData from "../forms/EmployeeData";
import PasswordForm from "../forms/PasswordForm";
import {api} from "../../../models/InitialData";
import NewOptionalPersonForm from "../forms/OptionalPersonForm";
import {User} from "../../../models/UserReport";


class MyBaseData extends Component<StoreProps> {
    render() {
        const user = this.props.store.get("user");

        return <>
            <NewOptionalPersonForm value={user.person_id}
                                   onChange={(event, data) => this.onChangedPerson(event, data, user)}
                                   name={"person_id"}/>
            <Card fluid>
                <Card.Content>
                    <Card.Header>Mitarbeiter-Account</Card.Header>
                </Card.Content>
                <Card.Content>
                    <EmployeeData/>
                </Card.Content>
            </Card>
            <Card fluid>
                <Card.Content>
                    <Card.Header>Passwort ändern</Card.Header>
                </Card.Content>
                <Card.Content>
                    <PasswordForm/>
                </Card.Content>
            </Card>
        </>
    }


    onChangedPerson = (event: SyntheticEvent | null, data: FormData, inUser: User) => {
        api("/forms/myemployeedata", "POST", {
            "person_id": data.value
        }).then(resp => {
            let user = Object.assign({}, inUser, resp.data);

            api("/person/" + user.person_id, "GET", {})
                .then(uresp => {
                    uresp.ok && Object.assign(user, uresp.data);
                    user.full_name = user.first_name + " " + user.last_name;
                    this.props.store.set("user")(user);
                })
        });
    };
}

export default DataStore.withStore(MyBaseData);