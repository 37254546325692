import React, {Component, ReactElement} from "react";
import {Button, Form, Icon, Table, TableCell, TableRow} from "semantic-ui-react";
import {STANDARD_ID} from "../../../../base/Helpers";
import {api} from "../../../../models/InitialData";
import DataStore, {get, StoreProps} from "../../../../models/DataStore";
import {Product} from "../../../../models/Product";
import EntityForm from "../../../../base/forms/EntityForm";
import CategorySelector from "../../../../base/forms/CategorySelector";


const defaultState = {
    "id": -1,
    "short_name": "",
    "extended_name": "",
    "category_id": -1
};

class ProductView extends Component<{ product: Product, cancelCreate?: () => void, create?: boolean } & StoreProps> {
    metaState = {
        "entityForm_editing": this.props.create,
    };

    render(): ReactElement {
        return <EntityForm
            defaultState={defaultState}
            metaState={this.metaState}
            value={this.props.product.id}

            name={"product_id"}
            preset={this.props.product}
            set={(value, state) => api("/product/", value === defaultState.id ? "PUT" : "PATCH", state)}

            changeHandler={() => {
                if (this.props.cancelCreate) {
                    this.props.cancelCreate();
                }
            }}
            {...STANDARD_ID}

            render={this.editing}
        />
    }

    editing = (form: EntityForm<typeof defaultState, number, {}>): ReactElement => {
        const categories = this.props.store.get("categories");

        if (form.state.entityForm_editing) {
            return <>
                <TableRow>
                    <TableCell><Form.Input fluid name='short_name' value={form.state.short_name}
                                           onChange={form.handleChange} placeholder='Produktname'/></TableCell>
                    <TableCell><Form.Input fluid name='extended_name' value={form.state.extended_name}
                                           onChange={form.handleChange} placeholder='Beschreibung'/></TableCell>
                    <TableCell className={"vcenter-text"}>
                        <CategorySelector name='category_id' value={form.state.category_id}
                                          onChange={form.handleChange}/>
                        <Button.Group style={{"margin": "0 0 0 5px"}}>
                            <Button icon onClick={() => this.doCancelEditing(form)}>
                                <Icon name='x'/>
                            </Button>
                            <Button icon primary onClick={form.doFinishEditing}>
                                <Icon name='check'/>
                            </Button>
                        </Button.Group>
                    </TableCell>
                </TableRow>
                {form.state.entityForm_temporaryError !== "" &&
                <Table.Row>
                    <Table.Cell colSpan='3'>
                        {form.getTemporaryError()}
                    </Table.Cell>
                </Table.Row>
                }
            </>
        }

        return <TableRow onClick={form.doStartEditing} className={"selectable"}>
            <TableCell>{form.state.short_name}</TableCell>
            <TableCell>{form.state.extended_name}</TableCell>
            <TableCell>{get(categories.get(form.state.category_id), "name")}</TableCell>
        </TableRow>
    };

    doCancelEditing = (form: EntityForm<typeof defaultState, number, {}>) => {
        form.doCancelEditing();
        if (this.props.cancelCreate) {
            this.props.cancelCreate();
        }
    }
}

export default DataStore.withStore(ProductView)