import React, {Component, ReactElement} from "react";
import EntityForm, {ChangeHandler} from "../../../../base/forms/EntityForm";
import {FullEmployee} from "../../../../models/FullEmployee";
import {api} from "../../../../models/InitialData";
import {STANDARD_ID} from "../../../../base/Helpers";
import {Button, Card, Checkbox, Form, FormField, TextArea} from "semantic-ui-react";
import NewOptionalPersonForm from "../../forms/OptionalPersonForm";
import AbstractForm from "../../../../base/forms/AbstractForm";
import CurrencyForm from "../../../../base/forms/CurrencyForm";
import ConfirmPortal from "../../../../base/forms/ConfirmPortal";
import NumberSelector from "../../../../base/forms/NumberSelector";
import EmployeeCost from "./EmployeeCost";
import DataStore, {getOrEmpty, StoreProps} from "../../../../models/DataStore";
import GroupSelector from "../../../../base/forms/GroupSelector";

const defaultState = {
    id: -1,
    person_id: -1,
    current_hourly_earnings: 0,
    weekly_hours: 0,
    yearly_vacation: 0,
    group_id: 2,
    username: "",
    notes: "",
    new_password: "",
    is_deleted: false,
    is_hidden: false,
};

const newEmployeeViewFormState = {
    employeeView_confirmDeleteEmployee: false
};

interface NewEmployeeViewProps extends ChangeHandler<number> {
    em: FullEmployee
}

class EmployeeView extends Component<NewEmployeeViewProps & StoreProps> {
    render(): ReactElement {
        return <EntityForm
            defaultState={defaultState}
            value={this.props.em.id}
            name={"employee_id"}
            changeHandler={this.props.changeHandler}

            preset={this.props.em}
            set={(value, state) => api("/employee/", "PATCH", state)}

            {...STANDARD_ID}

            metaState={newEmployeeViewFormState}
            render={this.externalRender}
        />
    }

    confirmDeleteEmployee = (form: EntityForm<typeof defaultState, number, typeof newEmployeeViewFormState>, value: boolean) => {
        form.setState({"employeeView_confirmDeleteEmployee": value})
    };

    externalRender = (form: EntityForm<typeof defaultState, number, typeof newEmployeeViewFormState>): ReactElement => {
        if (!this.props.store.get("ready")) {
            return <></>
        }

        if (form.state.entityForm_editing) {
            return <Card
                style={{width: "100%"}}>
                <Card.Content>
                    <NewOptionalPersonForm value={form.state.person_id} onChange={(a, model) =>
                        form.doUpdateEntity({"person_id": model.value})
                            .then(() => form.doFetchEntity(form.state.id))} name={"person_id"}/>
                </Card.Content>
                <Card.Content>
                    <AbstractForm cancel={() => form.doResetForm()}
                                  submit={() => form.doUpdateEntity({})}
                                  preview={<>
                                      hat den Nutzernamen <strong>{form.state.username}</strong>, ein
                                      veränderbares <strong>Passwort</strong>, gehört der Gruppe&nbsp;
                                      <strong>{getOrEmpty(this.props.store.get("groups").get(form.state.group_id), "name")}</strong>
                                      &nbsp;an<br/>und wird in Berichten <strong>{form.state.is_hidden ? "versteckt" : "angezeigt"}</strong>
                                  </>}>
                        <Form.Group widths={2}>
                            <Form.Input fluid label='Nutzername' name='username'
                                        value={form.state.username}
                                        onChange={form.handleChange} type='text' placeholder='nutzer_name123'/>
                            <Form.Input fluid label='neues Passwort' name='new_password'
                                        onChange={form.handleChange}
                                        value={form.state.new_password} type='password'
                                        placeholder='keine Eingabe, keine Änderung'/>
                        </Form.Group>
                        <Form.Group widths={2}>
                            <GroupSelector name={"group_id"}
                                           value={form.state.group_id} onChange={form.handleChange}
                                           label={"Nutzer-Gruppe"}/>
                            <FormField>
                                <label>Profil in Berichten anzeigen</label>
                                <Checkbox label={form.state.is_hidden ? "wird versteckt" : "wird angezeigt"}
                                          className={"vcenter-text"} name={'is_hidden'} toggle
                                          onChange={(a, data) => form.handleChange(a, {
                                              name: data.name,
                                              value: !data.checked
                                          })}
                                          checked={!form.state.is_hidden}/>
                            </FormField>
                        </Form.Group>
                    </AbstractForm>
                </Card.Content>
                <Card.Content>
                    <AbstractForm cancel={() => form.doResetForm()}
                                  submit={() => form.doUpdateEntity({})}
                                  preview={<>
                                      verdient <strong>{CurrencyForm.currencyString(form.state.current_hourly_earnings)}</strong> pro
                                      Stunde{form.state.weekly_hours > 0 && <>,
                                      arbeitet <strong>{form.state.weekly_hours} Stunden</strong> pro Woche</>}
                                      &nbsp;und hat&nbsp;
                                      <strong>{form.state.yearly_vacation} Urlaubstage</strong> pro Jahr
                                  </>}>
                        <Form.Group widths={2}>
                            <NumberSelector label='wöchentliche Stundenzahl' name='weekly_hours'
                                            onChange={form.handleChange} value={form.state.weekly_hours}
                                            placeholder='XX'/>
                            <NumberSelector label='jährliche Urlaubstage' name='yearly_vacation'
                                            onChange={form.handleChange}
                                            value={form.state.yearly_vacation} placeholder='XX'/>
                        </Form.Group>
                        <FormField>
                            <label>Stundenlohn</label>
                            <EmployeeCost value={form.state.id} name={"employee_cost"}
                                          onChange={() => form.doFetchEntity(form.state.id, true)}/>
                        </FormField>
                    </AbstractForm>
                </Card.Content>
                <Card.Content>
                    <AbstractForm cancel={() => form.doResetForm()}
                                  submit={() => form.doUpdateEntity({})}
                                  preview={form.state.notes === "" ? <>noch keine Notizen</> : <>
                                      <strong>Notizen</strong>: {form.state.notes}</>}>
                        <Form.Group>
                            <TextArea label='Notizen' name='notes'
                                      onChange={form.handleChange}
                                      value={form.state.notes} type='text' placeholder='Raum für Notizen'/>
                        </Form.Group>
                    </AbstractForm>
                </Card.Content>
                <Card.Content>
                    {form.getTemporaryError()}
                    <Button floated="right" onClick={() => form.doCancelEditing()}>Fertig</Button>
                    <Button floated="left" negative
                            onClick={() => this.confirmDeleteEmployee(form, true)}>Löschen</Button>

                    {
                        form.state.employeeView_confirmDeleteEmployee &&
                        <ConfirmPortal open ok={() => form.doDeleteEntity()}
                                       cancel={() => this.confirmDeleteEmployee(form, false)}
                                       text={"Mitarbeiter " + form.state.username + " wirklich löschen?"}
                        />
                    }
                </Card.Content>
            </Card>
        }

        const displayName = form.state.person_id === -1 ? form.state.username
            : this.props.em.first_name + " " + this.props.em.last_name;

        return <Card
            header={displayName}
            meta={'verdient ' + CurrencyForm.currencyString(this.props.em.current_hourly_earnings) + ' pro Stunde'}
            onClick={() => form.doStartEditing()}
            description={form.state.notes}
        >
        </Card>
    }
}

export default DataStore.withStore(EmployeeView)