import React, {Component, ReactElement} from "react";
import {MenuActionProps} from "../../base/MainMenu";
import SearchList from "../../base/forms/SearchList";
import {api} from "../../models/InitialData";
import {Card} from "semantic-ui-react";
import OptionalPersonForm, {defaultPerson} from "../general/forms/OptionalPersonForm";
import Helpers from "../../base/Helpers";


class PeopleContacts extends Component<{ create: boolean } & MenuActionProps> {

    componentDidMount(): void {
        this.props.menu("Person erstellen");
    }

    render(): ReactElement {
        return <SearchList
            match={(m: typeof defaultPerson) => m.first_name + " " + m.last_name + " " + Helpers.dateString(m.date_of_birth) + " " +
                +m.street + " " + m.street_number + " " + m.postcode + " " + m.city + " " + m.country + " " + m.email
                + " " + m.telephone_fixed.replace(/[^+0-9]/g, "") + " " + m.telephone_fax.replace(/[^+0-9]/g, "")
                + " " + m.telephone_mobile.replace(/[^+0-9]/g, "") + " " + (m.is_employee ? "Mitarbeiter" : "")
                + " " + (m.is_customer ? "Kunde" : "") + " " + (m.is_supplier ? "Zulieferer" : "")}

            get={() => api("/reports/person_contacts", "GET")}

            searchLabel={"nach Namen suchen"}
            placeholder={"Max Mustermann"}

            pageSize={14}

            render={(m, list) => <>
                {this.props.create && <OptionalPersonForm name={"person_id"} value={-1} create entity onChange={() => {
                    this.props.cancelMenu();
                    list.componentDidMount();
                }}/>}
                <Card.Group itemsPerRow={2}>
                    {
                        m.map(
                            (e: typeof defaultPerson) => {
                                return <OptionalPersonForm value={e.id} key={e.id} preset={e} name={"person_id"}
                                                           onChange={() => list.componentDidMount()} entity/>
                            }
                        )
                    }
                    {m.length === 0 && <Card fluid><Card.Content>Keine Ergebnisse gefunden</Card.Content></Card>}
                </Card.Group>
            </>
            }
        />
    }
}

export default PeopleContacts