import {Component} from "react";
import {FormData} from "./forms/FormProps";
import {api} from "../models/InitialData";
import _ from "lodash";
import {MenuAction} from "./ContentHeader";


export default class Helpers {
    static TO_MYSQL_DATE = "T00:00:00Z";
    static FIRST_DAY = "0001-01-01";
    static NULL_DATE = Helpers.FIRST_DAY + Helpers.TO_MYSQL_DATE;
    static OK = "OK!!!!";

    static dateString(input: string | undefined | null): string {
        if (input === undefined || input === null) {
            return ""
        }

        let s: string = input as string;
        return s.slice(8, 10) + "." + s.slice(5, 7) + "." + s.slice(0, 4);
    }

    static isoDate(input: string | undefined | null): string {
        if (input === undefined || input === null) {
            return ""
        }

        let s: string = input as string;
        return s.slice(0, 10);
    }

    static toNumber(s: {} | undefined): number {
        let n: number = s as number;
        return n;
    }

    static handleChange(th: Component, data: FormData) {
        if (data.name === undefined) {
            return
        }

        const key = data.name, obj = {
            [key]: data.value
        };

        th.setState(obj);
    }

    static mergeInto(base: { [key: string]: any }, obj: any) {
        Object.entries(obj).forEach(
            value => {
                const key: string = value[0];

                if (key in base) {
                    base[key] = value[1]
                }
            }
        )
    }

    static stripPassword<B extends { "new_password": string | null }>(base: B): B {
        if (base["new_password"] === null || base["new_password"].length === 0) {
            delete base["new_password"];
        }

        return base
    }

    static explainedError(error: string, explain: string): string {
        return error + ((explain === "") ? "" : " (" + explain + ")");
    }

    static getSearch<Model>(url: string, query: string, id?: number): Promise<Model[]> {
        const queryString = id ? "?id=" + encodeURIComponent(id)
            : "?query=" + encodeURIComponent(query);

        return new Promise(
            resolve => {
                api<Model[]>(url + queryString, "GET")
                    .then(resp => {
                        if (resp.ok && resp.data !== null) {
                            resolve(resp.data);
                        } else {
                            resolve([]);
                        }
                    })
            }
        )
    }

    static getStatic<S>(state: S): Promise<APIResponse<S>> {
        return new Promise<APIResponse<S>>(
            resolve => {
                resolve({
                    "ok": true,
                    "status": 200,
                    "explain": "",
                    "data": state
                })
            }
        )
    };

    static pad(input: string | number, size: number) {
        let s: string = input + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    static timeString(date: Date, noPadding?: boolean) {
        const pad = noPadding ? 2 : 0;

        return Helpers.pad(date.getUTCHours(), pad) + ":" + Helpers.pad(date.getUTCMinutes(), 2)
    }

    static parseDate(date: string | null): Date | null {
        return date === null ? null : new Date(date)
    }

    static germanDate(date: Date | null) {
        if (date === null) {
            return "";
        }

        return Helpers.pad(date.getUTCDate(), 2) + "." + Helpers.pad(date.getUTCMonth() + 1, 2) + "."
            + Helpers.pad(date.getUTCFullYear(), 4);
    }

    static germanDayAndMonth(date: Date) {
        return Helpers.pad(date.getUTCDate(), 2) + "." + Helpers.pad(date.getUTCMonth() + 1, 2) + ".";
    }

    static immCopy<A extends { [key: string]: any }>(input: A): A {
        return _.cloneDeep(input);
    }

    static setMenuAction<A extends Component>(el: A) {
        return (action: string | null) => {
            el.setState({menuAction: Helpers.menuAction(action, el), createObj: false});
        }
    };

    static menuAction<A extends Component>(name: string | null, el: A): MenuAction | null {
        return name === null ? null : new MenuAction(() => el.setState({createObj: true}), name);
    }

    static cancelAction<A extends Component>(el: A) {
        return () => {
            el.setState({createObj: false});
        }
    }
}

export const STANDARD_ID = {
    getIdFromState: (state: { id: number }) => state.id,
    setIdOnState: (value: number) => Object.assign({}, {"id": value}),
};

export const NULL_ID = {
    getIdFromState: (state: any) => null,
    setIdOnState: (value: number) => {
    },
};

export const FIXCOST_ID = {
    getIdFromState: (state: { valid_from: string }) => state.valid_from,
    setIdOnState: (value: string) => Object.assign({}, {"valid_from": value}),
};

export function ID<Index, State>(id: keyof State) {
    return {
        getIdFromState: (state: State) => state[id],
        setIdOnState: (value: Index) => Object.assign({}, {[id]: value}),
    };
}

export interface APIResponse<S> {
    status: number
    ok: boolean
    explain: string
    data: S | null
}