import {Component} from "react";
import Store, {getOrEmpty, StoreProps} from "./DataStore";
import {API_BASE} from "../Config";
import {Country, Gender, Group, Status} from "./UserReport";
import {APIResponse} from "../base/Helpers";
import {Category} from "./Category";
import {Product} from "./Product";

class InitialData extends Component<StoreProps> {
    componentDidMount(): void {
        const store = this.props.store;

        api("/reports/uiload", "GET")
            .then((resp: APIResponse<any>) => {
                if (!resp.ok) {
                    return
                }

                const data = resp.data;
                let genders = new Map<number, Gender>();
                let groups = new Map<number, Group>();
                let categories = new Map<number, Category>();
                let countries = new Map<string, Country>();
                let products = new Map<number, Product>();
                let status = new Map<number, Status>();

                for (let i = 0; i < data.genders.length; i++) {
                    let gender: Gender = data.genders[i];
                    genders.set(gender.id, gender);
                }

                for (let i = 0; i < data.countries.length; i++) {
                    let country: Country = data.countries[i];
                    countries.set(country.code, country);
                }

                for (let i = 0; i < data.groups.length; i++) {
                    let group: Group = data.groups[i];
                    groups.set(group.id, group);
                }

                for (let i = 0; i < data.categories.length; i++) {
                    let category: Category = data.categories[i];
                    categories.set(category.id, category);
                }

                for (let product of data.products) {
                    products.set(product.id, product);
                }

                for (const st of data.status) {
                    status.set(st.id, st);
                }

                store.set("genders")(genders);
                store.set("countries")(countries);
                store.set("groups")(groups);
                store.set("user")(data.user);
                store.set("categories")(categories);
                store.set("products")(products);
                store.set("status")(status);

                store.set("ready")(true);
            });
    }

    render(): null {
        return null;
    }
}

export function api<S>(url: string, method: ("POST" | "GET" | "PUT" | "PATCH" | "DELETE"), body?: {}): Promise<APIResponse<S>> {
    let init: RequestInit = {
        "credentials": "include",
        "method": method
    };

    if (method !== "GET" && body !== undefined) {
        Object.assign(init, {"body": JSON.stringify(body)})
    }

    return new Promise<APIResponse<S>>(
        resolve => {
            fetch(API_BASE + url, init).then(resp => {
                if (resp.status === 401) {
                    document.location.href = "/auth/";
                }

                let result: APIResponse<S> = {
                    "ok": resp.ok,
                    "status": resp.status,
                    "explain": "",
                    "data": null
                };

                if (Math.floor(resp.status / 100) !== 5) {


                    resp.json().then(
                        json => {
                            Object.assign(result, {
                                "data": json,
                                "explain": getOrEmpty(json, 'text')
                            });
                            resolve(result);
                        }
                    );
                } else {
                    resolve(result);
                }


            });
        });
}


export default Store.withStore(InitialData)