import React, {Component, ReactElement} from "react";
import ContentHeader from "../../base/ContentHeader";
import RouterContent from "../../base/RouterContent";
import MenuLink from "../../base/MenuLink";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import TimeTable from "./TimeTable";
import TimeRecorder from "./TimeRecorder";
import Helpers from "../../base/Helpers";

class TimePage extends Component<RouteComponentProps> {
    state = {
        menuAction: null,
        createObj: false
    };

    render(): ReactElement {
        const path = this.props.match.path;

        return <>
            <ContentHeader title={"Zeitverwaltung"} isReady={true} action={this.state.menuAction}>
                <MenuLink to={path} exact={true}>Tabelle</MenuLink>
            </ContentHeader>
            <RouterContent>
                <Switch>
                    <Route path={path + "/recorder"}>
                        <TimeRecorder menu={Helpers.setMenuAction(this)} cancelMenu={Helpers.cancelAction(this)}/>
                    </Route>
                    <Route path={path} exact={true}>
                        <TimeTable menu={Helpers.setMenuAction(this)} cancelMenu={Helpers.cancelAction(this)}
                                   create={this.state.createObj}/>
                    </Route>
                </Switch>
            </RouterContent>
        </>
    }
}

export default TimePage
