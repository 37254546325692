import React, {ReactElement} from "react";
import {Icon, Label} from "semantic-ui-react";
import {Redirect} from "react-router-dom";

export default class JSXHelpers {
    static previewContactData(data: { "telephone_mobile": string, "telephone_fax": string, "telephone_fixed": string, "email": string }): ReactElement {
        if (data.telephone_mobile === "" && data.telephone_fax === ""
            && data.telephone_fixed === "" && data.email === "") {
            return <>keine Kontaktdaten gespeichert</>
        }

        return <>Erreichbar unter &nbsp;
            {data.telephone_mobile !== "" &&
            <Label>
                <Icon name='mobile alternate'/> {data.telephone_mobile}
            </Label>
            }
            {data.telephone_fixed !== "" &&
            <Label>
                <Icon name='phone'/> {data.telephone_fixed}
            </Label>
            }
            {data.telephone_fax !== "" &&
            <Label>
                <Icon name='fax'/> {data.telephone_fax}
            </Label>
            }
            {data.email !== "" &&
            <Label>
                <Icon name='mail'/> {data.email}
            </Label>
            }
        </>
    }
}

export function goHome(): ReactElement {
    return <Redirect to="/dashboard"/>
}