import React, {Component, ReactElement} from "react";
import {MenuActionProps} from "../../../base/MainMenu";
import SearchList from "../../../base/forms/SearchList";
import {api} from "../../../models/InitialData";
import {Table} from "semantic-ui-react";
import FixcostView from "./views/FixcostView";
import GetList from "../../../base/forms/GetList";
import Helpers from "../../../base/Helpers";
import CurrencyForm from "../../../base/forms/CurrencyForm";
import DataStore, {StoreProps} from "../../../models/DataStore";
import Groups, {hasAnyGroup} from "../../../base/Groups";
import {goHome} from "../../../base/JSXHelpers";

export const defaultFixcost = {
    "id": 0,
    "valid_from": null,
    "amount": 0
};

class FixCosts extends Component<{ create: boolean } & MenuActionProps & StoreProps> {

    componentDidMount(): void {
        this.props.menu("neuer Eintrag");
    }

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT)) {
            return goHome()
        }

        return <SearchList
            match={(p: typeof defaultFixcost) => Helpers.dateString(p.valid_from) + " " + CurrencyForm.currencyString(p.amount)}

            get={() => api("/reports/fixcosts", "GET")}

            searchLabel={"nach Datum suchen"}
            placeholder={"JJJJ-MM-TT"}

            pageSize={15}

            render={(m, list) => {

                return <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>gültig ab</Table.HeaderCell>
                            <Table.HeaderCell>Betrag</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.props.create &&
                        <FixcostView fix={defaultFixcost} create cancelCreate={() => this.cancelCreate(list)}/>}
                        {
                            m.map(
                                (f: typeof defaultFixcost) => {
                                    return <FixcostView fix={f} key={f.id}
                                                        cancelCreate={() => this.cancelCreate(list)}/>
                                }
                            )
                        }
                        {m.length === 0 && <Table.Row>
                            <Table.Cell colSpan='2'>
                                Keine Ergebnisse gefunden
                            </Table.Cell>
                        </Table.Row>}
                    </Table.Body>
                </Table>
            }

            }
        />
    }

    cancelCreate = (list: GetList<any, any>) => {
        this.props.cancelMenu();
        list.componentDidMount();
    }
}

export default DataStore.withStore(FixCosts)