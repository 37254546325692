import React, {Component, ReactElement} from "react";
import {assembleTable, defaultProjectCost, projectCostTableColsKey} from "../projects/SingleProject";
import {api} from "../../models/InitialData";
import {RouteComponentProps} from "react-router-dom";
import {Card, Header, Input, Table} from "semantic-ui-react";
import Time from "../../models/Time";
import ContentHeader from "../../base/ContentHeader";
import RouterContent from "../../base/RouterContent";
import DataStore, {StoreProps} from "../../models/DataStore";
import Groups, {hasAnyGroup} from "../../base/Groups";
import {goHome} from "../../base/JSXHelpers";

const productCols = ["product_names", "working_minutes", "over_minutes", "total_time"] as projectCostTableColsKey[];

class ProductOverviewReport extends Component<RouteComponentProps & StoreProps> {
    thisYear = new Date().getUTCFullYear() + "";

    state = {
        products: [] as typeof defaultProjectCost[],
        year: this.thisYear,
        editYear: this.thisYear,
        totalTime: 0,
    };

    componentDidMount(): void {
        api<typeof defaultProjectCost[]>("/reports/product_overview?year=" + this.state.year, "GET")
            .then(resp => !resp.ok || resp.data === null
                ? this.props.history.goBack()
                : this.setState({
                    products: resp.data,
                    totalTime: resp.data.length === 0 ? 0 : resp.data
                        .map(p => p.over_minutes + p.working_minutes)
                        .reduce((p, n) => p + n)
                }));
    }

    componentDidUpdate(prevProps: any, prevState: Readonly<{ year: string, project_work: typeof defaultProjectCost[] }>): void {
        if (prevState.year !== this.state.year) {
            this.componentDidMount();
        }
    }

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT)) {
            return goHome()
        }

        const products = this.state.products;

        return this.header(<Card fluid>
            <Card.Content>
                <h2 style={{display: "inline-block"}}>Leistung</h2>
                <span style={{float: "right"}}>
                        <Header size={"small"} style={{"margin": "0 15px 0 0", "display": "inline-block"}}>
                            Jahr
                        </Header>
                        <Input value={this.state.editYear}
                               action={{
                                   disabled: this.state.year === this.state.editYear,
                                   icon: "check", onClick: () => this.setState({year: this.state.editYear})
                               }}
                               onChange={(a, v) => this.setState({editYear: v.value})}
                               type='number' placeholder='XXXX' size={"small"}/>
                    </span>
                <Table basic='very' celled textAlign={"center"} className={"sumup"} structured>
                    {products.length > 0 &&
                    assembleTable(productCols, products)}
                    {products.length === 0 &&
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell colSpan={productCols.length}>
                                Keine Produktleistungen in diesem Zeitraum.
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>}
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell colSpan={productCols.length - 1}
                                        textAlign={"right"}>
                                <Header size={"small"} content={"Gesamtsumme"}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Header size={"medium"}
                                        content={Time.parse(this.state.totalTime).toString()}/>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Card.Content>
        </Card>);
    }


    header(content: ReactElement) {
        return <>
            <ContentHeader
                title={"Produkte"}
                isReady action={null} goBackButton>
                <div className={"item active"}>
                    Übersicht
                </div>
            </ContentHeader>
            <RouterContent children={content}/>
        </>
    }
}

export default DataStore.withStore(ProductOverviewReport)