import React, {Component, ReactElement} from "react";
import {RouteComponentProps} from "react-router-dom";
import ContentHeader from "../../../base/ContentHeader";
import MenuLink from "../../../base/MenuLink";
import RouterContent from "../../../base/RouterContent";
import {Route, Switch} from "react-router";
import ProductTypes from "./ProductTypes";
import Companies from "./Companies";
import FixCosts from "./FixCosts";
import Helpers from "../../../base/Helpers";
import DataStore, {StoreProps} from "../../../models/DataStore";
import Groups, {hasAnyGroup} from "../../../base/Groups";
import {goHome} from "../../../base/JSXHelpers";


class BaseDataPage extends Component<RouteComponentProps & StoreProps> {
    state = {
        menuAction: null,
        createObj: false
    };

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.ACQUISITION, Groups.MANAGEMENT, Groups.PROJECTLEAD)) {
            return goHome()
        }

        const path = this.props.match.path;

        return <>
            <ContentHeader title={"Stammdaten pflegen"} action={this.state.menuAction} isReady={true}>
                <MenuLink to={path} exact>Unternehmen</MenuLink>
                {
                    hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT) &&
                    <>
                        <MenuLink to={path + "/products"}>Produkte</MenuLink>
                        <MenuLink to={path + "/fixcosts"}>Fixkosten</MenuLink>
                    </>
                }
            </ContentHeader>
            <RouterContent>
                <Switch>
                    <Route path={path + "/products"}>
                        <ProductTypes create={this.state.createObj} menu={Helpers.setMenuAction(this)}
                                      cancelMenu={Helpers.cancelAction(this)}/>
                    </Route>
                    <Route path={path + "/fixcosts"}>
                        <FixCosts create={this.state.createObj} menu={Helpers.setMenuAction(this)}
                                  cancelMenu={Helpers.cancelAction(this)}/>
                    </Route>
                    <Route path={path} exact>
                        <Companies create={this.state.createObj} menu={Helpers.setMenuAction(this)}
                                   cancelMenu={Helpers.cancelAction(this)}/>
                    </Route>
                </Switch>
            </RouterContent>
        </>
    }

}

export default DataStore.withStore(BaseDataPage);