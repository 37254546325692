import React, {Component, ReactElement} from "react";
import {Button, Header, Input, InputOnChangeData, InputProps, Statistic, Table} from "semantic-ui-react";
import {MenuActionProps} from "../../base/MainMenu";
import TimeEntry, {defaultTimeEntry} from "./views/TimeEntry";
import Helpers from "../../base/Helpers";
import GetList from "../../base/forms/GetList";
import {api} from "../../models/InitialData";
import Time from "../../models/Time";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import {defaultEmployeeReport} from "../reports/EmployeeOverviewReport";

const defaultKillOvertimeEntry = Object.assign(Helpers.immCopy(defaultTimeEntry), {
    id: -2,
    project_id: -1,
    product_id: 3,
});
const weekDays = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
const weekInputAttr: InputProps = {
    transparent: true,
};

class TimeTable extends Component<MenuActionProps & { create: boolean, viewOnly?: boolean, userId?: string, onUpdate?: () => void } & RouteComponentProps> {
    currentDate = new Date();
    currentMondayNumber = new Date().setUTCDate(this.currentDate.getUTCDate() - this.currentDate.getUTCDay() + 1);
    currentMonday = new Date(this.currentMondayNumber);

    list: GetList<any, any> | null = null;

    state = {
        mondayDate: new Date(this.currentMondayNumber),
        weekDates: TimeTable.weekDates(this.currentDate),
        editWeek: false,
        isValid: true,
        killOvertime: false,
        work_report: defaultEmployeeReport,
        editWeekValue: TimeTable.getWeek(this.currentMonday),
        editYearValue: this.currentMonday.getFullYear()
    };

    static toMonday(date: Date): Date {
        date = new Date(date);
        date.setHours(10)
        date.setDate(date.getDate() - date.getDay() + 1);

        return date;
    }

    static weekDates(current: Date): Date[] {
        current = new Date(current);

        let week: Date[] = [];
        // Starting Monday not Sunday
        current = this.toMonday(current);

        for (let i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setUTCDate(current.getUTCDate() + 1);
        }

        return week;
    }

    static getWeek(date: Date) {
        date = new Date(date);

        const firstOfYear = new Date(date.getFullYear(), 0, 1);
        const firstThursday = this.toMonday(firstOfYear);
        firstThursday.setDate(firstThursday.getDate() + 3);

        const originalThursday = this.toMonday(date);
        originalThursday.setDate(originalThursday.getDate() + 3);
        originalThursday.setHours(11);

        const timeDiff = originalThursday.valueOf() - firstThursday.valueOf();


        const weekNo = Math.round((timeDiff / 86400000) / 7);

        return weekNo;
    }

    componentDidMount(): void {
        this.props.menu("neuer Eintrag");
        if (this.props.location.search === "?killOvertime=true") {
            this.props.history.replace(this.props.match.path);
            this.setState({killOvertime: true})
        }

        this.refreshStats();
    }

    refreshStats() {
        !this.props.userId && api<typeof defaultEmployeeReport>("/reports/my_work_report", "GET")
            .then(resp => !resp.ok || resp.data === null
                ? this.props.history.goBack()
                : this.setState({work_report: resp.data}));
    }

    render(): ReactElement {

        const weeks = "?weeks=" + Helpers.pad(this.state.mondayDate.getFullYear(), 4) + "-" + Helpers.pad(TimeTable.getWeek(this.state.mondayDate), 2);

        return <GetList
            get={() => this.props.userId ? api("/reports/employee_time_table/" + this.props.userId + weeks, "GET")
                : api("/reports/personal_time_table" + weeks, "GET")}
            updateCallback={() => {
                this.refreshStats();
                this.props.onUpdate && this.props.onUpdate();
            }}
            render={this.embed}
        />
    }

    embed = (models: typeof defaultTimeEntry[], list: GetList<typeof defaultTimeEntry, number>): ReactElement => {
        if (this.list === null) {
            this.list = list;
        }

        const employee = this.state.work_report;
        let dateMap = new Map<string, typeof defaultTimeEntry[]>();
        models.forEach((m: any) => {
            const iso = Helpers.isoDate(m.dates[0].date) + Helpers.TO_MYSQL_DATE;
            let arr = dateMap.get(iso);

            if (!arr) {
                arr = [];
                dateMap.set(iso, arr);
            }

            arr.push({
                category_name: m.category_name,
                company_name: m.company_name,
                dates: [{
                    date: iso,
                    working_time: Time.parse(m.dates[0].working_time),
                    over_time: Time.parse(m.dates[0].over_time)
                }],
                id: m.id,
                notes: m.notes,
                product_id: m.product_id,
                product_short_name: m.product_short_name,
                project_id: m.project_id,
                project_name: m.project_name,
                project_number: m.project_number,
            });
        });

        return <>
            {(this.props.create || this.state.killOvertime) &&
            <TimeEntry value={this.state.killOvertime ? defaultKillOvertimeEntry : defaultTimeEntry}
                       onChange={() => {
                           list.componentDidMount();
                           this.setState({killOvertime: false});
                           this.props.cancelMenu();
                       }}
                       cancelCreate={() => {
                           this.props.cancelMenu();
                           this.setState({killOvertime: false});
                       }}
                       userId={this.props.userId}
                       create/>}
            <div>
                <Header as={"h1"} floated={"left"}>
                    KW&nbsp;
                    {!this.state.editWeek ? <> {this.state.editWeekValue}, {this.state.editYearValue}</>
                        : <>&nbsp;
                            <Input onChange={this.changeNumber(2, 52)} onKeyDown={this.keyDown} name={"editWeekValue"}
                                   value={this.state.editWeekValue}
                                   style={{"width": "40px"}} {...weekInputAttr}/>,&nbsp;
                            <Input onChange={this.changeNumber(4, 9999)} onKeyDown={this.keyDown} name={"editYearValue"}
                                   value={this.state.editYearValue} style={{"width": "80px"}} {...weekInputAttr}/>
                        </>}
                </Header>
                <Button circular icon={this.state.editWeek ? "check" : "edit"}
                        onClick={() => this.toggleEdit()}/>
                <Button.Group floated={"right"}>
                    <Button labelPosition='left' icon='left chevron' content='Zurück'
                            onClick={() => this.editWeek(-1)}/>
                    <Button labelPosition='right' icon='right chevron' content='Weiter'
                            onClick={() => this.editWeek(1)}/>
                </Button.Group>
                <Button floated={"right"} style={{marginRight: "20px"}} content='Heute'
                        onClick={this.navigateToday}
                        disabled={this.state.mondayDate.getFullYear() === this.currentMonday.getFullYear() && TimeTable.getWeek(this.state.mondayDate) === TimeTable.getWeek(this.currentMonday)}/>
            </div>
            <Table basic='very' celled fixed>
                <Table.Body>
                    {weekDays.map((val, idx) => {
                        const date = this.state.weekDates[idx];
                        const iso = Helpers.isoDate(date.toISOString()) + Helpers.TO_MYSQL_DATE;


                        let entries = dateMap.get(iso);
                        if (!entries) {
                            entries = [];
                        }

                        return <Table.Row key={idx}>
                            <Table.Cell width={3}><strong>{val}</strong><br/>{Helpers.germanDayAndMonth(date)}
                            </Table.Cell>
                            <Table.Cell className={"ui labels"}>
                                {entries.map(e => <TimeEntry viewOnly={this.props.viewOnly}
                                                             key={e.id} onChange={list.componentDidMount} value={e}/>)}
                            </Table.Cell>
                        </Table.Row>;
                    })}
                </Table.Body>
            </Table>
            <br/>
            {!this.props.userId &&
            <Statistic.Group size={"small"} widths={4}>
                <Statistic label={"Urlaubstage"}
                           value={employee.used_vacation + " / " + employee.yearly_vacation}/>
                <Statistic label={"Überstunden"} color={employee.over_time_saldo < 0 ? "red" : undefined}
                           value={Time.parse(employee.over_time_saldo).toString()}/>
                <Statistic label={"Std. diesen Monat"}
                           value={Time.parse(employee.working_minutes_month).toString()}/>
                <Statistic label={"Krank"} value={employee.illness_days + " Tage"}/>
            </Statistic.Group>}
        </>
    };

    changeNumber = (len: number, max: number) => {
        return (ignored: any, val: InputOnChangeData) => {
            const rawValue = val.value
                .replace(/(^0+|[^0-9])/g, '')
                .substring(0, len);

            if (rawValue === "") {
                this.setState({[val.name]: rawValue, isValid: false});
            } else {

                let value = parseInt(rawValue);

                if (value > max) {
                    value = max;
                }

                this.setState({[val.name]: value, isValid: true})
            }
        };
    };

    keyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.toggleEdit()
        }
    };

    toggleEdit = () => {
        if (this.state.editWeek && !this.state.isValid) {
            return
        }

        if (this.state.editWeek) {
            this.updateTable(this.state.editWeekValue, this.state.editYearValue);
        }

        this.setState({editWeek: !this.state.editWeek});
    };

    editWeek = (delta: number) => {
        this.setState((prevState: { mondayDate: Date }) =>
                this.dateCallback(TimeTable.modifyByWeeks(prevState.mondayDate, delta)),
            () => this.list && this.list.componentDidMount()
        );
    };

    static modifyByWeeks(date: Date, weeks: number): Date {
        date = new Date(date);
        date.setUTCDate(date.getUTCDate() + weeks * 7);

        return date;
    }

    updateTable = (week: number, year: number) => {

        const date = TimeTable.toMonday(new Date(year, 0, week * 7 - 1));

        this.setState(this.dateCallback(date), () => this.list && this.list.componentDidMount());
    };

    dateCallback = (date: Date) => {
        return {
            mondayDate: date,
            weekDates: TimeTable.weekDates(date),
            editWeekValue: TimeTable.getWeek(date),
            editYearValue: date.getFullYear(),
        }
    }

    navigateToday = () => this.setState(this.dateCallback(new Date(this.currentMondayNumber)),
        () => this.list && this.list.componentDidMount());
}

export default withRouter(TimeTable)