import React, {Component, ReactElement, SyntheticEvent} from "react";
import {Form} from "semantic-ui-react";
import Helpers from "../Helpers";
import {DataFormProps, FormData} from "./FormProps";


class DateForm extends Component<DataFormProps<string | null> & { notFluid?: boolean, action?: any }> {
    addStyle = this.props.notFluid ? {"width": "unset"} : {};


    getValue = (): string | null => {
        return this.props.value === null ? "" : Helpers.isoDate(this.props.value)
    };

    render(): ReactElement {
        return <Form.Input fluid={!this.props.notFluid} style={this.addStyle} label={this.props.label}
                           name={this.props.name} onChange={this.handleChange}
                           value={this.state.isValid ? this.getValue() : this.state.editableValue} type='date'
                           action={this.props.action}
                           placeholder={"JJJJ-MM-DD"} error={!this.state.isValid}/>
    }

    handleChange = (event: SyntheticEvent | null, data: FormData) => {
        const ok = data.value === "" || data.value.match('^\\d{4}-\\d{2}-\\d{2}$');

        this.setState({
            isValid: ok,
            editableValue: data.value,
        });

        if (ok) {
            data.value = data.value === "" ? null : data.value + Helpers.TO_MYSQL_DATE;

            this.props.onChange(event, data)
        }
    };

    state = {
        isValid: true,
        editableValue: this.getValue(),
    };
}

export default DateForm;