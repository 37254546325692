import React, {Component, ReactElement} from "react";
import {MenuActionProps} from "../../base/MainMenu";
import {Button, Statistic} from "semantic-ui-react";

class TimeRecorder extends Component<MenuActionProps> {
    componentDidMount(): void {
        this.props.menu(null);
    }

    render(): ReactElement {
        return <>
            <Statistic.Group size={"large"}>
                <Statistic>
                    <Statistic.Value>4</Statistic.Value>
                    <Statistic.Label>Stunden</Statistic.Label>
                </Statistic>
                <Statistic>
                    <Statistic.Value>32</Statistic.Value>
                    <Statistic.Label>Minuten</Statistic.Label>
                </Statistic>
                <Statistic>
                    <Statistic.Value>22</Statistic.Value>
                    <Statistic.Label>Sekunden</Statistic.Label>
                </Statistic>
            </Statistic.Group>
            <br/>
            <Button>Zurücksetzen</Button>&nbsp;<Button>Start</Button>
        </>
    }
}

export default TimeRecorder