import React, {Component, ReactElement, SyntheticEvent} from "react";
import {FormData} from "../../../base/forms/FormProps";
import Helpers, {APIResponse} from "../../../base/Helpers";
import AbstractForm from "../../../base/forms/AbstractForm";
import {Form} from "semantic-ui-react";
import {api} from "../../../models/InitialData";

class PasswordForm extends Component {
    state = {
        "password": "",
        "new_password": ""
    };
    initialState = this.state;

    render(): ReactElement {
        return <AbstractForm errors submit={this.submit} cancel={this.cancel} preview={
            <>Dein Passwort wird in gehashter Form gespeichert.</>
        }>
            <Form.Group widths='equal'>
                <Form.Input
                    fluid
                    id='form-subcomponent-shorthand-input-first-name'
                    label='altes Passwort'
                    placeholder='geheim'
                    name='password'
                    type='password'
                    value={this.state.password}
                    onChange={this.handleChange}
                />
                <Form.Input
                    fluid
                    id='form-subcomponent-shorthand-input-last-name'
                    label='neues Passwort'
                    placeholder='supergeheim'
                    name='new_password'
                    type='password'
                    value={this.state.new_password}
                    onChange={this.handleChange}
                />
            </Form.Group>
        </AbstractForm>
    }


    private submit = (): Promise<APIResponse<any>> => {
        return new Promise<APIResponse<any>>(resolve => {
            api("/forms/mypassword", "POST", this.state)
                .then((resp) => {
                    this.setState(this.initialState);
                    resolve(resp);
                })
                .catch(resp => resolve(resp));
        })
    };

    private cancel = (): void => {
        this.setState(this.initialState);
    };

    handleChange = (event: SyntheticEvent | null, data: FormData) => {
        Helpers.handleChange(this, data);
    };
}

export default PasswordForm