import React, {Component, ReactElement} from "react";
import {defaultCompany} from "../general/basedata/Companies";
import OptionalPersonForm, {defaultPerson} from "../general/forms/OptionalPersonForm";
import Helpers from "../../base/Helpers";
import ContentHeader from "../../base/ContentHeader";
import RouterContent from "../../base/RouterContent";
import {api} from "../../models/InitialData";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Card} from "semantic-ui-react";
import DataStore, {StoreProps} from "../../models/DataStore";
import {addressPreview} from "../general/forms/OptionalAddressForm";
import OptionalCompanyForm from "../general/basedata/views/OptionalCompanyForm";

const defaultSingleCompanyContactState = Object.assign(defaultCompany, {
    contacts: [] as typeof defaultPerson[]
});

class SingleCompanyContacts extends Component<RouteComponentProps<{ id: string }> & StoreProps> {
    state = Helpers.immCopy(defaultSingleCompanyContactState);

    componentDidMount(): void {
        api<typeof defaultSingleCompanyContactState>("/reports/company_contacts/" + this.props.match.params.id, "GET")
            .then(resp => !resp.ok || resp.data === null
                ? this.props.history.goBack()
                : this.setState(resp.data));
    }

    render(): ReactElement {
        const company = this.state;
        const address = company.address_id > -1 ? addressPreview(company, this.props.store, "", true)
            : "keine Adresse verknüpft";

        return this.header(<>
            <OptionalCompanyForm preset={company} name={"company_id"} value={company.id}
                                 onChange={() => this.componentDidMount()} entity/>
            <br/>
            <h2>Kontaktpersonen</h2>
            <Card.Group itemsPerRow={2}>
                {company.contacts.map(this.contact)}
                {company.contacts.length === 0 &&
                <Card fluid><Card.Content>Keine Ergebnisse gefunden</Card.Content></Card>}
            </Card.Group>
        </>);
    }


    header(content: ReactElement) {
        const company = this.state;

        return <>
            <ContentHeader
                title={company.id === -1 ? "Unternehmen" : company.name}
                isReady action={null} goBackButton>
                <div className={"item active"}>Kontaktansicht</div>
            </ContentHeader>
            <RouterContent children={content}/>
        </>
    }

    contact = (e: typeof defaultPerson, key: number) => {
        return <OptionalPersonForm value={e.id} key={e.id} preset={e} name={"person_id"}
                                   onChange={() => this.componentDidMount()} entity/>
    }
}

export default withRouter(DataStore.withStore(SingleCompanyContacts));