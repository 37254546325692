import {createConnectedStore, Effects, Store} from 'undux'
import {Country, Gender, Group, Status, User} from "./UserReport";
import {Category} from "./Category";
import {Product} from "./Product";


type DataState = {
    user: User,
    countries: Map<string, Country>,
    genders: Map<number, Gender>,
    groups: Map<number, Group>,
    categories: Map<number, Category>,
    products: Map<number, Product>,
    status: Map<number, Status>,
    ready: boolean
}

let initialState: DataState = {
    user: {
        address_id: 0,
        created_at: "",
        date_of_birth: "",
        email: "",
        entry_date: "",
        first_name: "",
        full_name: "lade Daten...",
        gender_id: 0,
        group_id: 0,
        current_hourly_earnings: 0,
        is_hidden: true,
        last_name: "",
        new_password: "",
        person_id: 0,
        telephone_fax: "",
        telephone_fixed: "",
        telephone_mobile: "",
        updated_at: "",
        yearly_vacation: 0,
        username: ""
    },
    groups: new Map<number, Group>(),
    countries: new Map<string, Country>(),
    genders: new Map<number, Gender>(),
    categories: new Map<number, Category>(),
    products: new Map<number, Product>(),
    status: new Map<number, Status>(),
    ready: false
};


export function get<I extends { [key: string]: any }>(input: (I | undefined), key: string): (object | undefined) {
    if (input === undefined) {
        return undefined
    }

    return input[key]
}

export function getOrEmpty<I, K extends keyof I>(input: (I | undefined), key: string): (string) {
    const got = get(input, key);

    if (got === undefined) {
        return "";
    }

    return got.toString();
}

export function update(input: ({} | undefined), obj: {}): void {
    if (input === undefined || obj === undefined) {
        return
    }

    Object.assign(input, obj)
}

export default createConnectedStore(initialState);

// Ignore this if you're using React Hooks
export type StoreProps = {
    store: Store<DataState>
}

export type StoreEffects = Effects<DataState>