import React, {Component, ReactElement} from "react";
import {Dropdown} from "semantic-ui-react";
import DataStore, {StoreProps} from "../../models/DataStore";
import Helpers from "../Helpers";
import {SelectorProps} from "./FormProps";


interface CategorySelectorProps extends SelectorProps {
    value: number
}

class CategorySelector extends Component<StoreProps & CategorySelectorProps> {

    render(): ReactElement {
        if (!this.props.store.get("ready")) {
            return <></>
        }

        const categories = Array
            .from(this.props.store.get("categories"), ([key, value]) => value)
            .map(
                (value) => {
                    return {
                        "key": value.id,
                        "value": value.id,
                        "text": value.name
                    }
                }
            );


        return <Dropdown
            placeholder='Kategorie wählen'
            fluid
            search
            selection
            options={categories}
            name={this.props.name}
            value={Helpers.toNumber(this.props.value)}
            onChange={this.props.onChange}
            className={"category-selector"}
        />
    }
}

export default DataStore.withStore(CategorySelector);