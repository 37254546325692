import React, {Component, ReactElement} from "react";
import OptionalEntityForm, {OptionalEntityFormState} from "../../../base/forms/OptionalEntityForm";
import {api} from "../../../models/InitialData";
import {STANDARD_ID} from "../../../base/Helpers";
import EntityForm from "../../../base/forms/EntityForm";
import {PresetReceiver, ValueSelectorProps} from "../../../base/forms/FormProps";
import {Form, FormField, FormGroup, TextArea} from "semantic-ui-react";
import ProductSelector from "../../../base/forms/ProductSelector";
import SearchSelection from "../../../base/forms/SearchSelection";
import {
    defaultProjectState,
    fetchProjectSearchResults,
    getSearchProject
} from "../../time_management/views/ProjectAssociation";
import OptionalSupplierForm from "../../general/entities/views/OptionalSupplierForm";
import DateForm from "../../../base/forms/DateForm";
import CurrencyForm from "../../../base/forms/CurrencyForm";

export const defaultExternalInput = {
    "id": -1,
    "name": "",
    "project_id": -1,
    "product_id": -1,
    "supplier_id": -1,
    "delivery_date": null,
    "invoice_total": 0,
    "invoice_id": "",
    "notes": "",
    "created_at": null,
    "is_deleted": false,
};

class ExternalInputForm extends Component<{ create?: boolean, onCancel?: () => void, defaultProject?: typeof defaultProjectState }
    & ValueSelectorProps<number> & PresetReceiver<typeof defaultExternalInput>> {

    render(): ReactElement {
        return <OptionalEntityForm
            defaultState={defaultExternalInput}
            value={this.props.value}

            notPresentText={"keine Fremdleistung verknüpft"}
            name={"external_input_id"}
            preset={this.props.preset}
            get={(value) => api("/reports/aggregated_external_input/" + value, "GET")}
            set={(value, state) => {
                if (this.props.defaultProject) {
                    state.project_id = this.props.defaultProject.id;
                }

                return api("/external_input/", value === -1 ? "PUT" : "PATCH", state)
            }}

            fullSizeEdit
            deleteEntity
            onClick={"none"}
            create={this.props.create}
            onCancel={this.props.onCancel}
            changeHandler={this.changeHandler}
            {...STANDARD_ID}

            editing={this.editing}
            preview={this.preview}
        />
    }

    editing = (form: EntityForm<typeof defaultExternalInput, number, OptionalEntityFormState>): ReactElement => {
        return <>
            <h2>{this.props.value === -1 && <>neue </>}Fremdleistung{this.props.value !== -1 && <> bearbeiten</>}</h2>
            <div className={"ui form"}>
                <FormGroup widths={2}>
                    <FormField>
                        <label>Projekt</label>
                        <SearchSelection get={getSearchProject}
                                         resultFetcher={fetchProjectSearchResults}
                                         name={"project_id"}
                                         onChange={this.props.onChange}
                                         placeholder={"nach Projekt suchen"}
                                         defaultState={defaultProjectState}
                                         preset={this.props.defaultProject
                                             ? this.props.defaultProject.project_number + " " + this.props.defaultProject.name
                                             : undefined}
                                         disabled={!!this.props.defaultProject}

                                         {...STANDARD_ID}
                        />
                    </FormField>
                    <FormField>
                        <label>Produkt</label>
                        <ProductSelector value={form.state.product_id} name={"product_id"}
                                         onChange={form.handleChange} onlyExternal/>
                    </FormField>
                </FormGroup>
                <FormGroup widths={2}>
                    <Form.Input label='Rechnungs-Nr.' name='invoice_id' value={form.state.invoice_id}
                                onChange={form.handleChange} placeholder='RN20190307'/>
                    <CurrencyForm label='Rechnungssumme' name='invoice_total'
                                  value={form.state.invoice_total}
                                  onChange={form.handleChange}/>
                    <DateForm label={"Lieferdatum"} name='delivery_date'
                              onChange={form.handleChange} value={form.state.delivery_date}/>
                </FormGroup>
                <OptionalSupplierForm name={"supplier_id"} value={form.state.supplier_id} onChange={form.handleChange}/>
                <br/>
                <FormField>
                    <label>Notizen</label>
                    <TextArea name='notes' onChange={form.handleChange}
                              value={form.state.notes} type='text' placeholder='Raum für Notizen'/>
                </FormField>
            </div>
        </>;
    };

    preview = (state: typeof defaultExternalInput): ReactElement => {
        return <>
            <strong></strong>
        </>
    };

    changeHandler = (name: string, id: number) => {
        this.props.onChange(null, {name: name, value: id});
    };
}

export default ExternalInputForm