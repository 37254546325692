import React, {Component, ReactElement} from "react";
import {Confirm, PortalProps} from "semantic-ui-react";

export interface ConfirmPortalProps extends PortalProps {
    ok: () => void
    cancel: () => void
    text?: string
}

class ConfirmPortal extends Component<ConfirmPortalProps> {
    state = {
        open: true
    };

    render(): ReactElement {

        return <Confirm
            open={this.state.open}
            onCancel={this.doCancel}
            onConfirm={this.doOk}
            content={this.props.text}
            cancelButton={"Nein"}
            confirmButton={"Ja"}
        />
    }


    doOk = () => {
        this.setState({"open": false});
        this.props.ok()
    };

    doCancel = () => {
        this.setState({"open": false});
        this.props.cancel()
    }
}

export default ConfirmPortal