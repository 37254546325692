import React, {Component, ReactElement} from "react";
import ContentHeader from "../../base/ContentHeader";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import Overview from "./Overview";
import FastLane from "./FastLane";
import MenuLink from "../../base/MenuLink";
import RouterContent from "../../base/RouterContent";


class Dashboard extends Component<RouteComponentProps> {
    render(): ReactElement {
        const path = this.props.match.path;

        return (
            <>
                <ContentHeader title={"Dashboard"} isReady={true}
                               action={null}>
                    <MenuLink to={path} exact={true}>Übersicht</MenuLink>
                </ContentHeader>
                <RouterContent>
                    <Switch>
                        <Route path={path + "/fastlane"} component={FastLane}/>
                        <Route path={path} exact={true} component={Overview}/>
                    </Switch>
                </RouterContent>
            </>
        )
    }
}

export default Dashboard
