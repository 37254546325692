import React, {Component, ReactElement} from "react";
import {RouteComponentProps} from "react-router-dom";
import {api} from "../../models/InitialData";
import {Button, Card, Header, Input, Statistic, Table} from "semantic-ui-react";
import ContentHeader from "../../base/ContentHeader";
import RouterContent from "../../base/RouterContent";
import {defaultEmployeeReport} from "./EmployeeOverviewReport";
import Time from "../../models/Time";
import {assembleTable, defaultProjectCost, projectCostTableColsKey} from "../projects/SingleProject";
import Helpers from "../../base/Helpers";
import TimeTable from "../time_management/TimeTable";
import DataStore, {StoreProps} from "../../models/DataStore";
import Groups, {hasAnyGroup} from "../../base/Groups";
import {goHome} from "../../base/JSXHelpers";

const projectCols = ["project_name", "product_names", "working_minutes", "over_minutes", "total_time"] as projectCostTableColsKey[];
const productCols = ["product_names", "working_minutes", "over_minutes", "total_time"] as projectCostTableColsKey[];
const defaultSingleEmployeeReport =
    Object.assign({
        project_work: [] as typeof defaultProjectCost[],
        product_work: [] as typeof defaultProjectCost[],
    }, defaultEmployeeReport);

const defaultMinuteState = {
    totalMinutes: {
        project_work: 0,
        product_work: 0,
    },
};

class SingleEmployeeReport extends Component<RouteComponentProps<{ id: string }> & StoreProps> {
    thisYear = new Date().getUTCFullYear() + "";


    state = Object.assign({
        year: this.thisYear,
        editYear: this.thisYear,
        createObj: false,
    }, defaultMinuteState, defaultSingleEmployeeReport);

    componentDidMount(): void {
        api<typeof defaultSingleEmployeeReport>("/reports/employee_analysis/" + this.props.match.params.id
            + "?year=" + this.state.year, "GET")
            .then(resp => !resp.ok || resp.data === null
                ? this.props.history.goBack()
                : this.setState(Object.assign({
                    totalMinutes: {
                        project_work: resp.data.project_work.length === 0 ? 0 :
                            resp.data.project_work
                                .map(p => p.working_minutes + p.over_minutes)
                                .reduce((p, c) => p + c),

                        product_work: resp.data.product_work.length === 0 ? 0 :
                            resp.data.product_work
                                .map(p => p.working_minutes + p.over_minutes)
                                .reduce((p, c) => p + c)
                    }
                }, resp.data)));
    }

    componentDidUpdate(prevProps: any, prevState: Readonly<{ year: string, project_work: typeof defaultProjectCost[] }>): void {
        if (prevState.year !== this.state.year) {
            this.componentDidMount()
        }
    }

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT)) {
            return goHome()
        }

        const employee = this.state;


        return this.header(<>

            <Statistic.Group size={"small"} widths={4}>
                <Statistic label={"Urlaubstage"}
                           value={employee.used_vacation + " / " + employee.yearly_vacation}/>
                <Statistic label={"Überstunden"} color={employee.over_time_saldo < 0 ? "red" : undefined}
                           value={Time.parse(employee.over_time_saldo).toString()}/>
                <Statistic label={"Std. diesen Monat"}
                           value={Time.parse(employee.working_minutes_month).toString()}/>
                <Statistic label={"Krank"} value={employee.illness_days + " Tage"}/>
            </Statistic.Group>
            <br/>
            <Card fluid>
                <Card.Content>
                    <h2 style={{display: "inline-block"}}>Projektleistungen</h2>
                    <span style={{float: "right"}}>
                        <Header size={"small"} style={{"margin": "0 15px 0 0", "display": "inline-block"}}>
                            Jahr
                        </Header>
                        <Input value={this.state.editYear}
                               action={{
                                   disabled: this.state.year === this.state.editYear,
                                   icon: "check", onClick: () => this.setState({year: this.state.editYear})
                               }}
                               onChange={(a, v) => this.setState({editYear: v.value})}
                               type='number' placeholder='XXXX' size={"small"}/>
                    </span>
                    <Table basic='very' celled textAlign={"center"} className={"sumup"} structured>
                        {employee.project_work.length > 0 &&
                        assembleTable(projectCols, employee.project_work, p =>
                            this.props.history.push("/projects/view/" + p.project_id))}
                        {employee.project_work.length === 0 &&
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell colSpan={projectCols.length}>
                                    Keine Projektleistungen in diesem Zeitraum.
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>}
                        <Table.Footer>
                            <Table.Row>
                                <Table.Cell colSpan={projectCols.length - 1}
                                            textAlign={"right"}>
                                    <Header size={"small"} content={"Gesamtsumme"}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header size={"medium"}
                                            content={Time.parse(this.state.totalMinutes.project_work).toString()}/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Card.Content>
            </Card>
            <br/>
            <Card fluid>
                <Card.Content>
                    <h2 style={{display: "inline-block"}}>Produktleistungen</h2>
                    <span style={{float: "right"}}>
                        <Header size={"small"} style={{"margin": "0 15px 0 0", "display": "inline-block"}}>
                            Jahr
                        </Header>
                        <Input value={this.state.editYear}
                               action={{
                                   disabled: this.state.year === this.state.editYear,
                                   icon: "check", onClick: () => this.setState({year: this.state.editYear})
                               }}
                               onChange={(a, v) => this.setState({editYear: v.value})}
                               type='number' placeholder='XXXX' size={"small"}/>
                    </span>
                    <Table basic='very' celled textAlign={"center"} className={"sumup"} structured>
                        {employee.product_work.length > 0 &&
                        assembleTable(productCols, employee.product_work)}
                        {employee.product_work.length === 0 &&
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell colSpan={productCols.length}>
                                    Keine Produktleistungen in diesem Zeitraum.
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>}
                        <Table.Footer>
                            <Table.Row>
                                <Table.Cell colSpan={productCols.length - 1}
                                            textAlign={"right"}>
                                    <Header size={"small"} content={"Gesamtsumme"}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header size={"medium"}
                                            content={Time.parse(this.state.totalMinutes.product_work).toString()}/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Card.Content>
            </Card>
            <br/>
            <Card fluid>
                <Card.Content>
                    <h2 style={{display: "inline-block"}}>Zeiteinträge</h2>
                    <span style={{float: "right"}}>
                        <Button onClick={() => this.setState({createObj: true})} content={"neuer Eintrag"} primary/>
                    </span>
                    <br/>
                    <TimeTable menu={Helpers.setMenuAction(this)}
                               create={this.state.createObj}
                               userId={this.props.match.params.id}
                               cancelMenu={() => this.setState({createObj: false})}
                               onUpdate={() => this.componentDidMount()}/>
                </Card.Content>
            </Card>
        </>);
    }


    header(content: ReactElement) {
        const employee = this.state;

        return <>
            <ContentHeader
                title={employee.employee_display_name === "" ? "Mitarbeiter"
                    : employee.employee_display_name}
                isReady action={null} goBackButton>
                <a className={"item active"}>
                    Detaillierte Analyse
                </a>
            </ContentHeader>
            <RouterContent children={content}/>
        </>
    }
}

export default DataStore.withStore(SingleEmployeeReport)