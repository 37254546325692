import React, {Component, ReactElement} from "react";
import {Card} from "semantic-ui-react";
import {FullEmployee} from "../../../models/FullEmployee";
import {api} from "../../../models/InitialData";
import {MenuActionProps} from "../../../base/MainMenu";
import EmployeeView from "./views/EmployeeView";
import CreateEmployeeForm from "../forms/CreateEmployeeForm";
import SearchList from "../../../base/forms/SearchList";
import Groups, {hasAnyGroup} from "../../../base/Groups";
import {goHome} from "../../../base/JSXHelpers";
import DataStore, {StoreProps} from "../../../models/DataStore";


class EmployeeManagement extends Component<{ create: boolean } & MenuActionProps & StoreProps> {

    componentDidMount(): void {
        this.props.menu("Mitarbeiter erstellen");
    }

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT)) {
            return goHome()
        }

        return <SearchList
            match={(em: FullEmployee) => (em.person_id === -1 ? em.username
                : em.first_name + " " + em.last_name)}

            get={() => api("/reports/employees", "GET")}

            searchLabel={"nach Namen suchen"}
            placeholder={"Max Mustermann"}

            pageSize={14}

            render={(m, list) => <>
                {this.props.create && <CreateEmployeeForm
                    cancelCreate={() => {
                        this.props.cancelMenu();
                        list.componentDidMount();
                    }}/>}
                <Card.Group itemsPerRow={2}>
                    {
                        m.map(
                            (em: FullEmployee) => {
                                return <EmployeeView key={em.id} em={em}
                                                     changeHandler={() => list.componentDidMount()}/>
                            }
                        )
                    }
                    {m.length === 0 && <Card fluid><Card.Content>Keine Ergebnisse gefunden</Card.Content></Card>}
                </Card.Group>
            </>
            }
        />
    }
}


export default DataStore.withStore(EmployeeManagement)