import {Component, default as React, ReactElement} from "react";
import Helpers, {APIResponse} from "../Helpers";
import {FormData} from "./FormProps";
import {Message} from "semantic-ui-react";
import _ from "lodash";

export const IS_DELETED = "is_deleted";

export interface ChangeHandler<Index> {
    changeHandler: (name: string, value: Index) => void
}

export interface EntityFormProps<State, Index> extends ChangeHandler<Index> {
    defaultState: State
    value: Index
    name: string

    get?: (value: Index) => Promise<APIResponse<State>>
    delete?: (value: Index) => Promise<APIResponse<State>>
    set: (value: Index, state: State) => Promise<APIResponse<State>>
    getIdFromState: (state: State) => Index
    setIdOnState: (value: Index) => Partial<State>

    preset?: State
}


interface ExternalRenderer<State, Index, MetaState> {
    render: (form: EntityForm<State, Index, MetaState>) => ReactElement
}

interface MetaState<Additional> {
    metaState?: Additional
}

export interface EntityFormState {
    entityForm_editing: boolean
    entityForm_deletable: boolean
    entityForm_definiteError: string
    entityForm_temporaryError: string
}


const noErrors = {
    entityForm_definiteError: "",
    entityForm_temporaryError: ""
};

class EntityForm<State, Index, AdditionalState> extends Component
    <EntityFormProps<State, Index> & ExternalRenderer<State, Index, AdditionalState> & MetaState<AdditionalState>> {

    uneditedState = Helpers.immCopy(this.props.defaultState);

    initialFormState: EntityFormState & AdditionalState = Object.assign({
        entityForm_editing: false,
        entityForm_deletable: IS_DELETED in this.props.defaultState
    }, this.props.metaState, noErrors);

    // initialize state
    state: (State & EntityFormState & AdditionalState) =
        Object.assign({}, this.initialFormState, Helpers.immCopy(this.props.defaultState));

    componentDidUpdate(prevProps: Readonly<EntityFormProps<State, Index> & ExternalRenderer<State, Index, AdditionalState> & MetaState<AdditionalState>>): void {
        if ((prevProps.value !== this.props.value && this.props.value !== this.props.getIdFromState(this.state))
            || !_.isEqual(prevProps.preset, this.props.preset)) {
            this.componentDidMount();
        }
    }

    /**
     * componentDidMount handles the fetching of necessary entity-data
     */
    componentDidMount(): void {
        this.doFetchEntity(this.props.value)
    }

    definiteErrorNoMethod() {
        if (!this.props.preset && !this.props.get) {
            this.setState({
                "entityForm_definiteError":
                    Helpers.explainedError("Fehler beim Laden des Datenobjekts", "keine Methode bereitgestellt")
            });
        }
    }

    /**
     * doFetchEntity fetches the entity from the API
     * and saves it in this state
     */
    doFetchEntity = (id: Index, forceRemote?: boolean): Promise<State | null> => {
        let get: Promise<APIResponse<State>> | null = null;

        if (id === this.props.getIdFromState(this.props.defaultState)) {
            get = Helpers.getStatic(this.props.defaultState);
        } else if (this.props.preset && !forceRemote) {
            get = Helpers.getStatic(this.props.preset)
        } else if (this.props.get) {
            get = this.props.get(id);
        }

        if (get === null) {
            this.definiteErrorNoMethod();
            return new Promise<State | null>(r => r(null));
        }

        return get
            .then(
                (resp: APIResponse<State>) => {
                    this.saveStateUpdate(resp, {});
                    return resp.ok && resp.data != null ? resp.data : null;
                }
            )
    };

    /**
     * doUpdateModel saves a partial new state in this form
     * and commits the changes to the database
     * @param update the partial new state
     */
    doUpdateEntity = (update: Partial<State>): Promise<APIResponse<State>> => {
        return new Promise<APIResponse<State>>(
            resolve => {
                this.props
                    .set(this.props.value, Object.assign(this.getModel(), update))
                    .then(
                        (resp: APIResponse<State>) => {
                            this.saveStateUpdate(resp, {}, {
                                "entityForm_temporaryError":
                                    Helpers.explainedError("Konnte Entität nicht aktualisieren", resp.explain)
                            })
                                .then((r) => {
                                    resolve(r);
                                    if (r.ok && r.data !== null) {
                                        this.props.changeHandler(this.props.name, this.props.getIdFromState(r.data));
                                    }
                                });
                        }
                    )
            }
        )
    };

    /**
     * doDeleteEntity deletes this entity
     */
    doDeleteEntity = () => {
        let p: Promise<APIResponse<State>>;

        if (this.props.delete) {
            // delete with supplied method
            p = this.props.delete(this.props.value)
        } else if (IS_DELETED in this.uneditedState) {
            // delete with is_deleted
            Helpers.mergeInto(this.uneditedState, {[IS_DELETED]: true});
            p = this.props.set(this.props.value, this.uneditedState)
        } else {
            // no method applicable
            this.setState({
                "entityForm_definiteError": "Konnte Entität nicht löschen (keine Methode bereitgestellt)"
            });
            return;
        }

        if (p !== null) {
            p.then(
                (resp: APIResponse<State>) => {
                    if (resp.ok) {
                        const s = Object.assign({}, Helpers.immCopy(this.props.defaultState), this.initialFormState, noErrors);
                        this.props.changeHandler(this.props.name, this.props.getIdFromState(this.props.defaultState));
                        Helpers.mergeInto(this.uneditedState, s);
                        this.setState(s);
                    } else {
                        this.setState({
                            "entityForm_temporaryError":
                                Helpers.explainedError("Konnte Entität nicht löschen", resp.explain)
                        });
                    }
                }
            )
        }
    };

    /**
     * doStartEditing starts the edit mode of this form
     */
    doStartEditing = () => {
        this.setState({
            "entityForm_editing": true
        });
    };

    /**
     * doResetForm resets the form
     */
    doResetForm = () => {
        this.setState(Helpers.immCopy(this.uneditedState));
    };

    /**
     * doCancelEditing resets the form and stops the edit mode of this form
     */
    doCancelEditing = () => {
        this.setState(Object.assign({}, this.initialFormState, Helpers.immCopy(this.uneditedState)));
    };

    /**
     * doFinishEditing stops the edit mode of this form
     * and commits the changes to the backend
     */
    doFinishEditing = () => {
        if (_.isEqual(this.getModel(), this.uneditedState)) {
            this.setState(this.initialFormState);
        } else {
            const gotCreated = this.props.value
                === this.props.getIdFromState(this.props.defaultState);

            this
                .doUpdateEntity({})
                .then((resp) => {
                    if (resp.ok) {
                        this.setState(this.initialFormState);
                        if (resp.data !== null) {
                            this.doFetchEntity(this.props.getIdFromState(resp.data), true)
                        }
                    }
                });
        }
    };

    /**
     * doDeleteLinking deletes the link by notifying the upper component
     * that the value of this form changed.
     */
    doDeleteLinking = () => {
        Object.assign(this.uneditedState, this.props.defaultState);
        const s = Object.assign({}, Helpers.immCopy(this.uneditedState), this.initialFormState);
        this.setState(s);

        this.props.changeHandler(this.props.name, this.props.getIdFromState(s));
    };

    /**
     * handleChange saves input changes to the frontend-model
     * @param irrelevant just to please React's API
     * @param data the actual relevant field data
     */
    handleChange = (irrelevant: any, data: FormData) => {
        this.setState({[data.name as string]: Helpers.immCopy(data.value)});
    };

    /**
     * getModel returns the pure State Model of this form
     */
    getModel = (): State => {
        let obj = Helpers.immCopy(this.uneditedState);
        Helpers.mergeInto(obj, this.state);

        return Helpers.immCopy(obj);
    };

    /**
     * getTemporaryError returns a display for temporary errors
     */
    getTemporaryError = (): ReactElement => {
        if (this.state.entityForm_temporaryError !== "") {
            return <Message negative>
                <p>{this.state.entityForm_temporaryError}</p>
            </Message>
        }

        return <></>
    };

    /**
     * setValue allows a nested component to set the index value for this form
     * @param value the value to set
     */
    setValue = (value: Index) => {
        this.props.changeHandler(this.props.name, value);
        this.setState(this.initialFormState, () => this.doFetchEntity(value));
    };

    private saveStateUpdate = (resp: APIResponse<State>, formState: Partial<EntityFormState & AdditionalState>,
                               errors?: Partial<typeof noErrors>): Promise<APIResponse<State>> => {
        return new Promise<APIResponse<State>>(
            resolve => {
                if (!resp.ok) {
                    const s = errors ? errors : {
                        "entityForm_definiteError":
                            Helpers.explainedError("Fehler beim Laden des Datenobjekts", resp.explain)
                    };

                    this.setState(s, () => resolve(resp));
                } else {
                    Helpers.mergeInto(this.uneditedState, resp.data);
                    const s = Object.assign({}, noErrors, formState, Helpers.immCopy(this.uneditedState));

                    this.setState(s, () => resolve(resp));
                }
            }
        )

    };

    render(): ReactElement {
        if (this.state.entityForm_definiteError !== "") {
            return <Message negative>
                <Message.Header>Fehler im Formular</Message.Header>
                <p>{this.state.entityForm_definiteError}</p>
            </Message>
        }

        return this.props.render(this);
    }
}

export default EntityForm