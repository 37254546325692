import React, {Component, ReactElement, ReactNode} from "react";
import {NavLink} from "react-router-dom";

export interface MenuLinkProps {
    to: string
    children?: ReactNode
    exact?: boolean
    disabled?: boolean
}

class MenuLink extends Component<MenuLinkProps> {
    render(): ReactElement {
        return (
            <NavLink to={this.props.to} exact={this.props.exact} className="item" activeClassName="active">
                {this.props.children}
            </NavLink>
        );
    }
}

export default MenuLink;