import React, {Component, ReactElement} from "react";
import {Search, SearchProps, SearchResultData} from "semantic-ui-react";
import {FormData} from "./FormProps";

interface SearchSelectionProps<Model, Index> {
    get: (query: string, id?: Index) => Promise<Model[]>
    resultFetcher: (model: Model[]) => DisplayResult<Index>[]
    onChange: (irrelevant: any, data: FormData) => void
    defaultState: Model

    getIdFromState: (state: Model) => Index
    setIdOnState: (value: Index) => Partial<Model>

    name: (keyof Model & string) | string
    placeholder?: string

    disabled?: boolean,
    preset?: string
}

export interface DisplayResult<Index> {
    title: string,
    description?: string,
    value: Index
}

class SearchSelection<Model, Index> extends Component<SearchSelectionProps<Model, Index>> {
    _isMounted = false;
    initialResults: DisplayResult<Index>[] = [];
    timeout = setTimeout(() => {
    }, 0);

    currentId = this.props.getIdFromState(this.props.defaultState);

    state = {
        loading: false,
        results: this.initialResults,
        validInput: false,
        textInput: "",
        editable: true,
        error: false
    };

    componentWillMount(): void {
        this._isMounted = true;
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    render(): ReactElement {
        const preset = this.props.preset && this.props.getIdFromState(this.props.defaultState) === this.currentId
            ? this.props.preset : "";

        return <Search
            loading={this.state.loading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={this.handleSearchChange}
            results={this.state.results}
            value={preset.length > 0 ? this.props.preset : this.state.textInput}
            disabled={this.props.disabled}

            noResultsMessage={this.state.validInput ? "keine Ergebnisse gefunden" : "bitte mindestens 3 Zeichen eingeben"}

            placeholder={this.props.placeholder}
            name={this.props.name}
            fluid
        />
    }

    handleResultSelect = (irrelevant: any, data: SearchResultData) => {
        this.currentId = data.result.value;

        this.props.onChange(irrelevant, {
            name: this.props.name, value: this.currentId,
        });
        this.setDisplayResult(data.result);
    };

    setDisplayResult = (result: DisplayResult<Index>) => {
        this.setState({
            textInput: result.title + " " + result.description,
            editable: false
        });
    };

    handleSearchChange = (irrelevant: any, data: SearchProps) => {
        clearTimeout(this.timeout);
        const preset = this.props.preset ? this.props.preset : "";
        const value = data.value ? data.value : "";

        if (this.state.editable && preset.length === 0) {
            if (value.length >= 3) {
                this.setState({loading: true, textInput: value});
                this.timeout = setTimeout(() => {

                    this.props.get(value)
                        .then(resp => this._isMounted && this.setState({
                                results: this.props.resultFetcher(resp),
                                loading: false,
                                validInput: true
                            })
                        );
                }, 150);
            } else {
                this.setState({validInput: false, results: [], textInput: value});
            }
        } else if ((preset.length > 0 && preset.length > value.length)
            || (preset.length === 0 && this.state.textInput.length > value.length)) {

            this.currentId = this.props.getIdFromState(this.props.defaultState);

            this.setState({
                textInput: "",
                editable: true,
                validInput: false,
                results: []
            });
            this.props.onChange(irrelevant, {
                name: this.props.name, value: this.currentId
            });

        }
    }
}

export default SearchSelection