import React, {Component, ReactElement} from "react";
import {api} from "../../models/InitialData";
import {Card, Header, Label, Table} from "semantic-ui-react";
import ContentHeader from "../../base/ContentHeader";
import RouterContent from "../../base/RouterContent";
import {RouteComponentProps} from "react-router-dom";
import Time from "../../models/Time";
import DataStore, {StoreProps} from "../../models/DataStore";
import Groups, {hasAnyGroup} from "../../base/Groups";
import {goHome} from "../../base/JSXHelpers";

export const defaultEmployeeReport = {
    employee_id: -1,
    employee_display_name: "",
    yearly_vacation: 0,
    used_vacation: 0,
    illness_days: 0,
    working_minutes_month: 0,
    over_time_saldo: 0,
};


class EmployeeOverviewReport extends Component<RouteComponentProps & StoreProps> {
    state = {
        employees: [] as typeof defaultEmployeeReport[]
    };

    componentDidMount(): void {
        api<typeof defaultEmployeeReport[]>("/reports/employee_overview", "GET")
            .then(resp => !resp.ok || resp.data === null
                ? this.props.history.goBack()
                : this.setState({employees: resp.data}));
    }

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT)) {
            return goHome()
        }

        const employees = this.state.employees;

        return this.header(<Card fluid>
            <Card.Content>
                <Table basic='very' celled textAlign={"center"} className={"sumup"} singleLine
                       structured>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Urlaubstage</Table.HeaderCell>
                            <Table.HeaderCell>verbleibende ÜStd.</Table.HeaderCell>
                            <Table.HeaderCell>Std. diesen Monat</Table.HeaderCell>
                            <Table.HeaderCell>Krank</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            employees.length === 0 &&
                            <Table.Row>
                                <Table.Cell colSpan={5}>
                                    Keine anzuzeigenden Mitarbeiter
                                </Table.Cell>
                            </Table.Row>
                        }
                        {
                            employees.map(
                                e => <Table.Row key={e.employee_id} className={"selectable"}
                                                onClick={() => this.props.history.push("./employees/" + e.employee_id)}>
                                    <Table.Cell><Label content={e.employee_display_name}/></Table.Cell>
                                    <Table.Cell><Header size={"small"}
                                                        content={e.used_vacation + " von " + e.yearly_vacation}/></Table.Cell>
                                    <Table.Cell><Header size={"medium"}
                                                        color={e.over_time_saldo < 0 ? "red" : undefined}
                                                        content={Time.parse(e.over_time_saldo).toString()}/></Table.Cell>
                                    <Table.Cell><Header size={"medium"}
                                                        content={Time.parse(e.working_minutes_month).toString()}/></Table.Cell>
                                    <Table.Cell><Header size={"medium"}
                                                        content={e.illness_days + " Tage"}/></Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </Card.Content>
        </Card>);
    }


    header(content: ReactElement) {
        return <>
            <ContentHeader
                title={"Mitarbeiter: Übersicht"}
                isReady action={null} goBackButton>
                <div className={"item"}>
                    Für detaillierte Analyse Mitarbeiter auswählen
                </div>
            </ContentHeader>
            <RouterContent children={content}/>
        </>
    }
}

export default DataStore.withStore(EmployeeOverviewReport)