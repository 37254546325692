import React, {Component, ReactElement} from 'react';
import {Dimmer, Loader, Sidebar} from "semantic-ui-react";
import MainMenu from "./base/MainMenu";
import Store, {StoreProps} from './models/DataStore'
import InitialData, {api} from "./models/InitialData";
import Dashboard from "./pages/dashboard/Dashboard";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Projects from "./pages/projects/Projects";
import MyAccount from "./pages/general/myaccount/MyAccount";
import BaseDataPage from "./pages/general/basedata/BaseDataPage";
import EntitiesPage from "./pages/general/entities/EntitiesPage";
import TimePage from "./pages/time_management/TimePage";
import Contacts from "./pages/contacts/Contacts";
import ReportsRouter from "./pages/reports/ReportsRouter";


class App extends Component<StoreProps> {
    tabState = {open: true, timeStamp: 0};

    render(): ReactElement {
        const isReady = this.props.store.get("ready");

        return (
            <Router basename={"/ui/"}>
                <Sidebar.Pushable>
                    <InitialData/>
                    <Dimmer inverted active={!isReady}>
                        <Loader/>
                    </Dimmer>
                    {this.onReadyMenu(isReady)}
                    <div id='content-wrapper'>
                        {this.onReadyRouter(isReady)}
                    </div>
                </Sidebar.Pushable>
            </Router>
        );
    }

    onReadyMenu(isReady: boolean): ReactElement {
        if (!isReady) {
            return <></>
        }

        return <MainMenu/>
    }

    onReadyRouter(isReady: boolean): ReactElement {
        if (!isReady) {
            return <></>
        }

        return <Switch>
            <Route path="/dashboard" component={Dashboard}/>
            <Route path="/time_management" component={TimePage}/>
            <Route path="/projects" component={Projects}/>
            <Route path="/reports" component={ReportsRouter}/>
            <Route path="/contacts" component={Contacts}/>
            <Route path="/myaccount" component={MyAccount}/>
            <Route path="/basedata" component={BaseDataPage}/>
            <Route path="/entities" component={EntitiesPage}/>
            <Redirect path="/" to="/dashboard"/>
        </Switch>
    }

    componentDidMount() {
        window.addEventListener("focus", this.handleTabOpened);
        window.addEventListener("blur", this.handleTabOpened);
    }

    componentWillUnmount(): void {
        window.removeEventListener("focus", this.handleTabOpened)
        window.addEventListener("blur", this.handleTabOpened);
    }

    handleTabOpened = (event: Event) => {
        const newTabState = {open: event.type === "focus", timeStamp: event.timeStamp};

        if (newTabState.open && newTabState.open !== this.tabState.open
            && event.timeStamp - this.tabState.timeStamp > 1000 * 60) {
            api("/reports/logged_in", "GET")
        }

        this.tabState = newTabState;
    }
}

export default Store.withStore(App);
