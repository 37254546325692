import React, {Component, ReactElement} from "react";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import ContentHeader from "../../base/ContentHeader";
import MenuLink from "../../base/MenuLink";
import RouterContent from "../../base/RouterContent";
import PeopleContacts from "./PeopleContacts";
import Helpers from "../../base/Helpers";
import CompanyContacts from "./CompanyContacts";
import SingleCompanyContacts from "./SingleCompanyContacts";


class Contacts extends Component<RouteComponentProps> {
    state = {
        menuAction: null,
        createObj: false
    };


    render(): ReactElement {
        const path = this.props.match.path;

        return <Switch>
            <Route path={path + "/companies/:id"} exact component={SingleCompanyContacts}/>
            <Route path={path + "/companies"} exact>
                {this.header(<CompanyContacts/>)}
            </Route>
            <Route path={path} exact>
                {this.header(<PeopleContacts create={this.state.createObj} menu={Helpers.setMenuAction(this)}
                                             cancelMenu={Helpers.cancelAction(this)}/>)}
            </Route>
        </Switch>
    }

    header(children: ReactElement) {
        const path = this.props.match.path;
        return <>
            <ContentHeader title={"Kontakte"} isReady action={null}>
                <MenuLink to={path} exact>Personen</MenuLink>
                <MenuLink to={path + "/companies"} exact>Unternehmen</MenuLink>
            </ContentHeader>
            <RouterContent children={children}/>
        </>
    }
}

export default Contacts