import React, {Component, ReactElement} from "react";
import {FormInput, InputOnChangeData} from "semantic-ui-react";
import {DataFormProps} from "./FormProps";

class NumberSelector extends Component<DataFormProps<number> & { placeholder: string }> {
    render(): ReactElement {
        return <FormInput fluid
                          label={this.props.label}
                          name={this.props.name}
                          onChange={this.onChange}
                          value={this.props.value}
                          type='number'
                          placeholder={this.props.placeholder}
        />
    }

    onChange = (irrelevant: any, data: InputOnChangeData) => {
        this.props.onChange(irrelevant, {
            "name": data.name,
            "value": parseInt(data.value)
        });
    }
}

export default NumberSelector