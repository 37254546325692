import React, {Component, ReactElement} from "react";
import {MenuActionProps} from "../../../base/MainMenu";
import {api} from "../../../models/InitialData";
import {Table} from "semantic-ui-react";
import SearchList from "../../../base/forms/SearchList";
import {Product} from "../../../models/Product";
import DataStore, {StoreProps} from "../../../models/DataStore";
import ProductView from "./views/ProductView";
import Helpers from "../../../base/Helpers";
import Groups, {hasAnyGroup} from "../../../base/Groups";
import {goHome} from "../../../base/JSXHelpers";

const defaultProduct: Product = {
    category_id: -1,
    created_at: Helpers.NULL_DATE,
    extended_name: "",
    id: -1,
    notes: "",
    short_name: "",
    updated_at: Helpers.NULL_DATE
};

class ProductTypes extends Component<{ create: boolean } & MenuActionProps & StoreProps> {

    componentDidMount(): void {
        this.props.menu("Produkt erstellen");
    }

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT)) {
            return goHome()
        }

        return <SearchList
            match={(p: Product) => (p.short_name + " " + p.extended_name)}

            get={() => api("/reports/products", "GET")}

            searchLabel={"nach Produkten suchen"}
            placeholder={"3D Modelling"}

            pageSize={15}

            render={(m, list) => <>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Beschreibung</Table.HeaderCell>
                            <Table.HeaderCell>Kategorie</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.props.create &&
                        <ProductView product={defaultProduct} create cancelCreate={() => {
                            this.props.cancelMenu();
                            list.componentDidMount();
                        }}/>}
                        {
                            m.map(
                                (p: Product) => {
                                    return <ProductView product={p} key={p.id}/>
                                }
                            )
                        }
                        {m.length === 0 && <Table.Row>
                        <Table.Cell colSpan='3'>
                            Keine Ergebnisse gefunden
                        </Table.Cell>
                    </Table.Row>}
                    </Table.Body>
                </Table>
            </>
            }
        />
    }
}

export default DataStore.withStore(ProductTypes)