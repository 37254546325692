import React, {Component, ReactElement} from "react";
import {api} from "../../models/InitialData";
import {defaultProjectCost} from "../projects/SingleProject";
import {defaultSupplerOverview} from "./SupplierOverviewReport";
import {RouteComponentProps} from "react-router-dom";
import ContentHeader from "../../base/ContentHeader";
import RouterContent from "../../base/RouterContent";
import {Card, Header, Input, Label, Statistic, Table} from "semantic-ui-react";
import CurrencyForm from "../../base/forms/CurrencyForm";
import Helpers from "../../base/Helpers";
import DataStore, {StoreProps} from "../../models/DataStore";
import Groups, {hasAnyGroup} from "../../base/Groups";
import {goHome} from "../../base/JSXHelpers";


interface SupplierProjectReport {
    "project_id": number,
    "delivery_date": string | null,
    "project_display_name": string,
    "product_name": string,
    "invoice_id": string,
    "invoice_total": number
}

const defaultSingleSupplierReport = Object.assign({
    projects: [] as SupplierProjectReport[],
}, defaultSupplerOverview);

class SingleSupplierReport extends Component<RouteComponentProps<{ id: string }> & StoreProps> {
    thisYear = new Date().getUTCFullYear() + "";


    state = Object.assign({
        year: this.thisYear,
        editYear: this.thisYear,
    }, defaultSingleSupplierReport);

    componentDidMount(): void {
        api<typeof defaultSingleSupplierReport>("/reports/supplier_analysis/"
            + this.props.match.params.id + "?year=" + this.state.year, "GET")
            .then(resp => !resp.ok || resp.data === null
                ? this.props.history.goBack()
                : this.setState(resp.data));
    }

    componentDidUpdate(prevProps: any, prevState: Readonly<{ year: string, project_work: typeof defaultProjectCost[] }>): void {
        if (prevState.year !== this.state.year) {
            this.componentDidMount();
        }
    }


    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT)) {
            return goHome()
        }

        const supplier = this.state;

        return this.header(
            <>
                <Header textAlign={"center"} content={this.state.year + " hatte " + supplier.supplier_display_name}/>
                <Statistic.Group size={"small"} widths={3}>
                    <Statistic label={"Projekte"}

                               value={supplier.project_amount}/>
                    <Statistic label={"Kosten"}
                               value={CurrencyForm.currencyString(supplier.invoice_total)}/>
                    <Statistic label={"Bestellungen"}
                               value={supplier.order_amount}/>
                </Statistic.Group>
                <br/>
                <Card fluid>
                    <Card.Content>
                        <h2 style={{display: "inline-block"}}>Kosten</h2>
                        <span style={{float: "right"}}>
                        <Header size={"small"} style={{"margin": "0 15px 0 0", "display": "inline-block"}}>
                            Jahr
                        </Header>
                        <Input value={this.state.editYear}
                               action={{
                                   disabled: this.state.year === this.state.editYear,
                                   icon: "check", onClick: () => this.setState({year: this.state.editYear})
                               }}
                               onChange={(a, v) => this.setState({editYear: v.value})}
                               type='number' placeholder='XXXX' size={"small"}/>
                    </span>
                        <Table basic='very' celled textAlign={"center"} className={"sumup"} structured>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Datum</Table.HeaderCell>
                                    <Table.HeaderCell>Projekt</Table.HeaderCell>
                                    <Table.HeaderCell>Produkt</Table.HeaderCell>
                                    <Table.HeaderCell>Rechnungs-Nr.</Table.HeaderCell>
                                    <Table.HeaderCell width={3}>Summe</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {supplier.projects.length === 0 &&
                                <Table.Row>
                                    <Table.Cell colSpan={5}>
                                        Keine Projektarbeit im gewählten Zeitraum.
                                    </Table.Cell>
                                </Table.Row>
                                }
                                {
                                    supplier.projects.map(
                                        e => <Table.Row key={e.project_id} className={"selectable"}
                                                        onClick={() => this.props.history.push("/projects/view/" + e.project_id)}>
                                            <Table.Cell><Header size={"small"}
                                                                content={Helpers.dateString(e.delivery_date)}/></Table.Cell>
                                            <Table.Cell><Label content={e.project_display_name}/></Table.Cell>
                                            <Table.Cell><Header size={"small"}
                                                                content={e.product_name}/></Table.Cell>
                                            <Table.Cell><Header size={"small"}
                                                                content={e.invoice_id}/></Table.Cell>
                                            <Table.Cell><Header size={"medium"}
                                                                content={CurrencyForm.currencyString(e.invoice_total)}/></Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.Cell colSpan={4}
                                                textAlign={"right"}>
                                        <Header size={"small"} content={"Gesamtsumme"}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Header size={"medium"}
                                                color={supplier.invoice_total > 0 ? "red" : undefined}
                                                content={CurrencyForm.currencyString(supplier.invoice_total)}/>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Card.Content>
                </Card>
            </>
        )
    }

    header(content: ReactElement) {
        const supplier = this.state;

        return <>
            <ContentHeader
                title={supplier.supplier_display_name === "" ? "Zulieferer"
                    : supplier.supplier_display_name}
                isReady action={null} goBackButton>
                <a className={"item active"}>
                    Detaillierte Analyse
                </a>
            </ContentHeader>
            <RouterContent children={content}/>
        </>
    }
}

export default DataStore.withStore(SingleSupplierReport)