import React, {Component, ReactElement} from "react";
import {MenuActionProps} from "../../../base/MainMenu";
import DataStore, {StoreProps} from "../../../models/DataStore";
import SearchList from "../../../base/forms/SearchList";
import {api} from "../../../models/InitialData";
import {Card} from "semantic-ui-react";
import OptionalCompanyForm from "./views/OptionalCompanyForm";
import {AggregatedCompany} from "../../../models/AggregatedCompany";

export const defaultCompany = {
    "id": -1,
    "name": "",
    "address_id": -1,
    "notes": "",
    "is_deleted": false,
    "street": "",
    "street_number": "",
    "postcode": "",
    "city": "",
    "country": "DE",
    "is_customer": false,
    "is_supplier": false,
};

class Companies extends Component<{ create: boolean } & MenuActionProps & StoreProps> {

    componentDidMount(): void {
        this.props.menu("Unternehmen erstellen");
    }

    render(): ReactElement {
        return <SearchList
            match={(p: AggregatedCompany) => p.name}

            get={() => api("/reports/companies", "GET")}

            searchLabel={"nach Unternehmen suchen"}
            placeholder={"Beispiel Gesellschaft mbH"}

            pageSize={14}

            render={(m, list) => <>
                {this.props.create &&
                <OptionalCompanyForm name={"company_id"} value={-1} create entity onChange={(a, b) => {
                    this.props.cancelMenu();
                    list.componentDidMount();
                }}/>}
                <Card.Group itemsPerRow={2}>
                    {
                        m.map(
                            (c: AggregatedCompany) => {
                                return <OptionalCompanyForm name={"company_id"} value={c.id} preset={c} key={c.id}
                                                            onChange={() => list.componentDidMount()} entity/>
                            }
                        )
                    }
                    {m.length === 0 && <Card fluid><Card.Content>Keine Ergebnisse gefunden</Card.Content></Card>}
                </Card.Group>
            </>
            }
        />
    }
}

export default DataStore.withStore(Companies)