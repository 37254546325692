import React, {Component, ReactElement} from "react";
import {MenuActionProps} from "../../../base/MainMenu";
import SearchList from "../../../base/forms/SearchList";
import {api} from "../../../models/InitialData";
import {Card} from "semantic-ui-react";
import OptionalSupplierForm, {defaultSupplier} from "./views/OptionalSupplierForm";
import Groups, {hasAnyGroup} from "../../../base/Groups";
import {goHome} from "../../../base/JSXHelpers";
import DataStore, {StoreProps} from "../../../models/DataStore";

class SupplierManagement extends Component<{ create: boolean } & MenuActionProps & StoreProps> {

    componentDidMount(): void {
        this.props.menu("Zulieferer erstellen");
    }

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.ACQUISITION, Groups.MANAGEMENT, Groups.PROJECTLEAD)) {
            return goHome()
        }

        return <SearchList
            match={(sup: typeof defaultSupplier) => sup.company_name + " "
                + sup.persons.flatMap((p) => p.first_name + " " + p.last_name)}

            get={() => api("/reports/suppliers", "GET")}

            searchLabel={"nach Namen suchen"}
            placeholder={"Beispiel Gesellschaft mbH"}

            pageSize={14}

            render={(m, list) => <>
                {this.props.create &&
                <OptionalSupplierForm name={"supplier_id"} value={-1} create entity onChange={(a, b) => {
                    this.props.cancelMenu();
                    list.componentDidMount();
                }}/>}
                <Card.Group itemsPerRow={2}>
                    {
                        m.map(
                            (sup: typeof defaultSupplier) => {
                                return <OptionalSupplierForm name={"supplier_id"} value={sup.id} preset={sup}
                                                             key={sup.id}
                                                             onChange={() => list.componentDidMount()} entity/>
                            }
                        )
                    }
                    {m.length === 0 && <Card fluid><Card.Content>Keine Ergebnisse gefunden</Card.Content></Card>}
                </Card.Group>
            </>
            }
        />
    }
}

export default DataStore.withStore(SupplierManagement)