import React, {Component, ReactElement} from "react";
import {Button, Card, Message} from "semantic-ui-react";
import Helpers from "../../base/Helpers";


const defaultFastLaneState = {
    positiveMessage: false,
    editIdx: -1,
};

class FastLane extends Component {
    timeout = setTimeout(() => {
    }, 0);

    state = Helpers.immCopy(defaultFastLaneState);

    componentDidMount(): void {
        this.showSuccess();
    }

    componentWillUnmount(): void {
        clearTimeout(this.timeout);
    }

    render(): ReactElement {
        return <>
            {this.state.positiveMessage &&
            <Message positive>
                <Message.Header>Speichern erfolgreich</Message.Header>
                <p>
                    Die eingetragenen Daten sind nun in den entsprechenden Ansichten verfügbar.
                </p>
            </Message>}

            <Card fluid>
                <Card.Content>
                    <Button icon='plus' floated="right" size="mini"
                            onClick={() => this.showSuccess()} circular/>
                    Überstunden abbummeln
                </Card.Content>
            </Card>
            <Card fluid>
                <Card.Content>
                    <Button icon='plus' floated="right" size="mini"
                            onClick={() => this.showSuccess()} circular/>
                    Überstunden abbummeln
                </Card.Content>
            </Card>
            <Card fluid>
                <Card.Content>
                    <Button icon='plus' floated="right" size="mini"
                            onClick={() => this.showSuccess()} circular/>
                    Überstunden abbummeln
                </Card.Content>
            </Card>
            <Card fluid>
                <Card.Content>
                    <Button icon='plus' floated="right" size="mini"
                            onClick={() => this.showSuccess()} circular/>
                    Überstunden abbummeln
                </Card.Content>
            </Card>
        </>
    }

    showSuccess = () => {
        this.setState({positiveMessage: true});
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() =>
            this.setState({positiveMessage: false}), 3000)
    }
}

export default FastLane;