import React, {Component, ReactElement} from "react";
import ContentHeader from "../../base/ContentHeader";
import RouterContent from "../../base/RouterContent";
import {Route, RouteComponentProps, Switch} from "react-router";
import {Card} from "semantic-ui-react";
import MenuLink from "../../base/MenuLink";
import EmployeeOverviewReport from "./EmployeeOverviewReport";
import SingleEmployeeReport from "./SingleEmployeeReport";
import ProductOverviewReport from "./ProductOverviewReport";
import CustomerOverviewReport from "./CustomerOverviewReport";
import SingleCustomerReport from "./SingleCustomerReport";
import SupplierOverviewReport from "./SupplierOverviewReport";
import SingleSupplierReport from "./SingleSupplierReport";
import CompanySuccessReport from "./CompanySuccessReport";
import DataStore, {StoreProps} from "../../models/DataStore";
import Groups, {hasAnyGroup} from "../../base/Groups";
import {goHome} from "../../base/JSXHelpers";


class ReportsRouter extends Component<RouteComponentProps & StoreProps> {
    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT)) {
            return goHome()
        }


        const path = this.props.match.path;
        const history = this.props.history;

        return <Switch>
            <Route path={path + "/employees/:id"} exact component={SingleEmployeeReport}/>
            <Route path={path + "/employees"} exact component={EmployeeOverviewReport}/>
            <Route path={path + "/products"} exact component={ProductOverviewReport}/>
            <Route path={path + "/customers/:id"} exact component={SingleCustomerReport}/>
            <Route path={path + "/customers"} exact component={CustomerOverviewReport}/>
            <Route path={path + "/suppliers/:id"} exact component={SingleSupplierReport}/>
            <Route path={path + "/suppliers"} exact component={SupplierOverviewReport}/>
            <Route path={path + "/company_success"} exact component={CompanySuccessReport}/>


            <Route path={path} exact>
                {this.header(
                    <Card.Group>
                        <Card fluid onClick={() => history.push(path + "/employees")}>
                            <Card.Content>
                                <Card.Header>Mitarbeiter</Card.Header>
                                <Card.Description>
                                    Übersicht der Stundenaufzeichnung aller Mitarbeiter und detaillierte Auswertung
                                    eines Mitarbeiters
                                </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card fluid onClick={() => history.push(path + "/products")}>
                            <Card.Content>
                                <Card.Header>Produkte</Card.Header>
                                <Card.Description>
                                    Auswertung des Stundenaufwands pro Produkt
                                </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card fluid onClick={() => history.push(path + "/customers")}>
                            <Card.Content>
                                <Card.Header>Kunden</Card.Header>
                                <Card.Description>
                                    Detaillierte Auswertung aller Aufzeichnungen zu einem Kundenunternehmen
                                </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card fluid onClick={() => history.push(path + "/suppliers")}>
                            <Card.Content>
                                <Card.Header>Zulieferer</Card.Header>
                                <Card.Description>
                                    Detaillierte Auswertung aller Aufzeichnungen zu einem Zuliefererunternehmen
                                </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card fluid onClick={() => history.push(path + "/company_success")}>
                            <Card.Content>
                                <Card.Header>Unternehmenserfolg</Card.Header>
                                <Card.Description>
                                    Auswertung der Erträge aus Projekten
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Card.Group>
                )}
            </Route>
        </Switch>
    }


    header(content: ReactElement) {
        return <>
            <ContentHeader
                title={"Berichte"} isReady action={null}>
                <MenuLink to={this.props.match.path}>Auswahl</MenuLink>
            </ContentHeader>
            <RouterContent children={content}/>
        </>
    }
}


export default DataStore.withStore(ReportsRouter)