import * as React from "react";
import {Component, ReactElement} from "react";
import {Button, Container, Loader, Menu, Segment} from "semantic-ui-react";
import {RouteComponentProps, withRouter} from 'react-router-dom'

import './ContentHeader.css'

export class MenuAction {
    fn: () => any;
    text: string;

    constructor(fn: () => any, text: string) {
        this.fn = fn;
        this.text = text;
    }
}

export interface ContentHeaderProps {
    isReady?: boolean
    children?: React.ReactNode
    title: string
    action?: MenuAction | null
    goBackButton?: boolean
}


class ContentHeader extends Component<ContentHeaderProps & RouteComponentProps> {

    render(): ReactElement {
        const hasMenu = this.props.children === undefined ? "" : " has-menu";

        return (
            <Segment basic id='content-header'>
                <Container text>
                    {this.props.goBackButton &&
                    <Button circular size={"big"} icon='arrow left' className={"menu-back"}
                            onClick={() => this.props.history.goBack()}/>}
                    <div className={"vcenter-text" + hasMenu}>
                        <h1 className='header-text vcenter-text'>{this.props.title}
                            <Loader active={!(this.props.isReady === undefined || this.props.isReady)} inline
                                    size={"small"}/>
                        </h1>
                        {this.props.action &&
                        <Button primary size='large' onClick={this.props.action.fn}>{this.props.action.text}</Button>
                        }
                    </div>
                    <Menu secondary pointing className='secondary-menu'>
                        {this.props.children}
                    </Menu>
                </Container>
            </Segment>
        );
    }
}

export default withRouter(ContentHeader)