import React, {Component, ReactElement} from "react";
import {FormSelect} from "semantic-ui-react";
import DataStore, {StoreProps} from "../../models/DataStore";
import Helpers from "../Helpers";
import {SelectorProps} from "./FormProps";


interface GenderSelectorProps extends SelectorProps {
    value: number | undefined
}

class GenderSelector extends Component<StoreProps & GenderSelectorProps> {

    render(): ReactElement {
        if (!this.props.store.get("ready")) {
            return <></>
        }

        const genders = Array
            .from(this.props.store.get("genders"), ([key, value]) => value)
            .map(
                (value) => {
                    return {
                        "key": value.id,
                        "value": value.id,
                        "text": value.name
                    }
                }
            );


        return <FormSelect
            label='Geschlecht'
            options={genders}
            name={this.props.name}
            placeholder='unbekannt'
            value={Helpers.toNumber(this.props.value)}
            onChange={this.props.onChange}
        />
    }
}

export default DataStore.withStore(GenderSelector);