import React, {Component, ReactElement, ReactNode} from "react";
import {Button, Form, Message} from "semantic-ui-react";
import DataStore, {StoreProps} from "../../models/DataStore";
import {APIResponse} from "../Helpers";

export interface AbstractFormProps {
    submit: () => Promise<APIResponse<any>>
    cancel: () => void
    loading?: boolean
    preview: ReactNode
    children: ReactNode
    editing?: boolean
    errors?: boolean
}

class AbstractForm extends Component<StoreProps & AbstractFormProps> {
    state = {
        "editing": this.props.editing ? this.props.editing : false,
        "error": ""
    };

    render(): ReactElement {
        if (!this.props.store.get("ready")) {
            return <></>
        }

        if (this.state.editing) {
            return <Form>
                {this.props.children}
                {
                    this.state.error === "" ? <></> :
                        <Message negative>
                            <p><strong>Fehler im Formular</strong>: {this.state.error}</p>
                        </Message>
                }
                <Button type='submit' primary floated="right" onClick={this.finishEditing}>Speichern</Button>
                <Button type='reset' floated="right" onClick={this.cancelEditing}>Abbrechen</Button>
            </Form>
        } else {
            return <Form loading={this.props.loading}>
                <Button icon='pencil alternate' floated="right" size="mini"
                        onClick={this.startEditing}
                        circular/>
                {this.props.preview}
            </Form>
        }
    }

    private startEditing = () => {
        this.setState({"editing": true});
    };

    private finishEditing = () => {
        this.props
            .submit()
            .then(resp => {
                if (resp.ok) {
                    this.setState({"editing": false, "error": ""});
                } else if (this.props.errors) {
                    this.setState({"error": resp.explain})
                }
            });
    };

    private cancelEditing = () => {
        this.props.cancel();
        this.setState({"editing": false, "error": ""});
    }

}


export default DataStore.withStore(AbstractForm)