import React, {Component, ReactElement} from "react";
import ContentHeader from "../../../base/ContentHeader";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import RouterContent from "../../../base/RouterContent";
import DataStore, {StoreProps} from "../../../models/DataStore";
import MySettings from "./MySettings";
import PersonalSite from "./MyBaseData";
import MenuLink from "../../../base/MenuLink";


class MyAccount extends Component<StoreProps & RouteComponentProps> {
    render(): ReactElement {
        const path = this.props.match.path;

        return <>
            <ContentHeader title={"Mein Account"}  isReady={true}>
                <MenuLink to={path} exact={true}>Stammdaten</MenuLink>
            </ContentHeader>
            <RouterContent>
                <Switch>
                    <Route path={path + "/settings"} component={MySettings}/>
                    <Route path={path} exact={true} component={PersonalSite}/>
                </Switch>
            </RouterContent>
        </>
    }
}

export default DataStore.withStore(MyAccount)