import Helpers, {STANDARD_ID} from "../../../base/Helpers";
import React, {Component, ReactElement} from "react";
import OptionalEntityForm, {OptionalEntityFormState} from "../../../base/forms/OptionalEntityForm";
import {FormData, PresetReceiver, ValueSelectorProps} from "../../../base/forms/FormProps";
import {api} from "../../../models/InitialData";
import {CardDescription, CardHeader, CardMeta, Divider, Form, Label} from "semantic-ui-react";
import GenderSelector from "../../../base/forms/GenderSelector";
import DateForm from "../../../base/forms/DateForm";
import DataStore, {get, StoreProps} from "../../../models/DataStore";
import JSXHelpers from "../../../base/JSXHelpers";
import OptionalAddressForm, {addressPreview} from "./OptionalAddressForm";
import EntityForm from "../../../base/forms/EntityForm";
import SearchSelection, {DisplayResult} from "../../../base/forms/SearchSelection";

export const defaultPerson = {
    "id": -1,
    "date_of_birth": null,
    "gender_id": -1,
    "first_name": "",
    "last_name": "",
    "telephone_mobile": "",
    "telephone_fixed": "",
    "telephone_fax": "",
    "email": "",
    "address_id": -1,
    "street": "",
    "street_number": "",
    "postcode": "",
    "city": "",
    "country": "DE",
    "is_customer": false,
    "is_employee": false,
    "is_supplier": false,
};

class OptionalPersonForm extends Component<{ create?: boolean, entity?: boolean, onClick?: () => void }
    & ValueSelectorProps<number> & StoreProps & PresetReceiver<typeof defaultPerson>> {

    genders = this.props.store.get("genders");

    render(): ReactElement {
        return <OptionalEntityForm
            defaultState={defaultPerson}
            value={this.props.value}
            name={this.props.name}
            notPresentText={"noch keine Person verknüpft"}

            fullSizeEdit={this.props.entity}
            deleteEntity={this.props.entity}
            create={this.props.create}
            onCancel={this.doCancelEditing}
            changeHandler={this.changeHandler}
            onClick={this.props.onClick}
            unDeletable={this.props.entity}

            get={(value) => api("/reports/aggregated_person/" + value, "GET")}
            set={(value, state) => api("/person/", value === defaultPerson.id ? "PUT" : "PATCH", state)}
            preset={this.props.preset}

            {...STANDARD_ID}

            preview={this.preview}
            editing={this.editing}
        />
    }

    doCancelEditing = () => {
        if (this.props.create) {
            this.changeHandler(this.props.name, -1)
        }
    };

    changeHandler = (name: string, id: number) => {
        this.props.onChange(null, {name: name, value: id});
    };

    editing = (form: EntityForm<typeof defaultPerson, number, OptionalEntityFormState>): ReactElement => {
        const addressPreset = Helpers.immCopy(form.state);
        addressPreset.id = addressPreset.address_id;

        return <div className={"ui form"}>
            <h2>Person</h2>
            {form.state.id === defaultPerson.id &&
            <>
                <SearchSelection get={this.getSearch}
                                 resultFetcher={this.fetchSearchResults}
                                 name={"id"}
                                 onChange={(a: any, b: FormData) => form.setValue(b.value)}
                                 placeholder={"nach bestehender Person suchen"}
                                 defaultState={defaultPerson}

                                 {...STANDARD_ID}
                />
                <Divider/>
            </>
            }
            <Form.Group widths={2}>
                <GenderSelector name='gender_id' value={form.state.gender_id} onChange={form.handleChange}/>
                <DateForm label='Geburtsdatum' name='date_of_birth' onChange={form.handleChange}
                          value={form.state.date_of_birth}/>
            </Form.Group>
            <Form.Group widths={2}>
                <Form.Input fluid label='Vorname' name='first_name' value={form.state.first_name}
                            onChange={form.handleChange} placeholder='Max'/>
                <Form.Input fluid label='Nachname' name='last_name' value={form.state.last_name}
                            onChange={form.handleChange} placeholder='Mustermann'/>
            </Form.Group>
            <Form.Group widths={2}>
                <Form.Input fluid label='Telefon (mobil)' name='telephone_mobile'
                            value={form.state.telephone_mobile}
                            onChange={form.handleChange} type='tel' placeholder='+49 1577 12345'/>
                <Form.Input fluid label='Telefon (Festnetz)' name='telephone_fixed' onChange={form.handleChange}
                            value={form.state.telephone_fixed} type='tel' placeholder='+49 40 12345'/>
            </Form.Group>
            <Form.Group widths={2}>
                <Form.Input fluid label='Telefon (Fax)' name='telephone_fax' value={form.state.telephone_fax}
                            onChange={form.handleChange} type='tel' placeholder='+49 40 12346'/>
                <Form.Input fluid label='E-Mail' name='email' value={form.state.email}
                            onChange={form.handleChange} type='email' placeholder='vader@deathstar.com'/>
            </Form.Group>
            <OptionalAddressForm
                name={"address_id"}
                prefix={"wohnhaft in"}
                preset={addressPreset}
                value={form.state.address_id}
                onChange={(a, model) =>
                    form.doUpdateEntity({"address_id": model.value})
                        .then(() => form.doFetchEntity(form.state.id))}
            />
        </div>
    };

    preview = (state: typeof defaultPerson): ReactElement => {
        return this.props.entity
            ? <>
                <CardHeader>
                    {get(this.genders.get(state.gender_id), "salutation")}&nbsp;
                    {state.first_name + " " + state.last_name}
                </CardHeader>
                <CardMeta>
                    {state.date_of_birth === null
                    ? "noch ohne Geburtstag"
                    : <>geboren am&nbsp;{Helpers.dateString(state.date_of_birth)}</>}
                </CardMeta>
                <CardDescription> {state.address_id === -1 ? "keine Adresse verknüpft"
                    : addressPreview(state, this.props.store, "", true, true)}
                    <br/>
                    {JSXHelpers.previewContactData(state)}<br/><br/>
                    {state.is_employee && <Label content={"Mitarbeiter"}/>}
                    {state.is_supplier && <Label content={"Zulieferer-Kontakt"}/>}
                    {state.is_customer && <Label content={"Kunde"}/>}
                </CardDescription>
            </>
            : <>
                <strong>{get(this.genders.get(state.gender_id), "salutation")}&nbsp;
                    {state.first_name + " " + state.last_name}</strong>,&nbsp;
                {state.date_of_birth === null
                    ? "noch ohne Geburtstag"
                    : <>geboren am&nbsp;<strong>{Helpers.dateString(state.date_of_birth)}</strong></>}<br/>
                {state.address_id === -1 ? "keine Adresse verknüpft"
                    : addressPreview(state, this.props.store, "wohnhaft in")}<br/><br/>
                {JSXHelpers.previewContactData(state)}
            </>
    };

    getSearch = (query: string): Promise<typeof defaultPerson[]> =>
        Helpers.getSearch("/reports/search_person", query);


    fetchSearchResults = (models: typeof defaultPerson[]): DisplayResult<number>[] => {
        return models.map(
            state => {
                return {
                    title: get(this.genders.get(state.gender_id), "salutation") + " " + state.first_name + " " + state.last_name,
                    description: (state.date_of_birth === null) ? "noch ohne Geburtstag" : "geboren am " + Helpers.dateString(state.date_of_birth),
                    value: state.id
                }
            }
        )
    }
}

export default DataStore.withStore(OptionalPersonForm)