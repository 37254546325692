import React, {Component, ReactElement} from "react";
import DataStore, {getOrEmpty, StoreProps} from "../../../../models/DataStore";
import {FormData, PresetReceiver, ValueSelectorProps} from "../../../../base/forms/FormProps";
import {defaultCompany} from "../../basedata/Companies";
import OptionalEntityForm, {OptionalEntityFormState} from "../../../../base/forms/OptionalEntityForm";
import {api} from "../../../../models/InitialData";
import Helpers, {APIResponse, STANDARD_ID} from "../../../../base/Helpers";
import EntityForm from "../../../../base/forms/EntityForm";
import SearchSelection, {DisplayResult} from "../../../../base/forms/SearchSelection";
import {CardDescription, CardHeader, CardMeta, Divider, FormField, TextArea} from "semantic-ui-react";
import OptionalCompanyForm from "../../basedata/views/OptionalCompanyForm";
import PersonAssociation from "./PersonAssociation";
import MultipleProductSelector from "../../../../base/forms/MultipleProductSelector";

export const defaultSupplierPerson = {
    person_id: -1,
    first_name: "",
    last_name: "",
    gender_id: -1,
    supplier_contact_id: -1,
    edit: true,
};

export type MapSA = Map<number, ScheduledAssociation>

export interface ScheduledAssociation {
    action: "add" | "remove",
    linkingId?: number
}

export const defaultSupplier = {
    id: -1,
    company_id: -1,
    company_name: "",
    notes: "",
    is_deleted: false,
    persons: [] as typeof defaultSupplierPerson[],
    products: [] as number[],
    supplier_product_ids: [] as number[],
    someThingChanged: false
};


class OptionalSupplierForm extends Component<{ create?: boolean, entity?: boolean } & StoreProps
    & ValueSelectorProps<number> & PresetReceiver<typeof defaultSupplier>> {

    form?: EntityForm<typeof defaultSupplier, number, OptionalEntityFormState>;
    pendingPersonChanges: MapSA = new Map<number, ScheduledAssociation>();
    productsUpdate: number[] = [];


    render(): ReactElement {
        return <OptionalEntityForm
            defaultState={defaultSupplier}
            value={this.props.value}
            name={"supplier_id"}
            notPresentText={"noch keinen Zulieferer ausgewählt"}

            preset={this.props.preset}
            get={(value) => api("/reports/aggregated_supplier/" + value, "GET")}
            set={(value, state) => api("/supplier/", value === defaultCompany.id ? "PUT" : "PATCH", state)}
            deleteEntity={this.props.entity}
            fullSizeEdit={this.props.entity}
            onClick={this.props.entity ? "edit" : undefined}
            create={this.props.create}
            onCancel={this.doCancelEditing}

            changeHandler={(n, v) => {
                this.updateAssociations(() => this.changeHandler(n, v))
            }}
            {...STANDARD_ID}

            editing={this.editing}
            preview={this.preview}
        />
    }

    changeHandler = (name: string, id: number) => {
        this.props.onChange(null, {name: name, value: id});
    };

    editing = (form: EntityForm<typeof defaultSupplier, number, OptionalEntityFormState>): ReactElement => {
        this.form = form;

        return <>
            <h2>Zulieferer</h2>
            <div className={"ui form"}>
                {form.state.id === -1 && !this.props.create &&
                <>
                    <SearchSelection get={this.getSearch}
                                     resultFetcher={this.fetchSearchResults}
                                     name={"id"}
                                     onChange={(a: any, b: FormData) => form.setValue(b.value)}
                                     placeholder={"nach bestehendem Zulieferer suchen"}
                                     defaultState={defaultSupplier}

                                     {...STANDARD_ID}
                    />
                    <Divider/>
                </>
                }
                <OptionalCompanyForm value={form.state.company_id} onChange={form.handleChange}
                                     name={"company_id"}/>
                <h3>Ansprechpartner</h3>
                <PersonAssociation create={this.props.create}
                                   value={form.state}
                                   pendingChanges={this.pendingPersonChanges}
                                   onChange={(a: any, data) => {
                                       this.pendingPersonChanges = data.value;
                                       form.setState({"someThingChanged": this.pendingPersonChanges.size > 0})
                                   }}
                                   changeHandler={() => !this.props.create
                                       && form.doFetchEntity(form.state.id, true)}/>
                <br/>
                <FormField>
                    <label>Leistungen</label>
                    <MultipleProductSelector store={this.props.store} value={form.state.products} name={"products"}
                                             onChange={(a, b) => {
                                                 form.handleChange(a, b);
                                                 this.productsUpdate = b.value;
                                                 form.setState({"someThingChanged": this.pendingPersonChanges.size > 0});
                                             }}/>
                </FormField>

                <FormField>
                    <label>Notizen</label>
                    <TextArea name='notes'
                              onChange={form.handleChange}
                              value={form.state.notes} type='text' placeholder='Raum für Notizen'/>
                </FormField>
            </div>
        </>
    };

    preview = (state: typeof defaultSupplier): ReactElement => {
        return this.props.entity ? <>
            <CardHeader>{state.company_id === -1 ? this.fetchPersonPreview(state.persons, 1) : state.company_name}</CardHeader>
            <CardMeta>{state.notes}</CardMeta>
            <CardDescription>{state.company_id === -1 ? "kein Unternehmen verknüpft" : this.fetchPersonPreview(state.persons, 3)}</CardDescription>
        </> : <>
            <strong>{state.company_id === -1 ? this.fetchPersonPreview(state.persons, 1) : state.company_name}</strong>
            <br/>{state.company_id === -1 ? "kein Unternehmen verknüpft" : this.fetchPersonPreview(state.persons, 2)}</>
    };

    doCancelEditing = () => {
        if (this.props.create) {
            this.changeHandler(this.props.name, -1)
        }
    };

    getSearch = (query: string): Promise<typeof defaultSupplier[]> =>
        Helpers.getSearch("/reports/search_supplier", query);


    fetchSearchResults = (models: typeof defaultSupplier[]): DisplayResult<number>[] => {
        return models.map(
            state => {
                return {
                    title: state.company_id === -1 ? this.fetchPersonPreview(state.persons, 1) : state.company_name,
                    description: state.company_id === -1 ? "kein Unternehmen verknüpft" : this.fetchPersonPreview(state.persons, 2),
                    value: state.id
                }
            }
        )
    };

    fetchPersonPreview = (models: typeof defaultSupplierPerson[], maxAmount: number): string => {
        const genders = this.props.store.get("genders");

        if (models.length === 0) {
            return "keine Ansprechpartner verknüpft"
        }

        let result = [] as string[];
        let addText = "";

        for (const person of models) {
            result.push(
                getOrEmpty(genders.get(person.gender_id), "salutation") + " "
                + person.first_name + " " + person.last_name
            );

            if (result.length === maxAmount && models.length > result.length) {
                addText = " (+" + (models.length - result.length) + " weitere)";
                break
            }
        }

        return result.join(", ") + addText
    };

    updateAssociations = (callback?: () => void) => {
        if (!this.form) {
            return
        }

        const pendingProductChanges: MapSA =
            this.generatePendingProductChanges(this.form.uneditedState.products, this.productsUpdate, this.form.uneditedState.supplier_product_ids);
        const promises: Promise<APIResponse<any>>[] = [];
        const supplierId = this.form.state.id;


        this.pendingPersonChanges.forEach(
            (def, personId) =>
                promises.push(
                    def.action === "add"
                        ? api("/supplier_contact/", "PUT", {"person_id": personId, "supplier_id": supplierId})
                        : api("/supplier_contact/" + def.linkingId, "DELETE")
                )
        );

        pendingProductChanges.forEach(
            (def, productId) =>
                promises.push(
                    def.action === "add"
                        ? api("/supplier_product/", "PUT", {"product_id": productId, "supplier_id": supplierId})
                        : api("/supplier_product/" + def.linkingId, "DELETE")
                )
        );

        this.pendingPersonChanges = new Map<number, ScheduledAssociation>();
        Promise.all(promises).then(callback);
    };

    generatePendingProductChanges = (initial: number[], update: number[], linkingIds: number[]): MapSA => {
        const pendingProductChanges: MapSA = new Map<number, ScheduledAssociation>();
        const initialSet = new Set(initial), updateSet = new Set(update);

        initial.forEach(
            (id, index) => !updateSet.has(id)
                && pendingProductChanges.set(id, {action: "remove", linkingId: linkingIds[index]})
        );


        for (const id of update)
            !initialSet.has(id) && pendingProductChanges.set(id, {action: "add"});

        return pendingProductChanges
    };
}

export default DataStore.withStore(OptionalSupplierForm)