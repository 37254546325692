import Helpers from "../base/Helpers";
import assert from "assert";


class Time {
    static readonly HOUR = 60;
    static readonly ZERO = Time.parse(0);

    private readonly hours: number;
    private readonly minutes: number;
    private readonly negative: boolean;

    constructor(hours: number, minutes: number, negative?: boolean) {
        assert(hours >= 0 && minutes >= 0);

        this.hours = hours;
        this.minutes = minutes;
        this.negative = !!negative;
    }

    static parse(minutes: number): Time {
        let negative = false;

        if (minutes < 0) {
            minutes = -minutes;
            negative = true
        }

        return new Time(Math.floor(minutes / this.HOUR), minutes % this.HOUR, negative);
    }

    static import(input: string): Time {
        const matches = input.match(/^(\d+)[.:](\d{2})/);

        if (matches === null || matches.length < 3) {
            return Time.ZERO;
        }

        return new Time(parseInt(matches[1]), parseInt(matches[2]));
    }

    getHours(): number {
        return this.hours;
    }

    export(): string {
        return Helpers.pad(this.hours, 2) + ":" + Helpers.pad(this.minutes, 2)
    }

    isZero(): boolean {
        return this.hours === 0 && this.minutes === 0;
    }

    toString(): string {
        return (this.negative ? "–" : "") + this.hours + ":" + Helpers.pad(this.minutes, 2)
    }

    toJSON(key?: any): number {
        return this.minutes + this.hours * 60;
    }
}

export default Time