import {StoreProps} from "../models/DataStore";

export default class Groups {
    static readonly ALL: number = 1337;
    static readonly EDITOR: number = 2;
    static readonly MANAGEMENT: number = 5;
    static readonly PROJECTLEAD: number = 3;
    static readonly ACQUISITION: number = 4;
    static readonly DEVELOPER: number = 1;
}

export function hasAnyGroup(store: StoreProps, ...groups: number[]): boolean {
    const userGroup = store.store.get("user").group_id;

    for (const g of groups) {
        if (g === userGroup || g === Groups.ALL) {
            return true
        }
    }

    return false
}