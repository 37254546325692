import React, {Component, ReactElement} from "react";
import EntityForm from "../../../base/forms/EntityForm";
import Helpers, {STANDARD_ID} from "../../../base/Helpers";
import {api} from "../../../models/InitialData";
import {
    CardDescription,
    CardHeader,
    CardMeta,
    Form,
    FormField,
    FormGroup,
    Label,
    Popup,
    SemanticCOLORS,
    TextArea
} from "semantic-ui-react";
import {PresetReceiver, ValueSelectorProps} from "../../../base/forms/FormProps";
import DataStore, {getOrEmpty, StoreProps} from "../../../models/DataStore";
import OptionalEntityForm, {OptionalEntityFormState} from "../../../base/forms/OptionalEntityForm";
import DateForm from "../../../base/forms/DateForm";
import StatusSelector from "../../../base/forms/StatusSelector";
import CustomerView from "../../general/entities/views/CustomerView";
import CurrencyForm from "../../../base/forms/CurrencyForm";
import NumberSelector from "../../../base/forms/NumberSelector";
import Groups, {hasAnyGroup} from "../../../base/Groups";

export const defaultProject = {
    id: -1,
    project_number: "",
    name: "",
    start_date: new Date().toISOString(),
    expected_hours: 0,
    expected_end_date: null,
    expected_return: 0,
    actual_return: 0,
    status_id: 1,
    customer_id: -1,
    customer_display_name: "",
    company_id: -1,
    company_name: "",
    notes: "",
    "telephone_mobile": "",
    "telephone_fixed": "",
    "telephone_fax": "",
    "email": "",
    is_deleted: false
};

export const statusColoring: { [key: number]: SemanticCOLORS } = {
    "-1": "grey",
    1: "yellow",
    2: "green",
    3: "red",
};

class ProjectView extends Component<{ create?: boolean, entity?: boolean, onClick?: () => void }
    & ValueSelectorProps<number> & PresetReceiver<typeof defaultProject> & StoreProps> {
    render(): ReactElement {
        return <OptionalEntityForm
            defaultState={defaultProject}
            value={this.props.value}
            name={this.props.name}
            notPresentText={"noch kein Projekt verknüpft"}

            forbidEditing={!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT, Groups.PROJECTLEAD, Groups.ACQUISITION)}
            fullSizeEdit={this.props.entity}
            deleteEntity={this.props.entity}
            create={this.props.create}
            onCancel={this.doCancelEditing}
            changeHandler={this.changeHandler}
            onClick={this.props.onClick}

            preset={this.props.preset}
            get={(value) => api("/aggregated_project/" + value, "GET")}
            set={(value, state) => api("/project/", this.props.value === -1 ? "PUT" : "PATCH", state)}

            {...STANDARD_ID}

            editing={this.editing}
            preview={this.preview}
        />
    }

    editing = (form: EntityForm<typeof defaultProject, number, OptionalEntityFormState>): ReactElement => {
        return <>
            <h2>Projekt</h2>
            <div className={"ui form"}>
                <CustomerView value={form.state.customer_id} name={"customer_id"} onChange={form.handleChange}/>
                <br/>
                <FormGroup>
                    <Form.Input width={3} label='Projekt-Nummer' name='project_number' value={form.state.project_number}
                                onChange={form.handleChange}
                                placeholder={Helpers.isoDate(new Date().toISOString()).replace(/-/g, "")}/>
                    <Form.Input width={10} label='Name' name='name' value={form.state.name}
                                onChange={form.handleChange} placeholder='Projekt-Name'/>
                    <FormField width={3}>
                        <label>Status</label>
                        <StatusSelector name={"status_id"} value={form.state.status_id} onChange={form.handleChange}/>
                    </FormField>
                </FormGroup>
                <FormGroup widths={3}>
                    <DateForm label='Startdatum' name='start_date' onChange={form.handleChange}
                              value={form.state.start_date}/>
                    <NumberSelector label='Stunden-Soll' name='expected_hours' placeholder="100"
                                    onChange={form.handleChange}
                                    value={form.state.expected_hours}/>
                    <DateForm label='erwartetes Enddatum' name='expected_end_date' onChange={form.handleChange}
                              value={form.state.expected_end_date}/>
                </FormGroup>
                <FormGroup widths={2}>
                    <CurrencyForm label='Angebotssumme' name='expected_return'
                                  value={form.state.expected_return}
                                  onChange={form.handleChange}/>
                    <CurrencyForm label='tatsächliche Einnahmen' name='actual_return'
                                  value={form.state.actual_return}
                                  onChange={form.handleChange}/>
                </FormGroup>
                <br/>
                <FormField>
                    <label>Notizen</label>
                    <TextArea name='notes'
                              onChange={form.handleChange}
                              value={form.state.notes} type='text' placeholder='Raum für Notizen'/>
                </FormField>
            </div>
        </>;
    };

    preview = (state: typeof defaultProject): ReactElement => {
        if (!this.props.store.get("ready")) {
            return <></>;
        }

        let addLabelProps = {};

        if (statusColoring.hasOwnProperty(state.status_id)) {
            addLabelProps = {
                color: statusColoring[state.status_id],
            };
        }

        const status = getOrEmpty(this.props.store.get("status").get(state.status_id), "name");


        return this.props.entity
            ? <>
                <CardHeader>
                    <Popup content={status} offset='-14px, 0' trigger={<Label circular empty {...addLabelProps} />}/>
                    &nbsp;&nbsp;{state.project_number} {state.name}
                </CardHeader>
                <CardMeta>für {state.customer_display_name.replace("Herr", "Herrn")} von {state.company_name}</CardMeta>
                <CardDescription>von <strong>{Helpers.dateString(state.start_date)}</strong> bis&nbsp;
                    <strong>{Helpers.dateString(state.expected_end_date)}</strong>
                </CardDescription>
            </>
            : <>
                Normale Einzeilenvorschau für das Projekt...
            </>
    };


    doCancelEditing = () => {
        if (this.props.create) {
            this.changeHandler(this.props.name, -1)
        }
    };

    changeHandler = (name: string, id: number) => {
        this.props.onChange(null, {name: name, value: id});
    };
}

export default DataStore.withStore(ProjectView)