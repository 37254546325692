import React, {Component, ReactElement} from "react";
import {FormData} from "../../../../base/forms/FormProps";
import {defaultSupplier, defaultSupplierPerson, MapSA} from "./OptionalSupplierForm";
import OptionalPersonForm from "../../forms/OptionalPersonForm";
import _ from "lodash";
import {ChangeHandler} from "../../../../base/forms/EntityForm";
import Helpers from "../../../../base/Helpers";
import {getOrEmpty} from "../../../../models/DataStore";


class PersonAssociation extends Component<{
    value: typeof defaultSupplier, onChange: (a: any, f: FormData) => void, create?: boolean, pendingChanges: MapSA
} & ChangeHandler<number>> {

    numCreated = 1;
    state = {
        persons: [...this.props.value.persons, Helpers.immCopy(defaultSupplierPerson)],
    };

    componentDidUpdate(prevProps: Readonly<{ value: typeof defaultSupplier, create?: boolean }>): void {
        if (!_.isEqual(prevProps.value.persons, this.props.value.persons)) {
            this.setState({
                persons: [...this.props.value.persons, Helpers.immCopy(defaultSupplierPerson)]
            });
        }
    }

    render(): ReactElement {
        return <>
            {this.state.persons.map(
                (p, idx) => {
                    let key = p.supplier_contact_id;

                    return <OptionalPersonForm
                        key={key}
                        value={p.person_id} onChange={this.changeHandler} name={idx + ""}/>
                }
            )}
        </>
    }

    changeHandler = (a: any, value: FormData): void => {
        const persons = this.state.persons;

        if (value.name === undefined || value.value === undefined) {
            return
        }

        const arrId = +value.name;
        const pendingChanges = this.props.pendingChanges;
        if (arrId === persons.length - 1) {
            // new person
            if (pendingChanges.has(value.value) && getOrEmpty(pendingChanges.get(value.value), "action") === "remove") {
                pendingChanges.delete(value.value)
            } else {
                pendingChanges.set(value.value, {action: "add"});
            }
            persons[arrId].person_id = value.value;
            persons.push(Object.assign(Helpers.immCopy(defaultSupplierPerson), {supplier_contact_id: -(++this.numCreated)}));
        } else if (value.value === -1) {
            // person removed
            // TODO this is buggy: when in edit mode, random entries get deleted

            const person = persons[arrId];
            if (person.edit) {
                pendingChanges.delete(person.person_id)
            } else {
                pendingChanges.set(person.person_id, {action: "remove", linkingId: person.supplier_contact_id});
            }
            persons.splice(arrId, 1)
        }

        this.setState({persons});
        this.props.onChange(a, {
            "name": "pendingChanges",
            "value": pendingChanges
        });
    };
}

export default PersonAssociation