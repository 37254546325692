import React, {Component, ReactElement} from "react";
import AbstractForm from "../../../base/forms/AbstractForm";
import {FormData} from "../../../base/forms/FormProps";
import Helpers, {APIResponse} from "../../../base/Helpers";
import DataStore, {get, StoreProps} from "../../../models/DataStore";
import {CardMeta, Form} from "semantic-ui-react";
import {api} from "../../../models/InitialData";

class EmployeeData extends Component<StoreProps> {
    user = this.props.store.get("user");

    state = {
        "username": this.user.username
    };
    initialState = this.state;

    render(): ReactElement {
        return <AbstractForm errors submit={this.submit} cancel={this.cancel} preview={
            <>Dein Account mit dem Nutzernamen <strong>{this.state.username}</strong> gehört der Gruppe&nbsp;
                <strong>{get(this.props.store.get("groups").get(this.user.group_id), "name")}</strong> an.<br/>
                <CardMeta>Erstellungsdatum: {Helpers.dateString(this.user.entry_date)}</CardMeta></>
        }>
            <Form.Input fluid label='Nutzername' name='username' value={this.state.username}
                        onChange={this.handleChange} placeholder='nutzer_name123'/>
        </AbstractForm>
    }


    handleChange = (a: any, data: FormData) => {
        Helpers.handleChange(this, data);
    };

    private cancel = (): void => {
        this.setState(this.initialState);
    };

    private submit = (): Promise<APIResponse<any>> => {
        return api("/forms/mylogin", "POST", this.state)
            .then(
                resp => {
                    Object.assign(this.user, resp);
                    this.initialState = this.state;

                    return resp
                }
            )
    };
}

export default DataStore.withStore(EmployeeData)