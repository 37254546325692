import React, {Component, ReactElement, SyntheticEvent} from "react";
import {FormInput} from "semantic-ui-react";
import {DataFormProps, FormData} from "./FormProps";


class CurrencyForm extends Component<DataFormProps<number> & { notFluid?: boolean }> {
    lastVal: string = "";

    static currencyString(input: number): string {
        let s = input.toString();

        if (s.length >= 3) {
            const p = s.length - 2;
            s = [s.slice(0, p), ",", s.slice(p)].join('');
        } else if (s.length >= 2) {
            s = "0," + s;
        } else {
            s = "0,0" + s;
        }

        return s + " €";
    }

    componentDidMount(): void {
        this.lastVal = CurrencyForm.currencyString(this.props.value);
    }

    render(): ReactElement {
        return <FormInput fluid={!this.props.notFluid} name={this.props.name}
                          value={CurrencyForm.currencyString(this.props.value)}
                          onChange={this.handleChange}
                          type='text'
                          className={"currency-form"}
                          placeholder='XX,XX €'
                          action={
                              {
                                  disabled: this.props.value === 0,
                                  icon: "x",
                                  onClick: () => this.props.onChange(null, {name: this.props.name, value: 0})
                              }
                          }
                          label={this.props.label}/>
    }

    handleChange = (event: SyntheticEvent | null, data: FormData) => {
        let edit = data.value as string;
        let value: number = this.props.value;

        const lastVal = this.lastVal as string;
        const diff: number = +edit.length - +lastVal.length;

        if (edit.length === 1) {
            const add = edit.charAt(edit.length - 1);
            const isNumeric = /^\d+$/.test(add);
            if (isNumeric) {
                value = +add;
            }
        } else if (diff > 0) {
            const add = edit.charAt(edit.length - 1);
            const isNumeric = /^\d+$/.test(add);
            if (isNumeric) {
                value = value * Math.pow(10, diff) + +add;
            }
        } else if (diff < 0) {
            value = Math.floor(value / Math.pow(10, -diff));
        }

        this.lastVal = CurrencyForm.currencyString(value);
        this.props.onChange(event, {name: this.props.name, value: value})
    };
}

export default CurrencyForm;