import {SelectorProps} from "./FormProps";
import React, {Component, ReactElement} from "react";
import DataStore, {StoreProps} from "../../models/DataStore";
import {Dropdown} from "semantic-ui-react";
import Helpers from "../Helpers";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";

interface ProductSelectorProps extends SelectorProps {
    value: number
}

class StatusSelector extends Component<StoreProps & ProductSelectorProps> {
    status: DropdownItemProps[] = [];

    render(): ReactElement {
        if (!this.props.store.get("ready")) {
            return <></>
        }

        if (this.status.length === 0) {
            this.status = Array
                .from(this.props.store.get("status"), ([key, value]) => value)
                .map(
                    (value) => {
                        return {
                            "key": value.id,
                            "value": value.id,
                            "text": value.name,
                        }
                    }
                );
        }


        return <Dropdown
            placeholder='Status wählen'
            fluid
            search
            selection
            options={this.status}
            name={this.props.name}
            value={Helpers.toNumber(this.props.value)}
            onChange={this.props.onChange}
            className={"category-selector"}
            noResultsMessage={"keine Ergebnisse gefunden"}
        />
    }
}

export default DataStore.withStore(StatusSelector);