import React, {Component, ReactElement} from "react";
import {Redirect, RouteComponentProps} from "react-router-dom";
import ContentHeader from "../../../base/ContentHeader";
import MenuLink from "../../../base/MenuLink";
import RouterContent from "../../../base/RouterContent";
import {Route, Switch} from "react-router";
import EmployeeManagement from "./EmployeeManagement";
import CustomerManagement from "./CustomerManagement";
import SupplierManagement from "./SupplierManagement";
import Helpers from "../../../base/Helpers";
import Groups, {hasAnyGroup} from "../../../base/Groups";
import {goHome} from "../../../base/JSXHelpers";
import DataStore, {StoreProps} from "../../../models/DataStore";


class EntitiesPage extends Component<RouteComponentProps & StoreProps> {
    state = {
        menuAction: null,
        createObj: false
    };

    render(): ReactElement {
        if (!hasAnyGroup(this.props, Groups.DEVELOPER, Groups.ACQUISITION, Groups.MANAGEMENT, Groups.PROJECTLEAD)) {
            return goHome()
        }

        const path = this.props.match.path;

        return <>
            <ContentHeader title={"Entitäten pflegen"} action={this.state.menuAction} isReady={true}>
                {
                    hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT) &&
                    <MenuLink to={path} exact={true}>Mitarbeiter</MenuLink>
                }
                <MenuLink to={path + "/customers"}>Kunden</MenuLink>
                <MenuLink to={path + "/suppliers"}>Zulieferer</MenuLink>
            </ContentHeader>
            <RouterContent>
                <Switch>
                    <Route path={path + "/suppliers"}>
                        <SupplierManagement create={this.state.createObj} menu={Helpers.setMenuAction(this)}
                                            cancelMenu={Helpers.cancelAction(this)}/>
                    </Route>
                    <Route path={path + "/customers"}>
                        <CustomerManagement create={this.state.createObj} menu={Helpers.setMenuAction(this)}
                                            cancelMenu={Helpers.cancelAction(this)}/>
                    </Route>

                    {
                        !hasAnyGroup(this.props, Groups.DEVELOPER, Groups.MANAGEMENT) &&
                        <Redirect to={path + "/customers"}/>
                    }

                    <Route path={path} exact={true}>
                        <EmployeeManagement create={this.state.createObj} menu={Helpers.setMenuAction(this)}
                                            cancelMenu={Helpers.cancelAction(this)}/>
                    </Route>
                </Switch>
            </RouterContent>
        </>
    }

}

export default DataStore.withStore(EntitiesPage);