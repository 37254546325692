import React, {Component, ReactElement} from "react";
import DataStore, {StoreProps} from "../../models/DataStore";
import SearchList from "../../base/forms/SearchList";
import {AggregatedCompany} from "../../models/AggregatedCompany";
import {api} from "../../models/InitialData";
import {Card, CardDescription, CardHeader, CardMeta, Label} from "semantic-ui-react";
import {addressPreview} from "../general/forms/OptionalAddressForm";
import {RouteComponentProps, withRouter} from "react-router";


class CompanyContacts extends Component<StoreProps & RouteComponentProps> {
    render(): ReactElement {
        return <SearchList
            match={(p: AggregatedCompany) => p.name}

            get={() => api("/reports/companies?onlyContacts=true", "GET")}

            searchLabel={"nach Unternehmen suchen"}
            placeholder={"Beispiel Gesellschaft mbH"}

            pageSize={14}

            render={(m, list) =>
                <Card.Group itemsPerRow={2}>
                    {
                        m.map(
                            (state: AggregatedCompany) => {
                                const address = state.address_id > -1 ? addressPreview(state, this.props.store, "", true, true)
                                    : "keine Adresse verknüpft";

                                return <Card key={state.id}
                                             onClick={() => this.props.history.push("./companies/" + state.id)}>
                                    <Card.Content>
                                        <CardHeader>{state.name}</CardHeader>
                                        <CardMeta>{address}</CardMeta>
                                        <CardDescription>
                                            {state.is_supplier && <Label content={"Zulieferer"}/>}
                                            {state.is_customer && <Label content={"Kunde"}/>}
                                        </CardDescription>
                                    </Card.Content>
                                </Card>
                            }
                        )
                    }
                    {m.length === 0 && <Card fluid><Card.Content>Keine Ergebnisse gefunden</Card.Content></Card>}
                </Card.Group>
            }
        />
    }
}

export default withRouter(DataStore.withStore(CompanyContacts))