import React, {Component, ReactElement} from "react";
import {CardDescription, CardHeader, CardMeta, Divider, FormField, TextArea} from "semantic-ui-react";
import Helpers, {STANDARD_ID} from "../../../../base/Helpers";
import {api} from "../../../../models/InitialData";
import EntityForm from "../../../../base/forms/EntityForm";
import {defaultCustomer} from "../CustomerManagement";
import OptionalPersonForm from "../../forms/OptionalPersonForm";
import OptionalCompanyForm from "../../basedata/views/OptionalCompanyForm";
import OptionalEntityForm, {OptionalEntityFormState} from "../../../../base/forms/OptionalEntityForm";
import {FormData, PresetReceiver, ValueSelectorProps} from "../../../../base/forms/FormProps";
import DataStore, {get, getOrEmpty, StoreProps} from "../../../../models/DataStore";
import SearchSelection, {DisplayResult} from "../../../../base/forms/SearchSelection";


class CustomerView extends Component<{ create?: boolean, entity?: boolean }
    & ValueSelectorProps<number> & PresetReceiver<typeof defaultCustomer> & StoreProps> {
    render(): ReactElement {
        return <OptionalEntityForm
            defaultState={defaultCustomer}
            value={this.props.value}

            notPresentText={"kein Kunde verknüpft"}
            name={"customer_id"}
            preset={this.props.preset}
            get={(value) => api("/reports/aggregated_customer/" + value, "GET")}
            set={(value, state) => api("/customer/", value === defaultCustomer.id ? "PUT" : "PATCH", state)}

            fullSizeEdit={this.props.entity}
            onClick={this.props.entity ? "edit" : undefined}
            deleteEntity={this.props.entity}
            create={this.props.create}
            onCancel={this.doCancelEditing}
            changeHandler={this.changeHandler}

            {...STANDARD_ID}

            editing={this.editing}
            preview={this.preview}
        />
    }

    editing = (form: EntityForm<typeof defaultCustomer, number, OptionalEntityFormState>): ReactElement => {
        return <>
            <h2>Kunde</h2>
            <div className={"ui form"}>
                {form.state.id === -1 && !this.props.create &&
                <>
                    <SearchSelection get={this.getSearch}
                                     resultFetcher={this.fetchSearchResults}
                                     name={"id"}
                                     onChange={(a: any, b: FormData) => form.setValue(b.value)}
                                     placeholder={"nach bestehendem Kunden suchen"}
                                     defaultState={defaultCustomer}

                                     {...STANDARD_ID}
                    />
                    <Divider/>
                </>
                }
                <OptionalPersonForm value={form.state.person_id} onChange={form.handleChange}
                                    name={"person_id"}/>
                <OptionalCompanyForm value={form.state.company_id} onChange={form.handleChange}
                                     name={"company_id"}/>
                <br/>
                <FormField>
                    <label>Notizen</label>
                    <TextArea name='notes'
                              onChange={form.handleChange}
                              value={form.state.notes} type='text' placeholder='Raum für Notizen'/>
                </FormField>
            </div>
        </>;
    };

    preview = (state: typeof defaultCustomer): ReactElement => {
        const genders = this.props.store.get("genders");

        const displayName = state.person_id === -1 ? state.company_name : getOrEmpty(genders.get(state.gender_id), "salutation")
            + " " + state.first_name + " " + state.last_name;

        const subtext = state.person_id === -1 ? "keine Person verknüpft" :
            (state.company_id !== -1 ? "von " + state.company_name : "kein Unternehmen verknüpft");

        return this.props.entity ? <>
            <CardHeader>{displayName}</CardHeader>
            <CardMeta>{subtext}</CardMeta>
            <CardDescription>{state.notes}</CardDescription>
        </> : <>
            <strong>{displayName}</strong><br/>
            {subtext}
        </>
    };

    doCancelEditing = () => {
        if (this.props.create) {
            this.changeHandler(this.props.name, -1)
        }
    };

    changeHandler = (name: string, id: number) => {
        this.props.onChange(null, {name: name, value: id});
    };

    getSearch = (query: string): Promise<typeof defaultCustomer[]> =>
        Helpers.getSearch("/reports/search_customer", query);


    fetchSearchResults = (models: typeof defaultCustomer[]): DisplayResult<number>[] => {
        const genders = this.props.store.get("genders");

        return models.map(
            state => {
                return {
                    title: get(genders.get(state.gender_id), "salutation") + " " + state.first_name + " " + state.last_name,
                    description: "von " + state.company_name,
                    value: state.id
                }
            }
        )
    }
}

export default DataStore.withStore(CustomerView)